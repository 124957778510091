/*eslint-disable*/
import React, { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Image1 from './../assets/img/main/1.webp';
import Image2 from './../assets/img/main/2.webp';

export default function Index() {
  const [showMain, setShowMain] = useState(false);

  return (
    <div className='overflow-hidden w-screen h-screen'>
      <div
        className={
          'malem-group ' + (showMain ? 'index-animation' : 'no-animation')
        }
        onClick={() => {
          setShowMain(true);
        }}>
        <img
          src={
            'https://images.pexels.com/photos/1054218/pexels-photo-1054218.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=1080'
          }
          alt=''
          className={
            'user-select elegantshadow ' + (showMain ? 'scale min-w-full' : '')
          }
        />
        <h1
          className={'user-select elegantshadow ' + (showMain ? 'scale' : '')}>
          Malem Group
        </h1>
      </div>
      <div className={'main ' + (showMain ? 'slide-in' : 'hide-message')}>
        <Link to='/catalogue/vianney'>
          <div
            className='split left left-background'
            style={{ transform: 'translate(0%, 0%)' }}></div>
          <div className='split left'>
            <div className='centered'>
              <img src={Image1} alt='Vianney' />
            </div>
            <div className='centered'>
              <div className='vianney w-40 md:w-80'>
                <p className='font-sans text-4xl md:text-5xl enmedio'>
                  Vianney
                </p>
              </div>
            </div>
          </div>
        </Link>
        <p className='enmedio icono w-1/12'></p>
        <Link to='/catalogue/balam'>
          <div className='split right'>
            <div className='centered'>
              <img src={Image2} alt='Auto Market' />
            </div>
            <div className='centered w-1/12'>
              <p className='font-sans text-5xl md:text-8xl enmedio autoMarket'>
                Auto Market
              </p>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}
