import React, { useState, useEffect, useRef } from 'react';
import CardTable from '../../components/Cards/CardTable.js';
import Modal from '../../components/Modals/Modal.js';
// Loader
import Loader from 'react-loader-spinner';
// Alertify
import alertify from 'alertifyjs';

export default function Extensiones() {
  function refreshOnSubmit() {
    refreshTable();
  }

  // Loading state
  const [loading, setLoading] = useState(true);
  // Data Map
  const [data, setData] = useState([]);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState(['id', 'nombre']);
  const modal = useRef(null);
  // Data push
  const [nameDataRow, setNameDataRow] = useState('');
  const [activeDataRow, setActiveDataRow] = useState('');
  // Information about present view
  const documentTitle = 'Comercio';
  const URIExtension = 'store';
  const URIPost = '/api/post/';
  const URI = '/api/data/';
  // Alerts
  const [alert, setAlert] = useState('');

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert);
    setAlert('');
  }

  useEffect(() => {
    const fetchData = () => {
      return fetch(URI + URIExtension, { method: 'GET' })
        .then((response) => response.json())
        .then((json) => {
          // Data successfully fetched
          if (json.success) {
            setData(json.data);
            setLoading(false);
          }
          // Else error / No data available
          else {
            setAlert(json.message);
            setData([]);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    fetchData();
  }, []);

  function refreshTable() {
    fetch(URI + URIExtension, { method: 'GET' })
      .then((response) => response.json())
      .then((json) => {
        // Refresh data successfully
        if (json.success) {
          setData(json.data);
        }
        // Else error / No data available
        else {
          setAlert(json.message);
          setData([]);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function submitData() {
    var datos = [];

    datos.push({
      nameDataRow,
      activeDataRow,
    });

    fetch(URIPost + URIExtension, {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos),
    })
      .then((response) => response.json())
      .then((json) => {
        // Data successfully inserted
        if (json.success) {
          setAlert(json.message);
          refreshTable();
        }
        // Else error
        else {
          setAlert(json.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const columns = data[0] && Object.keys(data[0]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submitData();
  };

  return (
    <>
      <div className='mb-auto h-full'>
        <div className='flex justify-center flex-wrap mt-4'>
          <div className='w-full px-4'>
            <div className='xs:block align-middle items-center justify-center mb-3'>
              <div className='my-auto pt-2 float-left mx-auto'>
                <input
                  className='border rounded-xl py-2 px-3 mr-4 text-gray-700'
                  type='text'
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
              {columns &&
                columns.map((column) => (
                  <div key={column} className='inline-flex'>
                    <div className='rounded-t-xl overflow-hidden p-2'>
                      <div className='p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md'>
                        <label className='flex items-center space-x-3'>
                          <input
                            className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none'
                            type='checkbox'
                            checked={searchColumns.includes(column)}
                            onChange={(e) => {
                              const checked = searchColumns.includes(column);
                              setSearchColumns((prev) =>
                                checked
                                  ? prev.filter((sc) => sc !== column)
                                  : [...prev, column]
                              );
                            }}
                          />
                          <span className='text-gray-900 font-normal space-x-3 font-sans capitalize text-sm'>
                            {column}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              <div className='xs:float-right pt-2 xxl:float-right mx-auto'>
                <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  onClick={() => modal.current.open()}>
                  Añadir nuevo {documentTitle}
                </button>
              </div>
            </div>
            <Modal ref={modal}>
              <form onSubmit={handleSubmit}>
                <div className='text-gray-300 font-bold md:text-left mb-1 md:mb-0 pr-4'>
                  Añadir nuevo {documentTitle}
                </div>
                <div className='my-4 md:block flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'nombre' + documentTitle}
                    className='text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Nombre de {documentTitle}
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id={'nombre' + documentTitle}
                    name={'nombre' + documentTitle}
                    placeholder={'Nombre de ' + documentTitle}
                    onChange={(e) => setNameDataRow(e.target.value)}></input>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'activo' + documentTitle}
                    className='text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Estado del {documentTitle}
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-6 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id={'activo' + documentTitle}
                    name={'activo' + documentTitle}
                    placeholder={'Estado de ' + documentTitle}
                    onChange={(e) => setActiveDataRow(e.target.value)}>
                    <option value='' defaultValue>
                      Seleccionar
                    </option>
                    <option value='1'>Activo</option>
                    <option value='2'>No activo</option>
                  </select>
                </div>
                <div className='flex'>
                  <button
                    type='button'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-1/2 w-1/2'
                    onClick={() => modal.current.close()}>
                    Cerrar ventana
                  </button>
                  <button
                    type='submit'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-1/2 w-1/2'>
                    Agregar nuevo
                  </button>
                </div>
              </form>
            </Modal>
            {loading ? (
              <Loader
                type='MutatingDots'
                color='#9fd8df'
                secondaryColor='#4b778d'
                height={100}
                width={100}
                className='loader-middle'
              />
            ) : (
              <CardTable
                data={search(data)}
                info={documentTitle}
                URIExtension={URIExtension}
                recibir={refreshOnSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
