import React, { useState, useEffect } from 'react';
import CardInventory from '../../components/Cards/CardInventory.js';
import Loader from 'react-loader-spinner';
import alertify from 'alertifyjs';

export default function Inventario() {
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataPhoto, setDataPhoto] = useState([]);
  const [q, setQ] = useState('');
  const [alert, setAlert] = useState('');
  const [loading, setLoading] = useState(true);
  const [searchColumns, setSearchColumns] = useState([
    'barcode',
    'id',
    'nombre',
    'activo',
    'valor',
    'elemento',
    'extra',
  ]);
  const documentTitle = 'Producto';
  const rowExtension = 'producto';
  const URIExtension = 'stock';

  useEffect(() => {
    const fetchData = () => {
      return fetch('/api/inventario/fetch', { method: 'GET' })
        .then((response) => response.json())
        .then((json) => {
          // Data successfully fetched
          if (json.success) {
            setData(json.data);
            setDataPhoto(json.dataPhoto);
            setDataSearch(json.dataSearch);
            setLoading(false);
          }
          // Else error / No data available
          else {
            setAlert(json.message);
            setData([]);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };
    fetchData();
  }, []);

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert);
    setAlert('');
  }

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  return (
    <>
      <div className='flex justify-center flex-wrap'>
        <div className='w-full px-4'>
          <div className='xs:block align-middle items-center justify-center mb-3'>
            <div className='my-auto pt-2 float-left mx-auto'>
              <input
                className='border rounded-xl py-2 px-3 mr-4 text-gray-700'
                type='text'
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </div>
            {columns &&
              columns.map((column) => (
                <div key={column} className='inline-flex'>
                  <div className='rounded-t-xl overflow-hidden p-2'>
                    <div className='p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md'>
                      <label className='flex items-center space-x-3'>
                        <input
                          className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none'
                          type='checkbox'
                          checked={searchColumns.includes(column)}
                          onChange={(e) => {
                            const checked = searchColumns.includes(column);
                            setSearchColumns((prev) =>
                              checked
                                ? prev.filter((sc) => sc !== column)
                                : [...prev, column]
                            );
                          }}
                        />
                        <span className='text-gray-900 font-normal space-x-3 font-sans capitalize text-sm user-select'>
                          {column}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {loading ? (
            <Loader
              type='MutatingDots'
              color='#9fd8df'
              secondaryColor='#4b778d'
              height={100}
              width={100}
              timeout={3000}
              className='loader-middle'
            />
          ) : (
            <CardInventory
              data={search(data)}
              dataPhoto={dataPhoto}
              info={documentTitle}
              URIExtension={URIExtension}
              rowExtension={rowExtension}
              optionsDropdown={false}
            />
          )}
        </div>
      </div>
    </>
  );
}
