import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginVertical: 2.5,
    width: '72.1mm',
    textAlign: 'center',
  },
  wrapper: {
    width: '72.1mm',
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Alte Haas Grotesk',
    fontWeight: 'normal',
    fontSize: 11,
    textTransform: 'none',
  },
});

const ReceiptDescription = ({ title, extension }) => (
  <Fragment>
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <Text style={[styles.text, { fontWeight: 'bold' }]}>{title}</Text>
        <Text style={styles.text}>Ubicación:</Text>
        {/* <Text style={styles.text}>{extension}</Text> */}
        <Text style={styles.text}>Calzada Kaibil Balam Zona 5</Text>
        <Text style={styles.text}>Frente a Celasa, Huehuetenango</Text>
        <Text style={[styles.text, { marginTop: 5 }]}>
          Whatssap: (502) 4877 7344
        </Text>
        <Text style={[styles.text, { fontWeight: 'bold', marginTop: 5 }]}>
          Visita nuestro catálogo en:
        </Text>
        <Text style={styles.text}>www.malem.group</Text>
        <Text style={[styles.text, { marginTop: 5 }]}>
          ¡Gracias por su preferencia!
        </Text>
      </View>
    </View>
  </Fragment>
);

export default ReceiptDescription;
