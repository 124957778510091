import React from "react";

export default function PageNumbers({ pages, info, color, ...props }) {

  const arrowPageChange = (value) => {
    props.arrowPageChange(value)
  }

  const changePage = (index, row) => {
    props.changePage(index, row)
  }

  return (
    <div className="flex w-full rounded-t mb-0 px-4 py-3 border-0">
      <div className="flex w-1/4 flex-wrap items-center">
        <div className="relative w-full px-4 max-w-full flex-grow flex-1">
          <h3
            className={
              "font-semibold text-lg " +
              (color === "light" ? "text-gray-800" : "text-white")
            }
          >
            {info}
          </h3>
        </div>
      </div>
      <div className="w-full text-right mr-6">
        <button 
          className="fas fa-angle-double-left border rounded-sm p-2 m-0.5 bg-indigo-100 hover:bg-indigo-200" 
          onClick={() => {arrowPageChange(-1)}}
        />
          {pages.map((row, index) => (
            <button 
              key={index} 
              className={
                "border rounded-sm p-2 m-0.5 bg-indigo-100 hover:bg-indigo-200 " +
                (window.location.search === `?page=${row}`
                ? "bg-indigo-200"
                : "")
              }
              onClick={() => {changePage(index, row)}}
            >{row}</button>
          ))}
        <button 
          className="fas fa-angle-double-right border rounded-sm p-2 m-0.5 bg-indigo-100 hover:bg-indigo-200" 
          onClick={() => {arrowPageChange(+1)}}
        />
      </div>
    </div>
  )
}