import React, { useEffect, useState, useRef } from "react";
// Components
import CatalogueCard from "./CatalogueCard";
// Loader
import Loader from "react-loader-spinner";
// CSS
import bootstrapstyle from './../../extras/e-shop/css/bootstrap.module.css';
import uistyle from './../../extras/e-shop/css/ui.module.css';
// CSS for jQuery & Buttons
import '../../assets/styles/bootstrap.custom.css'

require("./../../extras/e-shop/css/all.min.css")
require("../../extras/e-shop/js/jquery.min.js")
require("../../extras/e-shop/js/bootstrap.bundle.min.js")

export default function Catalogue() {

  return (
	<>		
	<div className={bootstrapstyle.html}>
		<div className={bootstrapstyle.app}>
			<header className={`${bootstrapstyle.header} ${uistyle["section-header"]}`}>
				<section className={`${bootstrapstyle.section} ${uistyle["header-main"]} ${bootstrapstyle["border-bottom"]}`}>
					<div className={bootstrapstyle.container}>
						<div className={`${bootstrapstyle.row} ${bootstrapstyle["align-items-center"]}`}>
							<div className={`${bootstrapstyle["col-lg-2"]} ${bootstrapstyle["col-4"]}`}>
								<a href="/" className={uistyle["brand-wrap"]}>Malem Group</a>
							</div>
							<div className={`${bootstrapstyle["col-lg-6"]} ${bootstrapstyle["col-sm-12"]}`}>
								{/* <form action=" " className="search">
									<div className="input-group w-100">
										<input type="text" className="form-control" placeholder="Search" />
										<div className="input-group-append">
											<button className="btn btn-primary" type="submit">
												<i className="fa fa-search"></i>
											</button>
										</div>
									</div>
								</form>  */}
							</div> 
							<div className={`${bootstrapstyle["col-lg-4"]} ${bootstrapstyle["col-sm-6"]} ${bootstrapstyle["col-12"]}`}>
								<div className={bootstrapstyle["float-md-right"]}>
									{/* <div className="widget-header  mr-3">
											<a href=" " className="icon icon-sm rounded-circle border"><i className="fa fa-shopping-cart"></i></a>
											<span className="badge badge-pill badge-danger notify">0</span>
									</div> */}
									<div className={`${uistyle["widget-header"]} ${uistyle.icontext}`}>
										<a href="/autenticacion/sesion" className={`${uistyle.icon} ${uistyle["icon-sm"]} ${bootstrapstyle["rounded-circle"]} ${bootstrapstyle.border}`}><i className="fa fa-user"></i></a>
										<div className={uistyle.text}>
											<span className={bootstrapstyle["text-muted"]}>Bienvenido!</span>
											<div> 
												<a href="/autenticacion/sesion">Iniciar sesión</a>
															{/* <a href=" "> | Register</a> */}
											</div>
										</div>
									</div>
								</div> 
							</div> 
						</div> 
					</div> 
				</section>
			</header> 
				
			<section className={`${bootstrapstyle.section} ${uistyle["section-pagetop"]} ${uistyle.bg}]`}>
				<div className={bootstrapstyle.container}>
					<h2 className={`${bootstrapstyle.h2} ${uistyle["title-page"]}`}>Catálogo de productos</h2>
					<nav className={bootstrapstyle.nav}>
						<ol className={`${bootstrapstyle.ol} ${bootstrapstyle.breadcrumb} ${bootstrapstyle["text-white"]}`}>
							<li className={`${bootstrapstyle["breadcrumb-item"]}`}>
								<a className={bootstrapstyle.a} href="/">Inicio</a>
							</li>
							<li className={`${bootstrapstyle["breadcrumb-item"]}`}>
								<a className={bootstrapstyle.a} href="/catalogue/vianney">Vianney</a>
							</li>
							<li className={`${bootstrapstyle["breadcrumb-item"]}`}>
								<a className={bootstrapstyle.a} href="/catalogue/balam">B'alam</a>
							</li>
						</ol>  
					</nav>
				</div>
			</section>

			<section className={`${bootstrapstyle.section} ${uistyle["padding-y"]}`}>
				<div className={bootstrapstyle.container}>
					<div className={bootstrapstyle.row}>
						<div>
							<article>
								<h1 className="text-center">TÉRMINOS Y CONDICIONES</h1>
								<p className="m-5 text-justify">
									El presente describe los términos y condiciones aplicables al uso del contenido, productos y/o servicios del sitio web Malem Group. Para hacer uso del contenido, productos y/o servicios del sitio web el usuario deberá sujetarse a los presentes términos y condiciones.
								</p>
							</article>

							<article>
								<h3 className="m-5">I. OBJETO</h3>
								<p className="m-5 text-justify">
									El objeto es regular el acceso y utilización del contenido, productos y/o servicios a disposición del público en general en el dominio <strong>malem.group</strong>.

									El titular se reserva el derecho de realizar cualquier tipo de modificación en el sitio web en cualquier momento y sin previo aviso, el usuario acepta dichas modificaciones.

									El acceso al sitio web por parte del usuario es libre y gratuito, la adqusición de productos y/o servicios implica un costo para el usuario.

									El sitio web admite el acceso a todo tipo personas sin importar edad, raza, genero o distinción alguna.

									El sitio web está dirigido a usuarios residentes en la República de Guatemala y cumple con la legislación establecida en dicho país, si el usuario reside en otro país y decide acceder al sitio web lo hará bajo su responsabilidad.

									La administración del sitio web puede ejercerse por terceros, es decir, personas distintas al titular, sin afectar esto los presentes términos y condiciones.
								</p>
							</article>
						
							<article>
								<h3 className="m-5">II. USUARIO</h3>
								<p className="m-5 text-justify">

									La actividad del usuario en el sitio web como publicaciones o comentarios estarán sujetos a los presentes términos y condiciones. El usuario se compromete a utilizar el contenido, productos y/o servicios de forma lícita, sin faltar a la moral o al orden público, absteniéndose de realizar cualquier acto que afecte los derechos de terceros o el funcionamiento del sitio web.

									El acceso al sitio web no supone una relación entre el usuario y el titular del sitio web.

								</p>
							</article>

							<article>
								<h3 className="m-5">III. ACCESO Y NAVEGACIÓN EN EL SITIO WEB</h3>
								<p className="m-5 text-justify">

									El titular no garantiza la continuidad y disponibilidad del contenido, productos y/o servicios en el sitito web, realizará acciones que fomenten el buen funcionamiento de dicho sitio web sin responsabilidad alguna.

									El titular no se responsabiliza de que el software esté libre de errores que puedan causar un daño al software y/o hardware del equipo del cual el usuario accede al sitio web. De igual forma, no se responsabiliza por los daños causados por el acceso y/o utilización del sitio web.
								</p>
							</article>

							<article>
								<h3 className="m-5">IV. POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS</h3>
								<p className="m-5 text-justify">

								El sitio web correspondiente al área de catalogos malem.group/catalogue/balam y malem.group/catalogue/vianney no hace uso alguno de cookies por lo que no tendrá a su disposición la obtención de datos personales en ningúna de sus formas.
								</p>
							</article>

							<article>
								<h3 className="m-5">V. POLÍTICA DE ENLACES</h3>						
								<p className="m-5 text-justify">
									Dentro del sitio web pueden existir enlaces a otros sitios de internet pertenecientes a terceros de los cuales no se hace responsable.
								</p>
							</article>

							<article>
								<h3 className="m-5">VI. POLÍTICA DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
								<p className="m-5 text-justify">
									El titular manifiesta tener los derechos de propiedad intelectual e industrial del sitio web incluyendo imágenes, archivos de audio o video, logotipos, marcas, colores, estructuras, tipografías, diseños y demás elementos que lo distinguen, protegidos por la legislación guatemalteca e internacional en materia de propiedad intelectual e industrial.

									El usuario se compromete a respetar los derechos de propiedad industrial e intelectual del titular pudiendo visualizar los elementos del sitio web, almacenarlos, copiarlos e imprimirlos exclusivamente para uso personal.
								</p>
							</article>

							<article>
								<h3 className="m-5">VII. LEGISLACIÓN Y JURISDICCIÓN APLICABLE</h3>
									<p className="m-5 text-justify">
										La relación entre el usuario y el titular se regirá por las legislaciones aplicables en Guatemala.

										Malem Group no se responsabiliza por la indebida utilización del contenido, productos y/o servicios del sitio web y del incumplimiento de los presentes términos y condiciones.
								</p>
							</article>

						</div>
					</div>
				</div> 
			</section>
				
			<footer className={`${bootstrapstyle.footer} ${uistyle["section-footer"]} ${bootstrapstyle["border-top"]} ${uistyle["padding-y"]}`}>
				<div className={bootstrapstyle.container}>
					<p className={bootstrapstyle["float-md-right"]}> 
						Malem Group &copy; Copyright {new Date().getFullYear() === 2021 ? 2021 : 2021 + " - " + new Date().getFullYear()}. Todos los derechos reservados
					</p>
					<p>
						<a className={bootstrapstyle.a} href="/terminos-y-condiciones">Términos y condiciones</a>
					</p>
				</div>
			</footer>
			
		</div>	
	</div>
	</>
	);
}
