import React, { useContext } from 'react';
import { UserContext } from 'UserContext';
import { Link } from 'react-router-dom';

import NotificationDropdown from 'components/Dropdowns/NotificationDropdown.js';
import ClientDropdown from 'components/Dropdowns/ClientDropdown.js';

export default function AdminSidebar() {
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  return (
    <>
      <nav className='md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6'>
        <div className='md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
          {/* Toggler */}
          <button
            className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
            type='button'
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}>
            <i className='fas fa-bars'></i>
          </button>
          {/* Brand */}
          <Link
            className='md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
            to='/admin/dashboard'>
            {userProfile.puesto == 1
              ? 'Propietario'
              : userProfile.puesto == 2 && 'Administración'}
          </Link>
          {/* User */}
          <ul className='md:hidden items-center flex flex-wrap list-none'>
            <li className='inline-block relative'>
              <NotificationDropdown />
            </li>
            <li className='inline-block relative'>
              <ClientDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }>
            {/* Collapse header */}
            <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300'>
              <div className='flex flex-wrap'>
                <div className='w-6/12'>
                  <Link
                    className='md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
                    to='#'>
                    {userProfile.puesto == 1
                      ? 'Propietario'
                      : userProfile.puesto == 2 && 'Administración'}
                  </Link>
                </div>
                <div className='w-6/12 flex justify-end'>
                  <button
                    type='button'
                    className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                    onClick={() => setCollapseShow('hidden')}>
                    <i className='fas fa-times'></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className='mt-6 mb-4 md:hidden'>
              <div className='mb-3 pt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal'
                />
              </div>
            </form>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />
            {/* Heading */}
            <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Opciones
            </h6>
            {/* Navigation */}

            <ul className='md:flex-col md:min-w-full flex flex-col list-none'>
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/dashboard') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/dashboard'>
                  <i
                    className={
                      'fas fa-tv mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/dashboard') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  {userProfile.puesto == 1
                    ? 'Panel de Propietario'
                    : userProfile.puesto == 2 && 'Panel de Administración'}
                </Link>
              </li>

              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/opciones') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/opciones'>
                  <i
                    className={
                      'fas fa-tools mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/opciones') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Opciones
                </Link>
              </li>
              {/* Comercios */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/comercios') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/comercios'>
                  <i
                    className={
                      'fas fa-store-alt mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/comercios') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Comercios
                </Link>
              </li>
              {/* Termina Comercios */}
              {/* Extensiones */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/extensiones') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/extensiones'>
                  <i
                    className={
                      'fas fa-store mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/extensiones') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Extensiones
                </Link>
              </li>
              {/* Termina Extensiones */}
              {/* Productos */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/productos') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/productos'>
                  <i
                    className={
                      'fas fa-table mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/productos') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Productos
                </Link>
              </li>
              {/* Termina Productos */}
              {/* Grupos */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/grupos') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/grupos'>
                  <i
                    className={
                      'fas fa-layer-group mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/grupos') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Grupos
                </Link>
              </li>
              {/* Termina Grupos */}
              {/* Elementos */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/elementos') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/elementos'>
                  <i
                    className={
                      'fas fa-atom mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/elementos') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Elementos
                </Link>
              </li>
              {/* Termina Elementos */}
              {/* Especificaciones */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/especificaciones') !==
                    -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/especificaciones'>
                  <i
                    className={
                      'fas fa-tags mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/admin/especificaciones'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Especificaciones
                </Link>
              </li>
              {/* Termina Especificaciones */}
              {/* Tags */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/tags') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/tags'>
                  <i
                    className={
                      'fas fa-hashtag mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/tags') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Hashtags
                </Link>
              </li>
              {/* Termina Tags */}
              {/* Tags */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/producto/tags') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/producto/tags'>
                  <i
                    className={
                      'fas fa-table fas fa-hashtag mr-2 text-sm ' +
                      (window.location.href.indexOf('/admin/producto/tags') !==
                      -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>
                  Productos
                  <i
                    className={
                      'fas fa-hashtag mx-2 text-sm ' +
                      (window.location.href.indexOf('/admin/producto/tags') !==
                      -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>
                  Hashtags
                </Link>
              </li>
              {/* Termina Tags */}
            </ul>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />
            {/* Heading */}

            <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Facturas
            </h6>

            <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-3'>
              {/* Administrar facturas */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/admin/facturas/consultar'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/facturas/consultar'>
                  <i
                    className={
                      'fas fa-check-double text-gray-400 mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/admin/facturas/consultar'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Administrar
                </Link>
              </li>
            </ul>

            <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-3'>
              {/* Auditar encargos */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/admin/encargos/auditar') !==
                    -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/encargos/auditar'>
                  <i
                    className={
                      'fas fa-box text-gray-400 mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/admin/encargos/auditar'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Encargos
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />

            <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Usuarios
            </h6>
            {/* Navigation */}

            {/* usuarios */}
            <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-3'>
              {/* Gestionar usuarios */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/admin/usuarios/gestionar'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/usuarios/gestionar'>
                  <i
                    className={
                      'fas fa-clipboard-list text-gray-400 mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/admin/usuarios/gestionar'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Gestionar
                </Link>
              </li>

              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/admin/usuarios/afiliados'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/usuarios/afiliados'>
                  <i
                    className={
                      'fas fa-user-tie text-gray-400 mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/admin/usuarios/afiliados'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Afiliados
                </Link>
              </li>

              {/* Registros de usuarios */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/admin/usuarios/registros'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/admin/usuarios/registros'>
                  <i
                    className={
                      'fas fa-fingerprint text-gray-500 mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/admin/usuarios/registros'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Registros
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />
            {/* Heading */}
            <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              {/* Documentation */}
            </h6>
            {/* Navigation */}
            {/* <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="inline-flex">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/colors/notus"
                  target="_blank"
                  className="text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                >
                  <i className="fas fa-paint-brush mr-2 text-gray-400 text-base"></i>
                  Styles
                </a>
              </li>

              <li className="inline-flex">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/alerts/notus"
                  target="_blank"
                  className="text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                >
                  <i className="fab fa-css3-alt mr-2 text-gray-400 text-base"></i>
                  CSS Components
                </a>
              </li>

              <li className="inline-flex">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/angular/overview/notus"
                  target="_blank"
                  className="text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                >
                  <i className="fab fa-angular mr-2 text-gray-400 text-base"></i>
                  Angular
                </a>
              </li>

              <li className="inline-flex">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/js/overview/notus"
                  target="_blank"
                  className="text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                >
                  <i className="fab fa-js-square mr-2 text-gray-400 text-base"></i>
                  Javascript
                </a>
              </li>

              <li className="inline-flex">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/nextjs/overview/notus"
                  target="_blank"
                  className="text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                >
                  <i className="fab fa-react mr-2 text-gray-400 text-base"></i>
                  NextJS
                </a>
              </li>

              <li className="inline-flex">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/react/overview/notus"
                  target="_blank"
                  className="text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                >
                  <i className="fab fa-react mr-2 text-gray-400 text-base"></i>
                  React
                </a>
              </li>

              <li className="inline-flex">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/svelte/overview/notus"
                  target="_blank"
                  className="text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                >
                  <i className="fas fa-link mr-2 text-gray-400 text-base"></i>
                  Svelte
                </a>
              </li>

              <li className="inline-flex">
                <a
                  href="https://www.creative-tim.com/learning-lab/tailwind/vue/overview/notus"
                  target="_blank"
                  className="text-gray-800 hover:text-gray-600 text-sm block mb-4 no-underline font-semibold"
                >
                  <i className="fab fa-vuejs mr-2 text-gray-400 text-base"></i>
                  VueJS
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </nav>
    </>
  );
}
