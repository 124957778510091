import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',

    width: '72.1mm',
    textAlign: 'justify',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textAlign: 'left',
    textTransform: 'none',

    width: '72.1mm',

    paddingRight: '20',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
});

const ReceiptTotal = ({ subtotal, descuento, total, items, adi }) => {
  function calcPercent(val, percentage) {
    let desc = (val * (percentage / 100)).toFixed(0);
    if (percentage > 0) return parseFloat(desc).toFixed(2);
    return (0).toFixed(2);
  }

  const valorTotalItems = items
    .map((item) => item.cantidad * item.valor)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <View style={styles.container}>
      <View style={styles.wrapper}>
        <View style={styles.row}>
          <Text>Subtotal</Text>
          <Text
            style={
              descuento > 0 && { backgroundColor: 'rgba(255, 255, 0, 0.5)' }
            }
          >
            {adi > 0 ? (
              <>Q{(parseFloat(subtotal) + parseFloat(adi)).toFixed(2)}</>
            ) : (
              <>Q{subtotal}</>
            )}
          </Text>
        </View>
        {(descuento > 0 || adi > 0) && (
          <View style={styles.row}>
            <Text>Desc</Text>
            <Text style={{ backgroundColor: 'rgba(255, 255, 0, 0.5)' }}>
              {adi > 0 ? (
                `- Q${adi}`
              ) : (
                <>
                  {descuento > 0 && '-'} Q
                  {calcPercent(valorTotalItems, descuento)}
                </>
              )}
            </Text>
          </View>
        )}

        {adi > 0 && (
          <View style={[styles.row, { fontSize: 12, fontWeight: 'bold' }]}>
            <Text>Precio especial ADI</Text>
            <Text>Q{subtotal}</Text>
          </View>
        )}
        {adi < 1 && (
          <View style={[styles.row, { fontSize: 14, fontWeight: 'bold' }]}>
            <Text>Total</Text>
            <Text>Q{total}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default ReceiptTotal;
