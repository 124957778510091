import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// Dropdown
import StockDropdown from "../../components/Dropdowns/StockDropdown.js";
import PageNumbers from "components/Table/PageNumbers.jsx";
// Loader
import Loader from "react-loader-spinner";

export default function CardStock({ data, dataSearch, dataStore, dataExtension, dataGroup, dataElement, dataSpec, info, URIExtension, rowExtension, optionsDropdown, ...props}) {

  const [slice, setSlice] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const color = "light";
  // Loading state
  const [loading, setLoading] = useState(true);
 
  useEffect(() => {

    let mounted = true;
    var dataPages = []
    var numberOfPages = Math.ceil(data.length / 10)
  
    for (var i = 1; i <= numberOfPages; i++) {
      dataPages.push(i)
    }

    if (mounted) {
      setPageNumbers(dataPages)
      defaultPage(dataPages)
      setLoading(false)
    }
    return () => {
      mounted = false
    }
  }, [data])

  function refreshOnSubmit() {
    props.recibir()
  }

  function fixPhotoArray (row) {    
    return row.split(',')[0]
  }

  function defaultPage(value) {
    var url = window.location.search
    var currentPage = Math.abs(parseInt(url.replace("?page=", "")))
    var index = currentPage - 1
    var lastPage = pageNumbers[pageNumbers.length - 1]

    if (typeof value === "number" && value < 0 && currentPage > 1) {
      changePage(index += value, currentPage += value)
    }
    else if (typeof value === "number" && value > 0 && currentPage < lastPage) {
      changePage(index += value, currentPage += value)
    }
    else if (typeof value === "object") {
      if (typeof currentPage !== "undefined" && currentPage <= value[value.length - 1] && currentPage != 0) {
        changePage(index, currentPage)
      }
      else {
        changePage(0, 1)
      }
    }
  }

  function changePage (index, row) {
    var startIndex = index * 10;
    var endIndex = row * 10;
    setSlice(data.slice([startIndex], [endIndex]));
    window.history.replaceState(null, "", `?page=${endIndex / 10}`)
  }

  const arrowPageChange = (value) => {
    defaultPage(value);
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-blue-900 text-white")
        }
      >        
        <PageNumbers info={info} color={color} pages={pageNumbers} changePage={changePage} arrowPageChange={arrowPageChange}/>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table 
            className="items-center w-full bg-transparent border-collapse"
          >
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >
                  ID
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Código</th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Nombre</th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Grupo</th>  
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Elemento</th>    
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Especificación</th>   
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Cantidad</th>   
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Valor</th>   
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Extra</th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Estado</th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Comercio</th>        
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200 "
                      : "bg-blue-800 text-blue-300 border-blue-700 ") +
                    (optionsDropdown === false
                      ? "hidden"
                      : "block"
                    )
                  }
                >Opciones</th>           
              </tr>
            </thead>
            <tbody>
              {slice.map((row, index) => (
                <tr 
                  key={index}
                  className={
                    (( index & 1 ) ? "bg-gray-100" : "hover:bg-gray-100")
                  }
                >
                  <td 
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200"
                  >
                    {row.id}
                  </td>
                  <td className="border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {row.codigo}
                  </td>  
                  <td 
                    className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center"
                  >
                    <div className="bg-white rounded-full border p-0.5">
                      <div className="block relative h-12 w-12 text-center whitespace-nowrap">
                        <div className="inline-block h-full align-middle"></div>
                        {((row.foto.indexOf(',') > -1) ?
                          <img
                            src={((process.env.NODE_ENV === "development") ? ("http://localhost:3000/api/resources/" + fixPhotoArray(row.foto)) : ("https://malem.group/api/resources/" + fixPhotoArray(row.foto)))}
                            className="rounded-3xl inline-block max-h-12 max-w-12 align-middle"
                            alt={row.id}
                          />
                          : 
                          <img
                            src={((process.env.NODE_ENV === "development") ? ("http://localhost:3000/api/resources/" + row.foto) : ("https://malem.group/api/resources/" + row.foto))}
                            className="rounded-3xl inline-block max-h-12 max-w-12 align-middle"
                            alt={row.id}
                          />
                        )}
                      </div>
                    </div>
                    <span
                      style={{width:"150px",maxWidth:"150px",whiteSpace:"pre-wrap",wordWrap:"break-word"}}
                      className={
                        "ml-3 " +
                        (color === "light" ? "bg-gray-000 text-gray-700 border-gray-200" : "text-white")                        
                      }
                    >
                      {row.nombre}
                    </span>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {row.grupo}
                  </td>  
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {row.elemento}
                  </td>  
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {row.especificacion}
                  </td>  
                  <td className="border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {row.cantidad}
                  </td>  
                  <td className="border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    Q{row.valor}
                  </td>  
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {(row.extra==="Si") ? (<><i className="fas fa-circle text-green-500 mr-2"></i>{row.extra}</>) : (<><i className="fas fa-circle text-red-500 mr-2"></i>{row.extra}</>)}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {(row.activo==="Activo") ? (<><i className="fas fa-circle text-green-500 mr-2"></i>{row.activo}</>) : (<><i className="fas fa-circle text-red-500 mr-2"></i>{row.activo}</>)}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {row.comercio}
                  </td>
                  <td 
                    className={
                      "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right " +
                      (optionsDropdown === false
                          ? "hidden"
                          : "block"
                      )
                    }
                  >
                  { loading
                  ? <Loader type="MutatingDots" color="#9fd8df" secondaryColor="#4b778d" height={100} width={100} className="loader-middle" />
                  : <StockDropdown
                      _id={row.id}
                      rowPhoto={row.foto}
                      _barCode={row.barcode}
                      producto_codigo={row.codigo}
                      rowDataName={row.nombre}
                      producto_descripcion={row.descripcion}
                      rowBusiness={row.comercio} 
                      rowExtension={row.extension} 
                      rowGroup={row.grupo} 
                      rowElement={row.elemento} 
                      rowSpecification={row.especificacion} 
                      rowQuantity={row.cantidad} 
                      rowValue={row.valor} 
                      rowExtraI={row.extraI} 
                      rowDataActiveI={row.activoI} 
                      rowBusinessId={row.comercioId}
                      rowExtensionId={row.extensionId}
                      rowGroupId={row.grupoId} 
                      rowElementId={row.elementoId} 
                      rowSpecificationId={row.especificacionId} 
                      dataStore={dataStore} 
                      dataExtension={dataExtension} 
                      dataGroup={dataGroup} 
                      dataElement={dataElement} 
                      dataSpec={dataSpec} 
                      info={info} 
                      URIExtension={URIExtension} 
                      recibir={refreshOnSubmit} 
                    />
                  }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <PageNumbers info={info} color={color} pages={pageNumbers} changePage={changePage} arrowPageChange={arrowPageChange}/>
      </div>
    </>
  );
};

CardStock.defaultProps = {
  color: "light",
};

CardStock.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
