import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { createPopper } from '@popperjs/core';
import Modal from 'components/Modals/Modal.js';
// Alertify
import alertify from 'alertifyjs';
// Cookies
import { Cookies } from 'react-cookie';
import icon from 'assets/img/PngItem_1503945.png';

const ClientDropdown = () => {
  // Modal
  const modalLogout = useRef(null);
  // Dropdown states
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'left-start',
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  // Alerts
  const [alert, setAlert] = useState('');

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert);
    setAlert('');
  }

  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
      useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
      if (event.key === 'Escape') {
        closeDropdownPopover();
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !btnDropdownRef.current.contains(event.target)) {
        closeDropdownPopover();
      }
    };

    const handleTouch = (event) => {
      if (
        popoverDropdownRef.current.classList.length > 0 &&
        Object.keys(popoverDropdownRef.current.classList).find(
          (key) => popoverDropdownRef.current.classList[key] === 'escondido'
        ) == '0'
      ) {
      } else {
        if (!popoverDropdownRef.current.contains(event.target)) {
          closeDropdownPopover();
        }
      }
    };

    useEffect(() => {
      if (window.innerWidth > 767) {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
      } else {
        document.addEventListener('touchstart', handleTouch, true);
        document.addEventListener('touchend', handleTouch, true);
        document.addEventListener('touchcancel', handleTouch, true);
        document.addEventListener('touchleave', handleTouch, true);
        document.addEventListener('touchmove', handleTouch, true);
      }

      return () => {
        if (window.innerWidth > 767) {
          document.removeEventListener('keydown', handleHideDropdown, true);
          document.removeEventListener('click', handleClickOutside, true);
        } else {
          document.removeEventListener('touchstart', handleTouch, true);
          document.removeEventListener('touchend', handleTouch, true);
          document.removeEventListener('touchcancel', handleTouch, true);
          document.removeEventListener('touchleave', handleTouch, true);
          document.removeEventListener('touchmove', handleTouch, true);
        }
      };
    }, [handleHideDropdown, handleClickOutside, handleTouch]);

    return { ref, isComponentVisible, setIsComponentVisible };
  }

  function submitLogout() {
    fetch('/api/logout/user/token', {
      method: 'GET',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((result) => {
        setAlert(result.message);
        if (result.LOG_OUT) {
          const cookies = new Cookies();

          cookies.remove('MALEM__PROFILE', { path: '/' });

          setTimeout(() => {
            window.location.href = window.location.origin;
          }, 3000);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSubmitLogout = (evt) => {
    evt.preventDefault();
    submitLogout();
  };

  return (
    <>
      <button
        className='text-gray-600 block'
        ref={btnDropdownRef}
        onClick={(e) => {
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          dropdownPopoverShow
            ? closeDropdownPopover()
            : setIsComponentVisible(true);
        }}>
        <div className='items-center flex'>
          <span className='w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full'>
            <img
              alt='...'
              className='w-full rounded-full align-middle border-none shadow-md'
              src={icon}
            />
          </span>
        </div>
      </button>

      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? 'bloque ' : 'escondido ') +
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48'
        }>
        {isComponentVisible && (
          <div ref={ref}>
            <Link
              to='/admin/dashboard'
              className={
                'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }>
              Panel
            </Link>
            <div className='h-0 my-2 border border-solid border-gray-200' />
            <Link
              to=''
              className={
                'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800 cursor-pointer z-10 relative'
              }
              onClick={(e) => {
                e.preventDefault();
                modalLogout.current.open();
              }}>
              Cerrar sesión
            </Link>
          </div>
        )}
        <Modal ref={modalLogout}>
          <form onSubmit={handleSubmitLogout}>
            <div className='text-gray-300 font-bold md:text-left mb-1 md:mb-0 pr-4'>
              Cerrar sesión
            </div>
            <div className='my-4'>
              <label
                htmlFor={'nombre'}
                className='text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'>
                En verdad desea cerrar sesión?
              </label>
              <div className='my-4'>
                <div className='flex'>
                  <button
                    type='button'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3.5 rounded mr-2 whitespace-pre-line'
                    onClick={() => modalLogout.current.close()}>
                    No, cerrar ventana
                  </button>
                  <button
                    type='submit'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3.5 rounded ml-2 whitespace-pre-line'>
                    Si, deseo cerrar sesión
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};

export default ClientDropdown;
