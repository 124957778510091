import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    textAlign: 'left',
    width: '72.1mm',
  },
});

var d = new Date();
var year = d.getFullYear();

const ReceiptDate = ({ receipt }) => (
  <Fragment>
    <View style={styles.container}>
      <Text>Fecha: {receipt.fecha}</Text>
      <Text>No. {String(year) + receipt.id}</Text>
    </View>
  </Fragment>
);

export default ReceiptDate;
