import React, { Fragment } from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import balamLogo from 'assets/img/Balam/Jaguar Transparente.png';
import vianneyLogo from 'assets/img/Vianney/Vianney.png';
import niceRoomLogo from 'assets/img/Vianney/Nice-Room.jpg';

const styles = StyleSheet.create({
  balamContainer: {
    textAlign: 'center',
    width: '72.1mm',
    marginTop: 22,
    marginBottom: 10,
  },
  balamLogo: {
    marginBottom: 4.5,
    marginHorizontal: 48,
    textAlign: 'center',
  },
  balamTexto: {
    fontFamily: 'Dancing Script',
    fontSize: 30,
    textTransform: 'initial',
  },
  vianneyContainer: {
    textAlign: 'center',
    width: '72.1mm',
    marginBottom: 18,
    marginTop: 18,
  },
  niceRoomLogo: {
    width: '60mm',
    marginVertical: 3.5,
    marginHorizontal: '5.5mm',
    marginBottom: 6,
  },
  vianneyLogo: {
    marginVertical: 3.5,
    marginHorizontal: 65,
    marginBottom: -15,
  },
});

const ReceiptLogo = ({ title }) => (
  <Fragment>
    {title == "B'alam" ? (
      <View style={styles.balamContainer}>
        <Image style={styles.balamLogo} src={balamLogo} />
        <Text style={styles.balamTexto}>{title}</Text>
      </View>
    ) : (
      <View style={styles.vianneyContainer}>
        <Image style={styles.niceRoomLogo} src={niceRoomLogo} />
        <Image style={styles.vianneyLogo} src={vianneyLogo} />
      </View>
    )}
  </Fragment>
);

export default ReceiptLogo;
