import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// Dropdown
import EncargosDropdown from '../../components/Dropdowns/EncargosDropdown.js';
import PageNumbers from 'components/Table/PageNumbers.jsx';
// Loader
import Loader from 'react-loader-spinner';
import { Fragment } from 'react';

export default function CardEncargos({ orders, ...props }) {
  const [slice, setSlice] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const color = 'light';
  // Loading state
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    var dataPages = [];
    var numberOfPages = Math.ceil(orders.length / 10);

    for (var i = 1; i <= numberOfPages; i++) {
      dataPages.push(i);
    }

    if (mounted) {
      setPageNumbers(dataPages);
      defaultPage(dataPages);
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [orders]);

  function refreshOnSubmit() {
    props.recibir();
  }

  function defaultPage(value) {
    var url = window.location.search;
    var currentPage = Math.abs(parseInt(url.replace('?page=', '')));
    var index = currentPage - 1;
    var lastPage = pageNumbers[pageNumbers.length - 1];

    if (typeof value === 'number' && value < 0 && currentPage > 1) {
      changePage((index += value), (currentPage += value));
    } else if (
      typeof value === 'number' &&
      value > 0 &&
      currentPage < lastPage
    ) {
      changePage((index += value), (currentPage += value));
    } else if (typeof value === 'object') {
      if (
        typeof currentPage !== 'undefined' &&
        currentPage <= value[value.length - 1] &&
        currentPage != 0
      ) {
        changePage(index, currentPage);
      } else {
        changePage(0, 1);
      }
    }
  }

  function changePage(index, row) {
    var startIndex = index * 10;
    var endIndex = row * 10;
    setSlice(orders.slice([startIndex], [endIndex]));
    window.history.replaceState(null, '', `?page=${endIndex / 10}`);
  }

  const arrowPageChange = (value) => {
    defaultPage(value);
  };

  function handleViewOrder(rowDataId) {
    props.viewOrder(rowDataId);
  }

  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (color === 'light' ? 'bg-white' : 'bg-blue-900 text-white')
        }>
        <PageNumbers
          info='Encargos'
          color={color}
          pages={pageNumbers}
          changePage={changePage}
          arrowPageChange={arrowPageChange}
        />
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Fecha
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Afiliado
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Comercio
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Pagado
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Forma de Pago
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Entrega
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Total
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Estado
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200 '
                      : 'bg-blue-800 text-blue-300 border-blue-700 ')
                  }>
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {slice.map((row, index) => (
                <tr
                  key={index}
                  className={index & 1 ? 'bg-gray-100' : 'hover:bg-gray-100'}>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.fecha}
                  </td>
                  <td className='border-t-0 px-6 text-left align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.afiliado}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.comercio}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.pagado}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.forma}
                  </td>
                  <td className='border-t-0 px-6 text-left align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.entrega == 'Sin entregar'
                      ? '25%'
                      : row.entrega == 'Pendiente'
                      ? '50%'
                      : '100%'}
                  </td>
                  <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.total}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.activo == 1 ? (
                      <Fragment>
                        <i className='fas fa-circle text-green-500 mr-2'></i>
                        Activo
                      </Fragment>
                    ) : (
                      <Fragment>
                        <i className='fas fa-circle text-red-500 mr-2'></i>
                        No Activo
                      </Fragment>
                    )}
                  </td>
                  <td
                    className={
                      'border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right '
                    }>
                    {loading ? (
                      <Loader
                        type='MutatingDots'
                        color='#9fd8df'
                        secondaryColor='#4b778d'
                        height={100}
                        width={100}
                        className='loader-middle'
                      />
                    ) : (
                      <EncargosDropdown
                        orders={row}
                        recibir={refreshOnSubmit}
                        viewOrder={handleViewOrder}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <PageNumbers
          info='Encargos'
          color={color}
          pages={pageNumbers}
          changePage={changePage}
          arrowPageChange={arrowPageChange}
        />
      </div>
    </>
  );
}

CardEncargos.defaultProps = {
  color: 'light',
};

CardEncargos.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
};
