import React from "react";
import PropTypes from "prop-types";

// components
import ManageDropdown from "../../components/Dropdowns/ManageDropdown.js";

export default function CardUser({ data, dataShop, dataExtension, info, URIExtension, ...props }) {

  const color = "light";
  
  function refreshOnSubmit() {
    props.recibir()
  }

  return (
    <>
    <div
      className={
        "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
        (color === "light" ? "bg-white" : "bg-blue-900 text-white")
      }
    >
      <div className="rounded-t mb-0 px-4 py-3 border-0">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full px-4 max-w-full flex-grow flex-1">
            <h3
              className={
                "font-semibold text-lg " +
                (color === "light" ? "text-gray-800" : "text-white")
              }
            >
              Usuarios
            </h3>
          </div>
        </div>
      </div>
      <div className="block w-full overflow-x-auto">
        {/* Projects table */}
        <table className="items-center w-full bg-transparent border-collapse">
          <thead>
            <tr>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >ID</th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Nombre</th>
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Email</th>           
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Departamento</th>    
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Dirección</th>    
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Ocupación</th>    
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Comercio</th>  
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Extension</th>   
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Puesto</th>    
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Teléfono</th>    
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Descripción</th>        
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Estado</th>    
              <th
                className={
                  "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right " +
                  (color === "light"
                    ? "bg-gray-100 text-gray-600 border-gray-200"
                    : "bg-blue-800 text-blue-300 border-blue-700")
                }
              >Opciones</th>           
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                  {row.id}
                </td>                    
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.nombre}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.email}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.departamento}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.direccion}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.ocupacion}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.comercio}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.extension}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.puesto}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.telefono}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.descripcion}</td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">                    
                  {(row.activo==="Activo") ? (<><i className="fas fa-circle text-green-500 mr-2"></i>{row.activo}</>) : (<><i className="fas fa-circle text-red-500 mr-2"></i>{row.activo}</>)}
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right">
                  <ManageDropdown 
                    dataId = {row.id} 
                    dataName = {row.nombre}
                    dataActiveRaw = {row.activoRaw} 
                    dataEmail = {row.email} 
                    dataPassword = {row.password}
                    dataDepartament = {row.departamento} 
                    dataAddress = {row.direccion} 
                    dataJob = {row.ocupacion}                       
                    dataTaskRaw = {row.puestoRaw} 
                    dataPhoneNumber = {row.telefono} 
                    dataDescription = {row.descripcion}                       
                    dataUserShopId = {row.comercioUserId}
                    dataUserExtensionId = {row.extensionUserId}
                    dataShop = {dataShop}
                    dataExtension = {dataExtension}
                    info = {info} 
                    URIExtension = {URIExtension} 
                    recibir = {refreshOnSubmit} 
                  />
                </td>                  
              </tr>
            ))}              
          </tbody>
        </table>
      </div>
    </div>
  </>
  );
}

CardUser.defaultProps = {
  color: "light",
};

CardUser.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
