import React from 'react';
import PropTypes from 'prop-types';
import AffiliatesDropdown from './../Dropdowns/AffiliatesDropdown.js';
import { Fragment } from 'react';

export default function CardUser({ affiliates, ...props }) {
  const color = 'light';

  function refreshOnSubmit() {
    props.recibir();
  }
  function shoppingHistory(val) {
    props.shoppingHistory(val);
  }

  return (
    <Fragment>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (color === 'light' ? 'bg-white' : 'bg-blue-900 text-white')
        }>
        <div className='rounded-t mb-0 px-4 py-3 border-0'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
              <h3
                className={
                  'font-semibold text-lg ' +
                  (color === 'light' ? 'text-gray-800' : 'text-white')
                }>
                <i className='fas fa-user-tie'></i> Afiliados
              </h3>
            </div>
          </div>
        </div>
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  ID
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Código
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Nombre
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Teléfono
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Email
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Estado
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {affiliates.map((row, index) => (
                <tr key={index}>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.id}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.codigo}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.nombre}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.telefono}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.email}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.estado === 1 ? (
                      <Fragment>
                        <i className='fas fa-circle text-green-500 mr-2'></i>
                        Activo
                      </Fragment>
                    ) : (
                      <Fragment>
                        <i className='fas fa-circle text-red-500 mr-2'></i>
                        No Activo
                      </Fragment>
                    )}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right'>
                    <AffiliatesDropdown
                      affiliates={row}
                      recibir={refreshOnSubmit}
                      shoppingHistory={shoppingHistory}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
}

CardUser.defaultProps = {
  color: 'light',
};

CardUser.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
};
