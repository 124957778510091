/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';

import NotificationDropdown from 'components/Dropdowns/NotificationDropdown.js';
import ClientDropdown from 'components/Dropdowns/ClientDropdown.js';

export default function AdminSidebar() {
  const [collapseShow, setCollapseShow] = React.useState('hidden');
  return (
    <>
      <nav className='md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-no-wrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6'>
        <div className='md:flex-col md:items-stretch md:min-h-full md:flex-no-wrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
          {/* Toggler */}
          <button
            className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
            type='button'
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}>
            <i className='fas fa-bars'></i>
          </button>
          {/* Brand */}
          <Link
            className='md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
            to='/managment/dashboard'>
            Administración
          </Link>
          {/* User */}
          <ul className='md:hidden items-center flex flex-wrap list-none'>
            <li className='inline-block relative'>
              <NotificationDropdown />
            </li>
            <li className='inline-block relative'>
              <ClientDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }>
            {/* Collapse header */}
            <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-gray-300'>
              <div className='flex flex-wrap'>
                <div className='w-6/12'>
                  <Link
                    className='md:block text-left md:pb-2 text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
                    to='#'>
                    Administración
                  </Link>
                </div>
                <div className='w-6/12 flex justify-end'>
                  <button
                    type='button'
                    className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                    onClick={() => setCollapseShow('hidden')}>
                    <i className='fas fa-times'></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className='mt-6 mb-4 md:hidden'>
              <div className='mb-3 pt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className='px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal'
                />
              </div>
            </form>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />
            {/* Heading */}
            <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Opciones
            </h6>
            {/* Navigation */}

            <ul className='md:flex-col md:min-w-full flex flex-col list-none'>
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/managment/dashboard') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/dashboard'>
                  <i
                    className={
                      'fas fa-tv mr-2 text-sm ' +
                      (window.location.href.indexOf('/managment/dashboard') !==
                      -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Panel de Administración
                </Link>
              </li>

              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/managment/opciones') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/opciones'>
                  <i
                    className={
                      'fas fa-tools mr-2 text-sm ' +
                      (window.location.href.indexOf('/managment/opciones') !==
                      -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Opciones
                </Link>
              </li>
              {/* Productos */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/managment/productos') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/productos'>
                  <i
                    className={
                      'fas fa-table mr-2 text-sm ' +
                      (window.location.href.indexOf('/managment/productos') !==
                      -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Productos
                </Link>
              </li>
              {/* Termina Productos */}
              {/* Grupos */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/managment/grupos') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/grupos'>
                  <i
                    className={
                      'fas fa-layer-group mr-2 text-sm ' +
                      (window.location.href.indexOf('/managment/grupos') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Grupos
                </Link>
              </li>
              {/* Termina Grupos */}
              {/* Elementos */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/managment/elementos') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/elementos'>
                  <i
                    className={
                      'fas fa-atom mr-2 text-sm ' +
                      (window.location.href.indexOf('/managment/elementos') !==
                      -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Elementos
                </Link>
              </li>
              {/* Termina Elementos */}
              {/* Especificaciones */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/managment/especificaciones'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/especificaciones'>
                  <i
                    className={
                      'fas fa-tags mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/managment/especificaciones'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Especificaciones
                </Link>
              </li>
              {/* Termina Especificaciones */}
              {/* Tags */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/managment/tags') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/tags'>
                  <i
                    className={
                      'fas fa-hashtag mr-2 text-sm ' +
                      (window.location.href.indexOf('/managment/tags') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Tags
                </Link>
              </li>
              {/* Termina Tags */}
              {/* Tags */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/managment/producto/tags'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/producto/tags'>
                  <i
                    className={
                      'fas fa-table fas fa-hashtag mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/managment/producto/tags'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>
                  Productos
                  <i
                    className={
                      'fas fa-hashtag mx-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/managment/producto/tags'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>
                  Tags
                </Link>
              </li>
              {/* Termina Tags */}
            </ul>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />
            {/* Heading */}

            <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Facturas
            </h6>

            <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-3'>
              {/* Administrar facturas */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/managment/facturas/consultar'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/facturas/consultar'>
                  <i
                    className={
                      'fas fa-check-double text-gray-400 mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/managment/facturas/consultar'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Administrar
                </Link>
              </li>
            </ul>

            <ul className='md:flex-col md:min-w-full flex flex-col list-none md:mb-3'>
              {/* Auditar encargos */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/managment/encargos/auditar'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/managment/encargos/auditar'>
                  <i
                    className={
                      'fas fa-box text-gray-400 mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/managment/encargos/auditar'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Encargos
                </Link>
              </li>
            </ul>

            {/* Divider */}
            <hr className='my-4 md:min-w-full' />

            {/* <h6 className="md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Usuarios
            </h6> */}
            {/* Navigation */}

            {/* usuarios */}
            {/* <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-3">                   
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/managment/usuarios/gestionar") !== -1
                    ? "text-blue-500 hover:text-blue-600"
                    : "text-gray-800 hover:text-gray-600")
                  }
                  to="/managment/usuarios/gestionar"
                >
                  <i 
                    className={
                    "fas fa-clipboard-list text-gray-400 mr-2 text-sm " +
                    (window.location.href.indexOf("/managment/usuarios/gestionar") !== -1
                    ? "opacity-75"
                    : "text-gray-400")
                  }
                  ></i>{" "}
                  Gestionar
                </Link>
              </li>
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/managment/usuarios/registros") !== -1
                    ? "text-blue-500 hover:text-blue-600"
                    : "text-gray-800 hover:text-gray-600")
                  }
                  to="/managment/usuarios/registros"
                >
                  <i 
                    className={
                      "fas fa-fingerprint text-gray-500 mr-2 text-sm " +
                      (window.location.href.indexOf("/managment/usuarios/registros") !== -1
                        ? "opacity-75"
                        : "text-gray-400")
                    }
                  ></i>{" "}
                  Registros
                </Link>
              </li>
            </ul> */}
            {/* <hr className='my-4 md:min-w-full' /> */}
            <h6 className='md:min-w-full text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'></h6>
          </div>
        </div>
      </nav>
    </>
  );
}
