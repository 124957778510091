import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from 'UserContext';

import ImageUploader from 'react-images-upload';
import alertify from 'alertifyjs';
import Loader from 'react-loader-spinner';
import Modal from 'components/Modals/Modal.js';

const StockDropdown = ({
  _id,
  rowPhoto,
  _barCode,
  producto_codigo,
  rowDataName,
  producto_descripcion,
  rowBusiness,
  rowExtension,
  rowGroup,
  rowElement,
  rowSpecification,
  rowQuantity,
  rowValue,
  rowExtraI,
  rowDataActiveI,
  rowBusinessId,
  rowExtensionId,
  rowGroupId,
  rowElementId,
  rowSpecificationId,
  dataStore,
  dataExtension,
  dataGroup,
  dataElement,
  dataSpec,
  info,
  URIExtension,
  ...props
}) => {
  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
      useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
      if (event.key === 'Escape') {
        closeDropdownPopover();
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !btnDropdownRef.current.contains(event.target)) {
        closeDropdownPopover();
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
      };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
  }

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  const [id, setId] = useState(null);
  const [barCode, setBarCode] = useState(null);
  const [productoCodigo, setProductoCodigo] = useState(producto_codigo);
  const [nameDataRow, setNameDataRow] = useState(rowDataName);
  const [productoDescripcion, setDescripcion] = useState(producto_descripcion);
  const [activeDataRow, setActiveDataRow] = useState(rowDataActiveI);
  const [comercioProducto, setComercioProducto] = useState(rowBusinessId);
  const [extensionProducto, setExtensionProducto] = useState(rowExtensionId);
  const [grupoProducto, setGrupoProducto] = useState(rowGroupId);
  const [elementoProducto, setElementoProducto] = useState(rowElementId);
  const [especificacionProducto, setEspecificacionProducto] =
    useState(rowSpecificationId);
  const [extraProducto, setExtraProducto] = useState(rowExtraI);
  const [productoFoto, setProductoFoto] = useState('');
  const [productoCantidad, setProductoCantidad] = useState(rowQuantity);
  const [productoCantidadOld, setProductoCantidadOld] = useState(rowQuantity);
  const [productoValor, setProductoValor] = useState(rowValue);
  const URIExtensiontPhoto = 'photo';
  const URI = '/api/update/';
  const URIDelete = '/api/delete/';
  const [pictures, setPictures] = useState([]);
  const [alert, setAlert] = useState('');
  const [loading, setLoading] = useState(true);

  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert, function () {
      if (typeof modalEdit.current === 'object') {
        modalEdit.current.close();
      }
      if (typeof modalRemove.current === 'object') {
        modalRemove.current.close();
      }
      alertify.success('Muy bien!');
    });
    setAlert('');
  }

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setId(_id);
      setBarCode(_barCode);
      setProductoCodigo(producto_codigo);
      setNameDataRow(rowDataName);
      setDescripcion(producto_descripcion);
      setActiveDataRow(rowDataActiveI);
      setComercioProducto(rowBusinessId);
      setExtensionProducto(rowExtensionId);
      setGrupoProducto(rowGroupId);
      setElementoProducto(rowElementId);
      setEspecificacionProducto(rowSpecificationId);
      setExtraProducto(rowExtraI);
      setProductoFoto('');
      setProductoCantidad(rowQuantity);
      setProductoCantidadOld(rowQuantity);
      setProductoValor(rowValue);
      setLoading(false);
    }
    return () => {
      mounted = false;
    };
  }, [
    _id,
    _barCode,
    producto_codigo,
    rowDataName,
    producto_descripcion,
    rowDataActiveI,
    rowBusinessId,
    rowExtensionId,
    rowGroupId,
    rowElementId,
    rowSpecificationId,
    rowExtraI,
    rowQuantity,
    rowValue,
  ]);

  const onDrop = (picture) => {
    setPictures([...pictures, picture]);
    setProductoFoto(picture);
  };

  function submitDataEdit() {
    if (userProfile.puesto !== 1) {
      if (productoCantidadOld > productoCantidad) {
        setProductoCantidad(productoCantidadOld);
        return setAlert('El producto no puede ser reducido!');
      }
    }

    const postPhoto = () => {
      const fd = new FormData();

      productoFoto.forEach((file) => {
        fd.append('image', file);
      });

      fetch(URI + URIExtensiontPhoto, {
        method: 'PUT',
        body: fd,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            postData(json.photoPath);
          } else {
            setAlert(json.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const postData = (photoPath) => {
      var datos = [];

      datos.push({
        id,
        barCode,
        productoCodigo,
        nameDataRow,
        productoDescripcion,
        activeDataRow,
        comercioProducto,
        extensionProducto,
        grupoProducto,
        elementoProducto,
        especificacionProducto,
        extraProducto,
        productoCantidad,
        productoValor,
        photoPath,
      });

      fetch(URI + URIExtension, {
        method: 'PUT',
        credentials: 'same-origin',
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(datos),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            setAlert(json.message);
            props.recibir();
          } else {
            setAlert(json.message);
          }
        })
        .catch((err) => {
          cons0ole.error(err);
        });
    };
    if (productoFoto) {
      postPhoto();
    } else {
      postData();
    }
  }

  function submitDataRemove() {
    var datos = [];

    datos.push({
      id,
    });

    fetch(URIDelete + URIExtension, {
      method: 'PUT',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          setAlert(json.message);
          props.recibir();
        } else {
          setAlert(json.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    submitDataEdit();
  };

  const handleSubmitRemove = (e) => {
    e.preventDefault();
    if (superAdminPW === '097') {
      submitDataRemove();
    }
  };

  const modalEdit = useRef(null);
  const modalRemove = useRef(null);

  const [superAdminPW, setSuperAdminPW] = useState('');

  return (
    <>
      {loading ? (
        <Loader
          type='MutatingDots'
          color='#9fd8df'
          secondaryColor='#4b778d'
          height={100}
          width={100}
          className='loader-middle'
        />
      ) : (
        <>
          <a
            className='text-gray-600 py-1 px-3'
            href=' '
            ref={btnDropdownRef}
            onClick={(e) => {
              e.preventDefault();
              dropdownPopoverShow
                ? closeDropdownPopover()
                : openDropdownPopover();
              dropdownPopoverShow
                ? closeDropdownPopover()
                : setIsComponentVisible(true);
            }}
          >
            <i className='fas fa-ellipsis-v'></i>
          </a>
          <div
            ref={popoverDropdownRef}
            className={
              'bg-white text-base z-50 float-left py-2 list-none text-left rounded-md shadow-lg w-36 ' +
              (dropdownPopoverShow ? 'message-globe' : 'hide-message')
            }
          >
            {isComponentVisible && (
              <div ref={ref}>
                <a
                  href=' '
                  className={
                    'edit-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    modalEdit.current.open();
                  }}
                >
                  <i className='far fa-edit mr-2'></i>
                  Editar
                </a>

                {userProfile.puesto == 1 ? (
                  <a
                    href=' '
                    className={
                      'delete-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      modalRemove.current.open();
                    }}
                  >
                    <i className='fas fa-eraser mr-2'></i>
                    Eliminar
                  </a>
                ) : null}
              </div>
            )}
          </div>
        </>
      )}
      <>
        <Modal ref={modalEdit}>
          <form
            onSubmit={handleSubmitEdit}
            className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 max-w-580-px'
          >
            <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-align-center'>
              Editar {info}
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'barcode' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Código de barras
              </label>
              <input
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'barcode' + info}
                name={'barcode' + info}
                placeholder='Código de barras'
                onChange={(e) => setBarCode(e.target.value)}
                value={barCode}
              ></input>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'codigo' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Código del {info}
              </label>
              <input
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'codigo' + info}
                name={'codigo' + info}
                placeholder={'Código de ' + info}
                onChange={(e) => setProductoCodigo(e.target.value)}
                value={productoCodigo}
              ></input>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'nombre' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Nombre del {info}
              </label>
              <input
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'nombre' + info}
                name={'nombre' + info}
                placeholder={'Nombre de ' + info}
                onChange={(e) => setNameDataRow(e.target.value)}
                value={nameDataRow}
              ></input>
            </div>
            <div className='my-4 flex items-baseline justify-center max-w-full'>
              <label
                htmlFor={'foto' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Foto del {info} a ingresar
              </label>
              <ImageUploader
                {...props}
                key={'image-uploader'}
                className='max-w-1/2 w-1/2'
                labelClass='w-full max-w-full text-center'
                buttonClassName='w-11/12'
                name={'foto' + info}
                withIcon={true}
                singleImage={false}
                withPreview={true}
                label='Tamaño máximo de archivo: 5 MB. Aceptado: jpg | jpeg | png'
                buttonText='Selecciona una imagen'
                onChange={onDrop}
                imgExtension={['.jpg', '.jpeg', '.png']}
                maxFileSize={5242880}
              />
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'descripcion' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Descripción del {info}
              </label>
              <textarea
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'descripcion' + info}
                name={'descripcion' + info}
                placeholder={'Descripción de ' + info}
                onChange={(e) => setDescripcion(e.target.value)}
                value={productoDescripcion}
              ></textarea>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'cantidad' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Cantidad actual de {info}
              </label>
              <input
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'cantidad' + info}
                name={'cantidad' + info}
                onChange={(e) => setProductoCantidad(e.target.value)}
                onKeyPress={(e) => {
                  if (!/[0-9.]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={productoCantidad}
              />
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'valor' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Valor de cada {info}
              </label>
              <input
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'valor' + info}
                name={'valor' + info}
                onChange={(e) => setProductoValor(e.target.value)}
                onKeyPress={(e) => {
                  if (!/[0-9.]/.test(e.key)) {
                    e.preventDefault();
                  }
                }}
                value={productoValor}
              ></input>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'activo' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Estado del {info}
              </label>
              <select
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'activo' + info}
                name={'activo' + info}
                onChange={(e) => setActiveDataRow(e.target.value)}
                value={activeDataRow}
              >
                <option value=''>Seleccionar</option>
                <option value='1'>Activo</option>
                <option value='2'>No activo</option>
              </select>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'comercio' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Comercio
              </label>
              <select
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'comercio' + info}
                name={'comercio' + info}
                onChange={(e) => setComercioProducto(e.target.value)}
                value={comercioProducto}
              >
                <option value=''>Seleccionar</option>
                {dataStore.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'extension' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Extensión
              </label>
              <select
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'extension' + info}
                name={'extension' + info}
                onChange={(e) => setExtensionProducto(e.target.value)}
                value={extensionProducto}
              >
                <option value='' defaultValue>
                  Seleccionar
                </option>
                {dataExtension.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'grupo' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Grupo
              </label>
              <select
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'grupo' + info}
                name={'grupo' + info}
                onChange={(e) => setGrupoProducto(e.target.value)}
                value={grupoProducto}
              >
                <option value='' defaultValue>
                  Seleccionar
                </option>
                {dataGroup.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'elemento' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Elemento
              </label>
              <select
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'elemento' + info}
                name={'elemento' + info}
                onChange={(e) => setElementoProducto(e.target.value)}
                value={elementoProducto}
              >
                <option value='' defaultValue>
                  Seleccionar
                </option>
                {dataElement.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'especificacion' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                Especificación
              </label>
              <select
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'especificacion' + info}
                name={'especificacion' + info}
                onChange={(e) => setEspecificacionProducto(e.target.value)}
                value={especificacionProducto}
              >
                <option value='' defaultValue>
                  Seleccionar
                </option>
                {dataSpec.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className='my-4 flex items-center justify-center max-w-full'>
              <label
                htmlFor={'extra' + info}
                className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
              >
                El {info} es un extra
              </label>
              <select
                className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                type='text'
                id={'extra' + info}
                name={'extra' + info}
                onChange={(e) => setExtraProducto(e.target.value)}
                value={extraProducto}
              >
                <option value=''>Seleccionar</option>
                <option value='1'>Si</option>
                <option value='2'>No</option>
              </select>
            </div>
            <div className='flex'>
              <button
                type='button'
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-1/2 w-1/2'
                onClick={() => modalEdit.current.close()}
              >
                Cerrar ventana
              </button>
              <button
                type='submit'
                className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-1/2 w-1/2'
              >
                Guardar cambios
              </button>
            </div>
          </form>
        </Modal>
        <Modal ref={modalRemove}>
          <form onSubmit={handleSubmitRemove} className='px-3'>
            <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-left'>
              Eliminar {info}
            </div>

            <div className='mt-4'>
              <div className='flex flex-col'>
                <h2 className='text-base'>
                  En verdad desea eliminar este Producto?
                </h2>
                <label
                  htmlFor={'nombre' + info}
                  className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 inline-block py-3'
                >
                  Ingrese la clave de Supervisor:
                </label>
                <input
                  id={'nombre' + info}
                  name={'nombre' + info}
                  type='password'
                  value={superAdminPW}
                  onChange={(e) => setSuperAdminPW(e.target.value)}
                  className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500'
                  placeholder='********'
                />
              </div>

              <div className='mt-4'>
                <div className='flex justify-between'>
                  <button
                    type='button'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-full inline-block'
                    onClick={() => modalRemove.current.close()}
                  >
                    Cancelar
                  </button>
                  <button
                    type='submit'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-full inline-block'
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </>
    </>
  );
};

export default StockDropdown;
