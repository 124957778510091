import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  invoiceDTEContainer: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginTop: 9,
    marginBottom: 8,
    width: '72.1mm',
    paddingLeft: '15%',
    paddingRight: '15%',
    textAlign: 'justify',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: -1,
    textAlign: 'left',
  },
});

var d = new Date();
var year = d.getFullYear();

const InvoiceDTE = ({ invoice }) => (
  <Fragment>
    <View style={styles.invoiceDTEContainer}>
      <View style={styles.container}>
        <Text>Set: {invoice.set}</Text>
        <Text>No. {String(year) + invoice.id}</Text>
      </View>
    </View>
  </Fragment>
);

export default InvoiceDTE;
