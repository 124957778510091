import React, { useState, useEffect } from 'react';
// components
import CardUser from 'components/Cards/CardUser.js';
import AlertRefresh from 'components/Alert/AlertRefresh.js';

export default function Registros() {
  // Fetch & Data Store From DB
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataShop, setDataShop] = useState([]);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState(['id', 'nombre']);
  const [mensajeData, setMensajeData] = useState('');
  // Alert Refresh
  const [reload, setReload] = useState('');
  const documentTitle = 'Registro';
  // documentTitle
  const URIExtension = 'records';
  const URI = '/api/data/';

  useEffect(() => {
    const fetchData = () => {
      return fetch(URI + URIExtension, { method: 'GET' })
        .then((response) => response.json())
        .then((json) => {
          if (json.message) {
            setMensajeData(json.message);
            if (json.reload) {
              setReload(json.reload);
            }
          } else {
            setData(json.data);
            setDataShop(json.dataShop);
            setDataSearch(json.dataSearch);
          }
        })
        .catch((error) => {
          console.log(error);
          setMensajeData(error);
        });
    };
    fetchData();
  }, []);

  function refreshOnSubmit() {
    refreshTable();
  }

  function refreshTable() {
    fetch(URI + URIExtension)
      .then((response) => response.json())
      .then((json) => {
        if (json.message) {
          setMensajeData(json.message);
          if (json.reload) {
            setReload(json.reload);
          }
        } else {
          setData(json.data);
        }
      })
      .catch((error) => {
        console.log(error);
        setMensajeData(error);
      });
  }

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  return (
    <>
      {mensajeData ? (alert(mensajeData), setMensajeData('')) : ''}
      <div className='mb-auto h-full'>
        <div className='flex justify-center flex-wrap mt-4'>
          <div className='w-full px-4'>
            <div className='block align-middle items-center justify-center mb-3 h-10'>
              <div className='my-auto pt-2 float-left mx-auto'>
                <input
                  className='border rounded-xl py-2 px-3 mr-4 text-gray-700'
                  type='text'
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
              {columns &&
                columns.map((column) => (
                  <div key={column} className='inline-flex'>
                    <div className='rounded-t-xl overflow-hidden p-2'>
                      <div className='p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md'>
                        <label className='flex items-center space-x-3'>
                          <input
                            className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none'
                            type='checkbox'
                            checked={searchColumns.includes(column)}
                            onChange={(e) => {
                              const checked = searchColumns.includes(column);
                              setSearchColumns((prev) =>
                                checked
                                  ? prev.filter((sc) => sc !== column)
                                  : [...prev, column]
                              );
                            }}
                          />
                          <span className='text-gray-900 font-normal space-x-3 font-sans capitalize text-sm'>
                            {column}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className='my-5 block'>
              <CardUser
                data={search(data)}
                dataShop={dataShop}
                documentTitle={documentTitle}
                URIExtension={URIExtension}
                recibir={refreshOnSubmit}
              />
            </div>
            <AlertRefresh reload={reload} />
          </div>
        </div>
      </div>
    </>
  );
}
