import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import SeparatorTop from '../SeparatorTop';
import SeparatorBottom from '../SeparatorBottom';

const styles = StyleSheet.create({
  headerContainer: {
    marginBottom: 8,
    width: '72.1mm',
  },
  billTo: {
    marginBottom: -2,
  },
});

const BillTo = () => (
  <View style={styles.headerContainer}>
    <SeparatorTop />
    <Text style={styles.billTo}>n3k3 S.A.</Text>
    <Text style={styles.billTo}>info@n3k3.com</Text>
    <Text style={styles.billTo}>Huehuetenango, Huehuetenango</Text>
    <Text style={styles.billTo}>Guatemala</Text>
    <Text style={styles.billTo}>n3k3.com</Text>
    <SeparatorBottom />
  </View>
);

export default BillTo;
