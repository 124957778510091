import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  tableRowContainer: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    marginTop: 9,
    marginBottom: -6,
    width: '72.1mm',
    paddingLeft: '10%',
    paddingRight: '10%',
    textAlign: 'justify',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: -1,
    textAlign: 'left',
  },
  product: {
    flex: '0 0 90%',
  },
  qty: {
    textAlign: 'left',
  },
});

function calcPercent(val, percentage) {
  let desc = (val * (percentage / 100)).toFixed(0);
  let value = val - desc;
  if (percentage > 0 && percentage < 100) return value.toFixed(2);
  return (0).toFixed(2);
}

const InvoiceTableRow = ({ items, descuento }) => {
  const rows = items.map((item, index) => (
    <View style={styles.tableRowContainer} key={item.id.toString()}>
      <View style={styles.row}>
        <Text style={styles.product}>
          {item.nombre} {item.especificacion}
        </Text>
        <Text style={styles.qty}>{item.cantidad}</Text>
      </View>
      <View style={styles.row}>
        <Text>Valor</Text>
        <Text>
          Q{item.valor} - {descuento}% = Q{calcPercent(item.valor, descuento)}
        </Text>
      </View>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default InvoiceTableRow;
