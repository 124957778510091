import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

import Separator from './../Separator';

const ReceiptN3k3 = () => {
  const styles = StyleSheet.create({
    container: {
      width: '72.1mm',
      paddingLeft: '5%',
      paddingRight: '5%',
    },
  });
  return (
    <View style={styles.container}>
      <Separator />
      <Separator />
      <Text>Powered by: n3k3 S.A.</Text>
      <Text>info@n3k3.com</Text>
      <Text>Huehuetenango, Huehuetenango,</Text>
      <Text>Guatemala</Text>
      <Text>n3k3.com</Text>
    </View>
  );
};

export default ReceiptN3k3;
