import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import SeparatorTop from '../SeparatorTop';
import SeparatorBottom from '../SeparatorBottom';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textAlign: 'left',

    width: '72.1mm',

    paddingLeft: '10%',
    paddingRight: '10%',
  },
  container: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',

    width: '72.1mm',
    marginTop: 12,
    marginBottom: 8,
    textAlign: 'justify',
  },
});

const InvoiceTableFooter = ({ items }) => {
  function calcPercent(val, discount) {
    let desc = (val * (discount / 100)).toFixed(0);
    if (discount > 0) return parseFloat(desc);
    // else descuento es 0 formateado con 2 decimales
    return 0;
  }

  const descuento = items
    .map((item) => calcPercent(item.cantidad * item.valor, item.descuento))
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const subtotal = items
    .map((item) => item.cantidad * item.valor)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  const total = items
    .map(
      (item) =>
        item.cantidad * item.valor -
        calcPercent(item.cantidad * item.valor, item.descuento)
    )
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

  return (
    <View style={styles.container}>
      <SeparatorTop />
      <View style={styles.wrapper}>
        <View style={styles.row}>
          <Text>SUBTOTAL</Text>
          <Text>Q{subtotal.toFixed(2)}</Text>
        </View>
        <View style={styles.row}>
          <Text>Desc</Text>
          <Text>- Q{descuento.toFixed(2)}</Text>
        </View>
        {items.adi > 0 && (
          <View style={styles.row}>
            <Text>Precio especial ADI</Text>
            <Text>- Q{items.adi}</Text>
          </View>
        )}
        <View style={styles.row}>
          <Text>TOTAL</Text>
          <Text>Q{total.toFixed(2)}</Text>
        </View>
      </View>
      <SeparatorBottom />
    </View>
  );
};

export default InvoiceTableFooter;
