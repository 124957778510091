import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  border: {
    borderTop: '1.40',
    borderStyle: 'dotted',
    marginVertical: 10,
  },
});

const Separator = () => <Text style={styles.border} />;

export default Separator;
