import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

// components
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import ManagmentSidebar from 'components/Sidebar/ManagmentSidebar.js';
import HeaderStats from 'components/Headers/HeaderStats.js';
import FooterAdmin from 'components/Footers/FooterAdmin.js';

import Dashboard from 'views/admin/Dashboard.js';
import Settings from 'views/admin/Settings.js';
import Productos from 'views/admin/Productos.js';
import Grupos from 'views/admin/Grupos.js';
import Elementos from 'views/admin/Elementos.js';
import Especificaciones from 'views/admin/Especificaciones.js';
import Tags from 'views/admin/Tags.js';
import ProductoTags from 'views/admin/ProductoTags.js';
import Registros from 'views/admin/Registros.js';
import Usuarios from 'views/admin/Usuarios.js';
import Facturas from 'views/admin/Facturas';
import Encargos from 'views/admin/Encargos';

const TITLE = 'Malem Group | Administración';

export default function Managment() {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ManagmentSidebar />
      <div className='block relative md:ml-64 bg-gray-200 h-screen-90'>
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className='px-4 md:px-10 mx-auto w-full'>
          <Switch>
            <Route path='/managment/dashboard' exact component={Dashboard} />
            <Route path='/managment/opciones' exact component={Settings} />
            <Route path='/managment/productos' exact component={Productos} />
            <Route path='/managment/grupos' exact component={Grupos} />
            <Route path='/managment/elementos' exact component={Elementos} />
            <Route
              path='/managment/especificaciones'
              exact
              component={Especificaciones}
            />
            <Route path='/managment/tags' exact component={Tags} />
            <Route
              path='/managment/producto/tags'
              exact
              component={ProductoTags}
            />
            <Route
              path='/managment/facturas/consultar'
              exact
              component={Facturas}
            />
            <Route
              path='/managment/encargos/auditar'
              exact
              component={Encargos}
            />
            <Route
              path='/managment/usuarios/gestionar'
              exact
              component={Usuarios}
            />
            <Route
              path='/managment/usuarios/registros'
              exact
              component={Registros}
            />
            <Redirect from='/managment' to='/managment/dashboard' />
          </Switch>
        </div>
        <div className='block relative md:ml-64 bg-gray-200'>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
