import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from 'UserContext';
import Modal from 'components/Modals/Modal.js';
// Alertify
import alertify from 'alertifyjs';

const OrderDropdown = ({ orders, ...props }) => {
  const [formData, setFormData] = useState({
    id: orders.id,
    pagado: orders.pagado,
    formaRaw: orders.formaRaw,
    descripcion: orders.descripcion,
    entregaRaw: orders.entregaRaw,
    activo: orders.activo,
  });

  const { id, pagado, formaRaw, descripcion, entregaRaw, activo } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    let _isMounted = true;

    if (_isMounted) {
      setFormData({
        id: orders.id,
        pagado: orders.pagado,
        formaRaw: orders.formaRaw,
        descripcion: orders.descripcion,
        entregaRaw: orders.entregaRaw,
        activo: orders.activo,
      });
    }

    return () => {
      _isMounted = false;
    };
  }, [orders]);

  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;
  const [alert, setAlert] = useState('');

  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
      useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
      if (event.key === 'Escape') {
        closeDropdownPopover();
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !btnDropdownRef.current.contains(event.target)) {
        closeDropdownPopover();
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
      };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
  }

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    // createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
    //   placement: "auto"
    // });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  function submitDataEdit() {
    fetch('/api/orders/edit', {
      method: 'PUT',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((result) => {
        props.recibir();
        setAlert(result.message);
      })
      .catch((error) => {});
  }

  function submitDataRemove() {
    var datos = [];

    datos.push({
      idData,
    });

    fetch(URIDelete + URIExtension, {
      method: 'PUT',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos),
    })
      .then((response) => response.json())
      .then((result) => {
        setAlert(result.message);
        props.recibir();
      })
      .catch((error) => {
        setAlert(error);
      });
  }

  const handleView = (e) => {
    e.preventDefault();
    props.viewOrder(formData.id);
  };
  const handleSubmitEdit = (e) => {
    e.preventDefault();
    submitDataEdit();
  };
  const handleSubmitRemove = (e) => {
    e.preventDefault();
    submitDataRemove();
  };

  const modalEdit = useRef(null);
  const modalRemove = useRef(null);

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert, function () {
      if (modalEdit.current) {
        modalEdit.current.close();
      }
      if (modalRemove.current) {
        modalRemove.current.close();
      }
      alertify.success('Muy bien!');
    });
    setAlert('');
  }

  return (
    <>
      <a
        className='text-gray-600 py-1 px-3'
        href=' '
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          dropdownPopoverShow
            ? closeDropdownPopover()
            : setIsComponentVisible(true);
        }}>
        <i className='fas fa-ellipsis-v'></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded-md shadow-lg w-36 ' +
          (dropdownPopoverShow ? 'message-globe' : 'hide-message')
        }>
        {isComponentVisible && (
          <div ref={ref}>
            <a
              href=' '
              className={
                'view-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                handleView(e);
              }}>
              <i className='far fa-eye mr-2'></i>
              Ver
            </a>
            <a
              href=' '
              className={
                'edit-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                e.preventDefault();
                modalEdit.current.open();
              }}>
              <i className='far fa-edit mr-2'></i>
              Editar
            </a>
            {
              {
                [(1, 2)]: (
                  <a
                    href=' '
                    className={
                      'delete-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      modalRemove.current.open();
                    }}>
                    <i className='fas fa-eraser mr-2'></i>
                    Eliminar
                  </a>
                ),
              }[userProfile.puesto]
            }
          </div>
        )}
      </div>
      <Modal ref={modalEdit}>
        <form
          onSubmit={handleSubmitEdit}
          className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 user-select'>
          <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-align-center modal-title'>
            # Editar Encargo
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='pagado'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square'></i> Pagado
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id='pagado'
              name='pagado'
              placeholder='Cantidad pagada del encargo'
              onChange={(e) => onChange(e)}
              value={pagado}
              required
            />
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='formaRaw'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square'></i> Forma de pago
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id='formaRaw'
              name='formaRaw'
              onChange={(e) => onChange(e)}
              value={formaRaw}>
              <option value=''>Seleccionar</option>
              <option value='1'>Efectivo</option>
              <option value='2'>Cheque</option>
              <option value='3'>Tarjeta</option>
            </select>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='descripcion'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square'></i> Descripción
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id='descripcion'
              name='descripcion'
              placeholder='Descripción del Encargo'
              onChange={(e) => onChange(e)}
              value={descripcion}
              required
            />
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='entregaRaw'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square'></i> Proceso de Entrega
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id='entregaRaw'
              name='entregaRaw'
              onChange={(e) => onChange(e)}
              value={entregaRaw}>
              <option value=''>Seleccionar</option>
              <option value='1'>Sin entregar</option>
              <option value='2'>Pendiente</option>
              <option value='3'>Transferencia completada</option>
            </select>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='activo'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square'></i> Estado del Encargo
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id='activo'
              name='activo'
              onChange={(e) => onChange(e)}
              value={activo}>
              <option value=''>Seleccionar</option>
              <option value='1'>Activo</option>
              <option value='2'>No activo</option>
            </select>
          </div>
          <div className='float-right'>
            <button
              type='button'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 mr-4'
              onClick={() => modalEdit.current.close()}>
              Cerrar ventana <i className='fas fa-times'></i>
            </button>
            <button
              type='submit'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 ml-4'>
              Guardar cambios <i className='fas fa-check-circle'></i>
            </button>
          </div>
        </form>
      </Modal>
      <Modal ref={modalRemove}>
        <form
          onSubmit={(e) => {
            handleSubmitRemove(e);
          }}>
          <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-left'>
            Eliminar
          </div>
          <div className='my-4'>
            <label
              htmlFor={'nombre'}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 inline-block'>
              En verdad desea eliminar este ?
            </label>
            <div className='my-4'>
              <div className='flex'>
                <button
                  type='button'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-full inline-block'
                  onClick={() => modalRemove.current.close()}>
                  No, cerrar ventana
                </button>
                <button
                  type='submit'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-full inline-block'>
                  Si, deseo eliminarlo
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default OrderDropdown;
