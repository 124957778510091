import React from 'react';
import Table from 'components/Tables/Table';
import Styles from 'components/Tables/Styles/Styles';

const CardReports = ({ reportData = undefined }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Info',
        Footer: 'Info',
        columns: [
          {
            Header: 'ID',
            Footer: 'ID',
            accessor: 'factura_id',
          },
          {
            Header: 'Fecha',
            Footer: 'Fecha',
            accessor: 'factura_fecha',
          },
        ],
      },
      {
        Header: 'Especificación',
        Footer: 'Especificación',
        columns: [
          {
            Header: 'Comercio',
            Footer: 'Comercio',
            accessor: 'comercio_nombre',
          },
          {
            Header: 'Extensión',
            Footer: 'Extensión',
            accessor: 'extension_nombre',
          },
          {
            Header: 'Subtotal',
            Footer: 'Subtotal',
            accessor: 'factura_subtotal',
          },
          {
            Header: 'Total',
            accessor: 'factura_total',
            Footer: () => {
              const total = React.useMemo(() => reportData.reporte_total, []);

              return <>Total: {total}</>;
            },
          },
        ],
      },
    ],
    []
  );

  const data = React.useMemo(() => reportData.rows, []);

  return (
    <Styles>
      <Table columns={columns} data={data} />
    </Styles>
  );
};

export default CardReports;
