import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/styles/estilo.css';
import 'assets/styles/admin.css';
import 'assets/styles/tailwind.css';
import 'assets/styles/modal.css';
import 'alertifyjs/build/css/alertify.min.css';
import 'alertifyjs/build/css/themes/default.min.css';

import App from './App.js';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
