/*eslint-disable*/
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from 'UserContext';

import NotificationDropdown from 'components/Dropdowns/NotificationDropdown.js';
import ClientDropdown from '../../components/Dropdowns/ClientDropdown.js';

export default function ClientSidebar() {
  const [collapseShow, setCollapseShow] = React.useState('hidden');

  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  return (
    <>
      <nav className='shadow bg-white flex flex-wrap items-center justify-between relative z-10 py-4 px-6'>
        <div className='px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
          {/* Toggler */}
          <button
            className='cursor-pointer text-black opacity-50 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
            type='button'
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}>
            <i className='fas fa-bars'></i>
          </button>
          {/* Brand */}
          <Link
            className='md:pl-20 pl-5 text-left text-gray-700 m-0 inline-block whitespace-no-wrap text-sm uppercase font-bold'
            to='/admin/dashboard'>
            {userProfile.comercio}
          </Link>
          {/* User */}

          <ul className='items-center flex flex-wrap list-none'>
            {/* Search Bar */}
            <form className='md:block hidden mx-8'>
              <div>
                <input
                  type='text'
                  placeholder='Buscar...'
                  className='px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal'
                />
              </div>
            </form>
            <li className='inline-block relative'>
              <NotificationDropdown />
            </li>
            <li className='inline-block relative'>
              <ClientDropdown />
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              'shadow absolute top-0 left-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded ' +
              collapseShow
            }>
            {/* Collapse header */}
            <div className='block pb-4 mb-4 border-b border-solid border-gray-300'>
              <div className='flex flex-wrap'>
                <div className='w-6/12'>
                  <Link
                    className='text-gray-700 mr-0 inline-block whitespace-no-wrap text-sm uppercase font-bold p-4 px-0'
                    to='#'>
                    Cliente
                  </Link>
                </div>
                <div className='w-6/12 flex justify-end'>
                  <button
                    type='button'
                    className='cursor-pointer text-black opacity-50 px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                    onClick={() => setCollapseShow('hidden')}>
                    <i className='fas fa-times'></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <div style={{ width: '226.667px' }}>
              <form className='mt-6 mb-4 md:hidden'>
                <div className='mb-3 pt-0'>
                  <input
                    type='text'
                    placeholder='Buscar...'
                    className='px-3 py-2 h-12 border border-solid  border-gray-600 placeholder-gray-400 text-gray-700 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal'
                  />
                </div>
              </form>
            </div>

            {/* Divider */}
            <hr className='my-4 md:hidden' />
            {/* Heading */}
            <h6 className='text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Opciones
            </h6>
            {/* Navigation */}

            <ul className='flex flex-col list-none'>
              {/* Dashboard */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/client/dashboard') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/client/dashboard'>
                  <i
                    className={
                      'fas fa-tv mr-2 text-sm ' +
                      (window.location.href.indexOf('/client/dashboard') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Panel
                </Link>
              </li>
              {/* Termina Dashboard */}
              {/* Inventario */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/client/inventario') !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/client/inventario'>
                  <i
                    className={
                      'fas fa-store mr-2 text-sm ' +
                      (window.location.href.indexOf('/client/inventario') !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Inventario
                </Link>
              </li>
              {/* Termina Inventario */}
            </ul>

            {/* Divider */}
            <hr className='my-4' />
            {/* Heading */}

            <h6 className='text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
              Pedidos
            </h6>

            <ul className='flex flex-col list-none'>
              {/* Administrar facturas */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf('/client/pedidos/nuevo') !==
                    -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/client/pedidos/nuevo'>
                  <i
                    className={
                      'fas fa-check-double text-gray-400 mr-2 text-sm ' +
                      (window.location.href.indexOf('/client/pedidos/nuevo') !==
                      -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Nuevo
                </Link>
              </li>

              {/* Ver ordenes pendientes */}
              <li className='items-center'>
                <Link
                  className={
                    'text-xs uppercase py-3 font-bold block ' +
                    (window.location.href.indexOf(
                      '/client/pedidos/pendientes'
                    ) !== -1
                      ? 'text-blue-500 hover:text-blue-600'
                      : 'text-gray-800 hover:text-gray-600')
                  }
                  to='/client/pedidos/pendientes'>
                  <i
                    className={
                      'fas fa-stream text-gray-400 mr-2 text-sm ' +
                      (window.location.href.indexOf(
                        '/client/pedidos/pendientes'
                      ) !== -1
                        ? 'opacity-75'
                        : 'text-gray-400')
                    }></i>{' '}
                  Pendientes
                </Link>
              </li>
            </ul>
            {userProfile.comercio !== `B'alam` ? (
              <>
                <hr className='my-4' />
                <h6 className='text-gray-600 text-xs uppercase font-bold block pt-1 pb-4 no-underline'>
                  Encargos
                </h6>
                <ul className='flex flex-col list-none'>
                  <li className='items-center'>
                    <Link
                      className={
                        'text-xs uppercase py-3 font-bold block ' +
                        (window.location.href.indexOf('/client/afiliados') !==
                        -1
                          ? 'text-blue-500 hover:text-blue-600'
                          : 'text-gray-800 hover:text-gray-600')
                      }
                      to='/client/afiliados'>
                      <i
                        className={
                          'fas fa-user-tie text-gray-400 mr-2 text-sm ' +
                          (window.location.href.indexOf('/client/afiliados') !==
                          -1
                            ? 'opacity-75'
                            : 'text-gray-400')
                        }></i>{' '}
                      Afiliados
                    </Link>
                  </li>
                  <li className='items-center'>
                    <Link
                      className={
                        'text-xs uppercase py-3 font-bold block ' +
                        (window.location.href.indexOf(
                          '/client/encargos/nuevo'
                        ) !== -1
                          ? 'text-blue-500 hover:text-blue-600'
                          : 'text-gray-800 hover:text-gray-600')
                      }
                      to='/client/encargos/nuevo'>
                      <i
                        className={
                          'fas fa-box text-gray-400 mr-2 text-sm ' +
                          (window.location.href.indexOf(
                            '/client/encargos/nuevo'
                          ) !== -1
                            ? 'opacity-75'
                            : 'text-gray-400')
                        }></i>{' '}
                      Nuevo
                    </Link>
                  </li>
                  {/* <li className='items-center'>
                    <Link
                      className={
                        'text-xs uppercase py-3 font-bold block ' +
                        (window.location.href.indexOf(
                          '/client/encargos/calendario'
                        ) !== -1
                          ? 'text-blue-500 hover:text-blue-600'
                          : 'text-gray-800 hover:text-gray-600')
                      }
                      to='/client/encargos/calendario'>
                      <i
                        className={
                          'fas fa-calendar-alt text-gray-400 mr-2 text-sm ' +
                          (window.location.href.indexOf(
                            '/client/encargos/calendario'
                          ) !== -1
                            ? 'opacity-75'
                            : 'text-gray-400')
                        }></i>{' '}
                      En calendario
                    </Link>
                  </li> */}
                </ul>
              </>
            ) : null}
          </div>
        </div>
      </nav>
    </>
  );
}
