import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
} from 'react';

import Calendar from 'react-calendar';
import moment from 'moment';
import Tilty from 'react-tilty';
import alertify from 'alertifyjs';

import Modal from '../../components/Modals/WhiteModal.js';
import CardReports from 'components/Cards/CardReports.jsx';

import Rocket from 'assets/img/loaders/rocket.png';

const Reportes = () => {
  const [openStartCalendar, setOpenStartCalendar] = useState(false);
  const [openEndCalendar, setOpenEndCalendar] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(1, 'month'));
  const [endDate, setEndDate] = useState(moment());
  const [isLoading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState(undefined);

  const [shops, setShops] = useState([]);
  const [savedShop, setSavedShop] = useState(undefined);

  const getShops = useCallback(async () => {
    await fetch('/api/fetch/shops')
      .then((res) => res.json())
      .then((res) => {
        if (res.ok) {
          setShops(res.data);
        } else if (!res.ok && res.message) {
          console.error(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(shops).length == 0) {
      getShops();
    }
  }, []);

  const firstDate = (e) => {
    setStartDate(moment(e));
    setOpenStartCalendar(false);
  };

  const secondDate = (e) => {
    setEndDate(moment(e));
    setOpenEndCalendar(false);
  };

  const openReportsModal = () => {
    if (savedShop !== undefined) {
      ReportsModal.current.open();
      consumeAPI();
    } else {
      alert('¡Selecciona una tienda!', 'warning');
    }
  };

  const ReportsModal = useRef(null);

  function prepareTable() {
    setReportData(undefined);
    setIsLoading(true);
  }

  async function consumeAPI() {
    prepareTable();

    let start = startDate;
    let end = endDate;
    let shop = savedShop;

    let body = {
      start,
      end,
      shop,
    };

    await fetch('/api/reports/all', {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
        'malem-forwarder-secure': 'true',
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.ok) {
          setIsLoading(false);
          setReportData(res);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function alert(message, type) {
    alertify.notify(`${message}`, `${type}`, 5);
  }

  const handleShopSelect = (e) => {
    setSavedShop(e.target.value);
  };

  return (
    <Fragment>
      <Tilty
        style={{
          position: 'relative',
          zIndex: 10,
          margin: '2rem',
          borderRadius: '20px',
        }}
        max={10}
        perspective={1000}
        scale={1}
        speed={400}
        transition={true}
        reset={true}
        easing={'cubic-bezier(.03,.98,.52,.99)'}
        glare
        maxGlare={1}>
        <div
          id='reports-container'
          className={
            'flex flow-row-wrap justify-center content-start shadow-lg' +
            (openStartCalendar || openEndCalendar ? ' transition-all' : '')
          }>
          <div className='flex flex-col m-4'>
            <div className={openStartCalendar ? 'smooth-up' : ''}>
              <label htmlFor='startInput' className='mb-3 cursor-pointer'>
                <span className='span-blue text-shadow'>
                  <i className='fas fa-calendar-check' /> Fecha de inicio:
                </span>
              </label>
            </div>
            <input
              type='text'
              name='startInput'
              id='startInput'
              className='input-glass shadow-md select-all'
              value={startDate.format('DD-MM-YYYY')}
              onFocus={() => {
                setOpenEndCalendar(false);
                setOpenStartCalendar(true);
              }}
              readOnly
            />

            <div
              id='calendar-start'
              className={'block' + (openStartCalendar ? ' smooth-down' : '')}>
              {openStartCalendar === true && (
                <Calendar
                  className='z-50 absolute shadow-lg rounded-lg'
                  onClickDay={firstDate}
                  value={startDate.toDate()}
                  locale='es-GT'
                />
              )}
            </div>
          </div>

          <div className='flex flex-col m-4'>
            <div className={openEndCalendar ? 'smooth-up' : ''}>
              <label htmlFor='endInput' className='mb-3 cursor-pointer'>
                <span className='span-blue text-shadow'>
                  <i className='far fa-calendar-check' /> Fecha final:
                </span>
              </label>
            </div>
            <input
              id='endInput'
              className='input-glass shadow-md select-all'
              type='text'
              placeholder='Fecha final'
              value={endDate.format('DD-MM-YYYY')}
              onFocus={() => {
                setOpenEndCalendar(true);
                setOpenStartCalendar(false);
              }}
              readOnly
            />
            <div
              id='calendar-end'
              className={'block' + (openEndCalendar ? ' smooth-down' : '')}>
              {openEndCalendar === true && (
                <Calendar
                  className='z-50 absolute shadow-lg rounded-lg'
                  onClickDay={secondDate}
                  value={endDate.toDate()}
                  locale='es-GT'
                />
              )}
            </div>
          </div>

          <div
            className='flex flex-col justify-end m-4'
            style={{ height: '68px' }}>
            <select
              className='mb-2 border rounded-md text-center text-gray-700 font-mono'
              onChange={handleShopSelect}
              defaultValue='default'
              value={savedShop}>
              <option value='default' disabled>
                Seleccionar
              </option>
              {Object.keys(shops).length > 0 &&
                shops.map((row, index) => (
                  <option key={index} value={row.name}>
                    {row.name}
                  </option>
                ))}
            </select>
            <button
              type='button'
              className='button-blue shadow-md button-transform'
              onClick={() => openReportsModal()}>
              <span>Generar reporte</span>
            </button>
          </div>
        </div>
      </Tilty>

      <Modal ref={ReportsModal}>
        {isLoading == true || reportData == undefined ? (
          <div id='report-generator'>
            <div style={{ marginTop: '8px' }}>
              <span>
                Generando Reporte
                {/* <i
                  className='fas fa-check'
                  style={{ color: 'hsl(134, 65%, 62%)' }}
                /> */}
              </span>
            </div>
            <div>
              <img src={Rocket} alt='loader' />
            </div>
          </div>
        ) : (
          <CardReports reportData={reportData} />
        )}
      </Modal>
    </Fragment>
  );
};

export default Reportes;
