import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import CryptoJS from 'crypto-js';
import { useCookies } from 'react-cookie';
import { UserContext } from 'UserContext';
import Loader from 'react-loader-spinner';

import packageJson from '../package.json';
import { getBuildDate } from './utils/utils';
import withClearCache from './ClearCache';

import Admin from './layouts/Admin.js';
import Managment from 'layouts/Managment.js';
import Client from './layouts/Client.js';
import Autenticacion from './layouts/Autenticacion.js';
import Catalogue from './views/catalogue/Catalogue.jsx';
import TerminosYCondiciones from './views/catalogue/TerminosYCondiciones';

import Index from './views/Index.js';

const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  // Cookies
  const [cookies, setCookie] = useCookies(['MALEM__PROFILE']);
  // Loader
  const [isBusy, setBusy] = useState(true);
  // User States
  const [IS_LOGGED_IN, SET_IS_LOGGED_IN] = useState(false);
  const [userType, setUserType] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  // Messages
  const [mensajeData, setMensajeData] = useState('');

  useEffect(() => {
    if (Object.keys(cookies).length === 0) {
      verificateUser();
    } else {
      try {
        const passphrase =
          '7a40c95a2c9ed49f01faeed18827d05739bde9b21c356555874b2dd2487cfafa';
        const bytes = CryptoJS.AES.decrypt(cookies.MALEM__PROFILE, passphrase);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        const cookieArray = JSON.parse(originalText);
        const cookieObject = cookieArray[0];
        setUserProfile(cookieObject);
        setUserType(cookieObject.puesto);
        userIsloggedIn();
      } catch (error) {
        verificateUser();
      }
    }

    function verificateUser() {
      const verifyUser = () => {
        return fetch('/api/verify/isLoggedIn')
          .then((res) => res.json())
          .then((res) => {
            SET_IS_LOGGED_IN(res.IS_LOGGED_IN);
            if (res.IS_LOGGED_IN === true) {
              getUserData();
            } else {
              setBusy(false);
              setMensajeData(res.message);
            }
          });
      };
      verifyUser();

      const getUserData = async () => {
        await fetch('/api/verify/user')
          .then((res) => res.json())
          .then((res) => {
            if (res.IS_LOGGED_IN == false || res.message) {
              location.reload();
            }
            if (res.data[0]) {
              setUserProfile(res.data[0]);
              setUserType(res.userType);
              setBusy(false);
              const passphrase =
                '7a40c95a2c9ed49f01faeed18827d05739bde9b21c356555874b2dd2487cfafa';
              const AES = CryptoJS.AES.encrypt(
                JSON.stringify(res.data),
                passphrase
              ).toString();
              handleCookie(AES);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      };

      const handleCookie = (cookieValue) => {
        setCookie('MALEM__PROFILE', cookieValue, {
          path: '/',
          sameSite: true,
        });
      };
    }
  }, [cookies, setCookie]);

  const userIsloggedIn = () => {
    return fetch('/api/verify/isLoggedIn')
      .then((response) => response.json())
      .then((json) => {
        SET_IS_LOGGED_IN(json.IS_LOGGED_IN);
        setBusy(false);
      });
  };

  return (
    <UserContext.Provider value={{ userProfile }}>
      {mensajeData ? (alert(mensajeData), setMensajeData('')) : ''}
      {isBusy ? (
        // Loader before render
        <Loader
          type='Puff'
          color='#00BFFF'
          height={150}
          width={150}
          timeout={9000}
        />
      ) : IS_LOGGED_IN ? (
        userType === 1 ? (
          // if user is owner
          <BrowserRouter>
            <Switch>
              <Route path='/admin' component={Admin} />
              <Redirect from='*' to='/admin' />
            </Switch>
          </BrowserRouter>
        ) : userType === 2 ? (
          // if user is admin
          <BrowserRouter>
            <Switch>
              <Route path='/managment' component={Managment} />
              <Redirect from='*' to='/managment' />
            </Switch>
          </BrowserRouter>
        ) : userType === 3 ? (
          // if user is provider
          <BrowserRouter>
            <Switch>
              <Route path='/provider' component={Admin} />
              <Redirect from='*' to='/provider' />
            </Switch>
          </BrowserRouter>
        ) : userType === 4 ? (
          // if user is employee
          <BrowserRouter>
            <Switch>
              <Route path='/client' component={Client} />
              <Redirect from='*' to='/client' />
            </Switch>
          </BrowserRouter>
        ) : (
          // if user is unknown
          <BrowserRouter>
            <Switch>
              <Route path='/autenticacion' component={Autenticacion} />
              <Route path='/' exact component={Index} />
              <Redirect from='*' to='/' />
            </Switch>
          </BrowserRouter>
        )
      ) : (
        // if user is not logged in
        <BrowserRouter>
          <Switch>
            <Route
              path='/terminos-y-condiciones'
              component={TerminosYCondiciones}
            />
            <Route path='/catalogue/vianney' component={Catalogue} />
            <Route path='/catalogue/balam' component={Catalogue} />
            <Route path='/autenticacion' component={Autenticacion} />
            <Route path='/' exact component={Index} />
            <Redirect from='*' to='/' />
          </Switch>
        </BrowserRouter>
      )}
    </UserContext.Provider>
  );
}

export default App;
