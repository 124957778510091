import React, { useState, useEffect, Fragment, useContext } from 'react';
import { UserContext } from 'UserContext';
import Chart from 'chart.js';

export default function GraficoVentas() {
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  const [data, setData] = useState([]);
  const [meses, setMeses] = useState({
    enero: 0,
    febrero: 0,
    marzo: 0,
    abril: 0,
    mayo: 0,
    junio: 0,
    julio: 0,
    agosto: 0,
    septiembre: 0,
    noviembre: 0,
    diciembre: 0,
  });

  const {
    enero,
    febrero,
    marzo,
    abril,
    mayo,
    junio,
    julio,
    agosto,
    septiembre,
    noviembre,
    diciembre,
  } = meses;

  useEffect(() => {
    fetch('/api/facturas/all', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          var sortedData = json.data.sort(function (a, b) {
            return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
          });
          sortedData.reverse();
          setData(sortedData);
          newChart(sortedData);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  function newChart(sortedData) {
    var enero = 0;
    var febrero = 0;
    var marzo = 0;
    var abril = 0;
    var mayo = 0;
    var junio = 0;
    var julio = 0;
    var agosto = 0;
    var septiembre = 0;
    var octubre = 0;
    var noviembre = 0;
    var diciembre = 0;

    for (let keys in sortedData) {
      switch (sortedData[keys].fecha.indexOf('2021-01') === -1) {
        case sortedData[keys].fecha.indexOf('2021-02') !== -1:
          febrero += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-03') !== -1:
          marzo += parseFloat(sortedData[keys].total);

          break;
        case sortedData[keys].fecha.indexOf('2021-04') !== -1:
          abril += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-05') !== -1:
          mayo += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-06') !== -1:
          junio += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-07') !== -1:
          julio += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-08') !== -1:
          agosto += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-09') !== -1:
          septiembre += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-10') !== -1:
          octubre += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-11') !== -1:
          noviembre += parseFloat(sortedData[keys].total);
          break;
        case sortedData[keys].fecha.indexOf('2021-12') !== -1:
          diciembre += parseFloat(sortedData[keys].total);
          break;

        default:
          enero += parseFloat(sortedData[keys].total);
          break;
      }

      // if (sortedData[keys].fecha.indexOf('2021-07') !== -1) {
      //   julio += parseFloat(sortedData[keys].total);
      // }
    }
    setMeses({
      ...meses,
      enero: enero,
      febrero: febrero,
      marzo: marzo,
      abril: abril,
      mayo: mayo,
      junio: junio,
      julio: julio,
      agosto: agosto,
      septiembre: septiembre,
      octubre: octubre,
      noviembre: noviembre,
      diciembre: diciembre,
    });
    paintCanvas(
      enero,
      febrero,
      marzo,
      abril,
      mayo,
      junio,
      julio,
      agosto,
      septiembre,
      octubre,
      noviembre,
      diciembre
    );
  }

  function getConfig(
    enero,
    febrero,
    marzo,
    abril,
    mayo,
    junio,
    julio,
    agosto,
    septiembre,
    octubre,
    noviembre,
    diciembre
  ) {
    return {
      type: 'line',
      data: {
        labels: [
          'Enero',
          'Febrero',
          'Marzo',
          'Abril',
          'Mayo',
          'Junio',
          'Julio',
          'Agosto',
          'Septiembre',
          'Octubre',
          'Noviembre',
          'Diciembre',
        ],
        datasets: [
          // {
          //   label: new Date().getFullYear(),
          //   backgroundColor: '#4c51bf',
          //   borderColor: '#4c51bf',
          //   data: [65, 78, 66, 44, 56, 67, 75, 80, 98, 64, 47, 59],
          //   fill: false,
          // },
          {
            label: new Date().getFullYear() - 1,
            fill: false,
            backgroundColor: '#fff',
            borderColor: '#fff',
            data: [
              enero,
              febrero,
              marzo,
              abril,
              mayo,
              junio,
              julio,
              agosto,
              septiembre,
              octubre,
              noviembre,
              diciembre,
            ],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: 'Sales Charts',
          fontColor: 'white',
        },
        legend: {
          labels: {
            fontColor: 'white',
          },
          align: 'end',
          position: 'bottom',
        },
        tooltips: {
          mode: 'index',
          intersect: false,
        },
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: 'rgba(255,255,255,.7)',
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Month',
                fontColor: 'white',
              },
              gridLines: {
                display: false,
                borderDash: [2],
                borderDashOffset: [2],
                color: 'rgba(33, 37, 41, 0.3)',
                zeroLineColor: 'rgba(0, 0, 0, 0)',
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                fontColor: 'rgba(255,255,255,.7)',
              },
              display: true,
              scaleLabel: {
                display: false,
                labelString: 'Value',
                fontColor: 'white',
              },
              gridLines: {
                borderDash: [3],
                borderDashOffset: [3],
                drawBorder: false,
                color: 'rgba(255, 255, 255, 0.15)',
                zeroLineColor: 'rgba(33, 37, 41, 0)',
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
  }

  const paintCanvas = (
    enero,
    febrero,
    marzo,
    abril,
    mayo,
    junio,
    julio,
    agosto,
    septiembre,
    octubre,
    noviembre,
    diciembre,
    lunes,
    martes,
    miércoles,
    jueves,
    viernes
  ) => {
    if ('1,2'.indexOf(userProfile.puesto) !== -1) {
      var ctx = document.getElementById('line-chart').getContext('2d');
      window.myLine = new Chart(
        ctx,
        getConfig(
          enero,
          febrero,
          marzo,
          abril,
          mayo,
          junio,
          julio,
          agosto,
          septiembre,
          octubre,
          noviembre,
          diciembre
        )
      );
    } else {
      var ctx = document.getElementById('daily-chart').getContext('2d');
      window.myLine = new Chart(
        ctx,
        getConfig(lunes, martes, miércoles, jueves, viernes)
      );
    }
  };

  return (
    <Fragment>
      <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-gray-800'>
        <div className='rounded-t mb-0 px-4 py-3 bg-transparent'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full max-w-full flex-grow flex-1'>
              <h6 className='uppercase text-gray-200 mb-1 text-xs font-semibold'>
                Visión general
              </h6>
              <h2 className='text-white text-xl font-semibold'>
                Valor de las ventas
              </h2>
            </div>
          </div>
        </div>
        <div className='p-4 flex-auto'>
          <div className='relative h-350-px'>
            {'1,2'.indexOf(userProfile.puesto) !== -1 ? (
              <canvas id='line-chart' />
            ) : (
              <canvas id='daily-chart' />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
