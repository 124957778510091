import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import SeparatorTop from '../SeparatorTop';
import SeparatorBottom from '../SeparatorBottom';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textAlign: 'left',

    width: '72.1mm',

    paddingLeft: '10%',
    paddingRight: '10%',
  },
  container: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',

    width: '72.1mm',
    marginTop: 12,
    marginBottom: 8,
    textAlign: 'justify',
  },
});

function calcPercent(val, percentage) {
  let desc = (val * (percentage / 100)).toFixed(0);
  if (percentage > 0) return parseFloat(desc).toFixed(2);
  return (0).toFixed(2);
}

const InvoiceTableFooter = ({ subtotal, descuento, total, adi }) => {
  return (
    <View style={styles.container}>
      <SeparatorTop />
      <View style={styles.wrapper}>
        <View style={styles.row}>
          <Text>SUBTOTAL</Text>
          <Text>Q{subtotal}</Text>
        </View>
        <View style={styles.row}>
          <Text>Desc</Text>
          <Text>- Q{calcPercent(subtotal, descuento)}</Text>
        </View>
        {adi > 0 && (
          <View style={styles.row}>
            <Text>Precio especial ADI</Text>
            <Text>- Q{adi}</Text>
          </View>
        )}
        <View style={styles.row}>
          <Text>TOTAL</Text>
          <Text>Q{total}</Text>
        </View>
      </View>
      <SeparatorBottom />
    </View>
  );
};

export default InvoiceTableFooter;
