import React, { useState, useEffect, useRef, Fragment } from 'react';

import Invoice from 'components/Reports/Invoice';
import CardAffiliates from 'components/Cards/CardAffiliates';

import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { isMobile } from 'react-device-detect';
import Modal from 'components/Modals/Modal';
import Loader from 'react-loader-spinner';
import alertify from 'alertifyjs';

export default function Afiliados() {
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    phone: '',
    email: '',
    description: '',
    status: 1,
  });

  const { code, name, phone, email, description, status } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  function refreshOnSubmit() {
    refreshTable();
  }

  const [affiliates, setAffiliates] = useState([]);
  const [viewOrderPDF, setViewOrderPDF] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);

  const [columnSearch, setColumnSearch] = useState([]);
  const [searchColumns, setSearchColumns] = useState(['codigo', 'nombre']);

  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState('');
  const [q, setQ] = useState('');
  const modal = useRef(null);

  if (alert) {
    if (!alertify.successAlert) {
      alertify.dialog(
        'successAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span className="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.successAlert(alert, function () {
      if (typeof modal.current === 'object') {
        modal.current.close();
      }
      alertify.success('Muy bien!');
    });
    setAlert('');
  }

  useEffect(() => {
    const fetchData = () => {
      return fetch('/api/affiliates', { method: 'GET' })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            setAffiliates(json.affiliates);
            setColumnSearch(json.columnSearch);
            setLoading(false);
          } else {
            setColumnSearch([]);
            setAlert(json.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    fetchData();
  }, []);

  function submitData() {
    var afiliates = [];

    afiliates.push({
      code,
      name,
      phone,
      email,
      description,
      status,
    });

    fetch('/api/affiliates', {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(afiliates),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.message) {
          setAlert(json.message);
        }
        refreshTable();
      })
      .catch((err) => {
        console.error(err);
        refreshTable();
      });
  }

  function refreshTable() {
    fetch('/api/affiliates', { method: 'GET' })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          setAffiliates(json.affiliates);
        } else {
          setAlert(json.message);
          setAffiliates([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function getOrder(id) {
    await fetch(`/api/facturas/fetch/by/id/${id}`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.ok) {
          setViewOrderPDF(true);
          setInvoiceData(res.data);
        } else {
          setAlert(res.message);
          setViewOrderPDF(false);
          setInvoiceData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const columns = columnSearch[0] && Object.keys(columnSearch[0]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submitData();
  };
  const shoppingHistory = (id) => {
    getOrder(id);
  };

  const handleCardBack = () => {
    setViewOrderPDF(false);
    setInvoiceData([]);
  };

  return (
    <Fragment>
      <div className='mb-auto h-full'>
        <div className='flex justify-center flex-wrap mt-4'>
          <div className='w-full px-4'>
            {viewOrderPDF ? (
              Object.keys(invoiceData).length !== 0 && (
                <>
                  <div className='admin-afiliados/pdf:buttons-div pb-8'>
                    <div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleCardBack();
                        }}
                        className='w-24 text-center p-3 rounded-xl order-button-back'>
                        Salir
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          handleCardBack();
                        }}
                        className='w-24 text-center p-3 rounded-xl order-button-back'>
                        Salir
                      </button>
                    </div>
                  </div>
                  {isMobile ? (
                    <PDFDownloadLink
                      document={<Invoice invoice={invoiceData} />}>
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          <Loader
                            type='MutatingDots'
                            color='#9fd8df'
                            secondaryColor='#4b778d'
                            height={100}
                            width={100}
                            className='loader-middle'
                          />
                        ) : (
                          (openDownloadModal(url),
                          (
                            <button
                              className='bg-red-200 border-red-300 border rounded-md text-center w-64 h-12 m-auto block'
                              onClick={(e) => {
                                e.preventDefault();
                                openDownloadModal(url);
                              }}>
                              Generar nuevo PDF
                            </button>
                          ))
                        )
                      }
                    </PDFDownloadLink>
                  ) : (
                    <PDFViewer
                      height='600'
                      className='app md:max-w-1150-px md:min-h-screen-80 relative mx-auto max-w-full w-full'>
                      <Invoice invoice={invoiceData} />
                    </PDFViewer>
                  )}
                </>
              )
            ) : (
              <div className='xs:block align-middle items-center justify-center mb-3'>
                <div className='my-auto pt-2 float-left mx-auto pb-6'>
                  <input
                    className='border rounded-xl py-2 px-3 mr-4 text-gray-700'
                    type='text'
                    value={q}
                    onChange={(e) => setQ(e.target.value)}
                  />
                </div>
                {columns &&
                  columns.map((column) => (
                    <div key={column} className='inline-flex'>
                      <div className='rounded-t-xl overflow-hidden p-2'>
                        <div className='p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md'>
                          <label className='flex items-center space-x-3'>
                            <input
                              className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none'
                              type='checkbox'
                              checked={searchColumns.includes(column)}
                              onChange={(e) => {
                                const checked = searchColumns.includes(column);
                                setSearchColumns((prev) =>
                                  checked
                                    ? prev.filter((sc) => sc !== column)
                                    : [...prev, column]
                                );
                              }}
                            />
                            <span className='text-gray-900 font-normal space-x-3 font-sans capitalize text-sm'>
                              {column}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  ))}
                <div className='xs:float-right pt-2 xxl:float-right mx-auto mb-4'>
                  <button
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    onClick={() => modal.current.open()}>
                    Añadir nuevo Afiliado <i className='fas fa-plus-circle ' />
                  </button>
                </div>
              </div>
            )}

            <Modal ref={modal}>
              <form
                onSubmit={handleSubmit}
                className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 user-select'>
                <div className='mb-1 md:mb-0 pr-4 text-center'>
                  <span className='block text-gray-700 font-bold modal-title'>
                    # Añadir nuevo Afiliado
                  </span>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor='code'
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
                    <i className='fas fa-check-circle'></i> Código del Afiliado
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='number'
                    id='code'
                    name='code'
                    placeholder='Código del nuevo Afiliado'
                    onChange={(e) => onChange(e)}
                    value={code}
                    required
                  />
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor='name'
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
                    <i className='fas fa-check-circle'></i> Nombre del Afiliado
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id='name'
                    name='name'
                    placeholder='Nombre del nuevo Afiliado'
                    onChange={(e) => onChange(e)}
                    value={name}
                    required
                  />
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor='phone'
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
                    <i className='fas fa-check-circle'></i> Teléfono
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='tel'
                    id='phone'
                    name='phone'
                    placeholder='Número de télefono del nuevo Afiliado'
                    onChange={(e) => onChange(e)}
                    value={phone}
                    required
                  />
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor='email'
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
                    <i className='fas fa-check-circle'></i> Email
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='email'
                    id='email'
                    name='email'
                    placeholder='Email del Afiliado'
                    onChange={(e) => onChange(e)}
                    value={email}
                    required
                  />
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor='description'
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
                    <i className='fas fa-check-circle'></i> Descripción
                  </label>
                  <textarea
                    style={{ minHeight: '80px' }}
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id='description'
                    name='description'
                    placeholder='Descripción del nuevo Afiliado'
                    onChange={(e) => onChange(e)}
                    value={description}
                    required
                  />
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor='status'
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
                    <i className='fas fa-check-circle'></i> Estado del Afiliado
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id='status'
                    name='status'
                    onChange={(e) => onChange(e)}
                    value={status}>
                    <option value=''>Seleccionar</option>
                    <option value='1'>Activo</option>
                    <option value='2'>No activo</option>
                  </select>
                </div>
                <div className='float-right'>
                  <button
                    type='button'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 mr-4'
                    onClick={() => modal.current.close()}>
                    Cerrar ventana <i className='fas fa-times'></i>
                  </button>
                  <button
                    type='submit'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 ml-4'>
                    Agregar nuevo <i className='fas fa-user-plus'></i>
                  </button>
                </div>
              </form>
            </Modal>

            {loading ? (
              <Loader
                type='MutatingDots'
                color='#9fd8df'
                secondaryColor='#4b778d'
                height={100}
                width={100}
                className='loader-middle'
              />
            ) : (
              <CardAffiliates
                affiliates={search(affiliates)}
                recibir={refreshOnSubmit}
                shoppingHistory={shoppingHistory}
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
