import React, { Fragment, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from 'UserContext';
import { Helmet } from 'react-helmet';

import ClientSidebar from '../components/Sidebar/ClientSidebar.js';
import FooterClient from '../components/Footers/FooterClient.js';

import Dashboard from '../views/client/Dashboard';
import Inventario from '../views/client/Inventario.js';
import Pedidos from '../views/client/Pedidos.js';
import Facturas from '../views/client/Facturas.js';
import Encargos from 'views/admin/Encargos';

import Afiliados from '../views/client/Vianney/Afiliados.jsx';

const TITLE = 'Malem Group | Cliente';

export default function Client() {
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ClientSidebar />
      <div className='md:mt-12 md:mb-12 bg-gray-200 flex relative'>
        <div className='md:max-w-1150-px md:min-h-screen-80 relative mx-auto max-w-full w-full'>
          {userProfile.comercio !== `B'alam` ? (
            <Switch>
              <Route path='/client/dashboard' exact component={Dashboard} />
              <Route path='/client/inventario' exact component={Inventario} />
              <Route path='/client/pedidos/nuevo' exact component={Pedidos} />
              <Route
                path='/client/pedidos/pendientes'
                exact
                component={Facturas}
              />
              <Route path='/client/afiliados' exact component={Afiliados} />
              <Route path='/client/encargos/nuevo' exact component={Encargos} />
              <Redirect from='/client' to='/client/dashboard' />
            </Switch>
          ) : (
            <Switch>
              <Route path='/client/dashboard' exact component={Dashboard} />
              <Route path='/client/inventario' exact component={Inventario} />
              <Route path='/client/pedidos/nuevo' exact component={Pedidos} />
              <Route
                path='/client/pedidos/pendientes'
                exact
                component={Facturas}
              />
              <Redirect from='/client' to='/client/dashboard' />
            </Switch>
          )}
        </div>
        <div className='absolute bottom-0 h-0 w-full'>
          <FooterClient />
        </div>
      </div>
    </>
  );
}
