import React, { Fragment, useEffect, useState, useRef } from 'react';
import 'react-calendar/dist/Calendar.css';

import Modal from '../../components/Modals/WhiteModal.js';
import GraficoVentas from 'components/Cards/CardLineChart.js';
import GraficoPedidos from 'components/Cards/CardBarChart.js';
import EstadisticaVisitantes from 'components/Cards/CardPageVisits.js';
import EstadisticaTraficoSocial from 'components/Cards/CardSocialTraffic.js';
import { useHistory } from 'react-router';

export default function Dashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const modal = useRef(null);
  const history = useHistory();

  const [meses, setMeses] = useState({
    enero: 0,
    febrero: 0,
    marzo: 0,
    abril: 0,
    mayo: 0,
    junio: 0,
    julio: 0,
    agosto: 0,
    septiembre: 0,
    octube: 0,
    noviembre: 0,
    diciembre: 0,
  });

  const {
    enero,
    febrero,
    marzo,
    abril,
    mayo,
    junio,
    julio,
    agosto,
    septiembre,
    octubre,
    noviembre,
    diciembre,
  } = meses;

  useEffect(() => {
    let _isMounted = true;
    if (_isMounted) {
      fetch('/api/facturas/all', {
        method: 'GET',
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            var sortedData = json.data.sort(function (a, b) {
              return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
            });
            sortedData.reverse();
            setData(sortedData);
            getTotalKeysByMonth(sortedData);
            setLoading(false);
          } else {
            setData([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => {
      _isMounted = false;
    };
  }, [
    enero,
    febrero,
    marzo,
    abril,
    mayo,
    junio,
    julio,
    agosto,
    septiembre,
    octubre,
    noviembre,
    diciembre,
    loading,
  ]);

  function getTotalKeysByMonth(sortedData) {
    var enero = 0;
    var febrero = 0;
    var marzo = 0;
    var abril = 0;
    var mayo = 0;
    var junio = 0;
    var julio = 0;
    var agosto = 0;
    var septiembre = 0;
    var octubre = 0;
    var noviembre = 0;
    var diciembre = 0;

    for (let keys in sortedData) {
      switch (sortedData[keys].fecha.indexOf('2021-01') === -1) {
        case sortedData[keys].fecha.indexOf('2021-01') !== -1:
          enero += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-02') !== -1:
          febrero += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-03') !== -1:
          marzo += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-04') !== -1:
          abril += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-05') !== -1:
          mayo += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-06') !== -1:
          junio += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-07') !== -1:
          julio += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-08') !== -1:
          agosto += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-09') !== -1:
          septiembre += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-10') !== -1:
          octubre += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-11') !== -1:
          noviembre += 1;
          break;
        case sortedData[keys].fecha.indexOf('2021-12') !== -1:
          diciembre += 1;
          break;
      }
    }
    setMeses({
      ...meses,
      enero: enero,
      febrero: febrero,
      marzo: marzo,
      abril: abril,
      mayo: mayo,
      junio: junio,
      julio: julio,
      agosto: agosto,
      septiembre: septiembre,
      octubre: octubre,
      noviembre: noviembre,
      diciembre: diciembre,
    });
  }

  function handleSubmit() {
    history.push('/admin/reportes');
  }

  function closeModal(e) {
    e.preventDefault();
    modal.current.close();
  }

  const openModal = () => modal.current.open();

  return (
    <Fragment>
      {loading === false && Object.keys(data).length !== 0 && (
        <div className='my-12'>
          <div id='reports-area' className='mx-4 my-4'>
            <button
              className='shadow-lg 
              button-transform'
              onClick={(e) => openModal(e)}>
              <span>Obtener reporte mensual</span>
            </button>
          </div>

          <div className='flex flex-wrap'>
            <div className='w-full xl:w-8/12 mb-12 xl:mb-0 px-4'>
              <GraficoVentas />
            </div>
            <div className='w-full xl:w-4/12 px-4'>
              <GraficoPedidos
                enero={enero}
                febrero={febrero}
                marzo={marzo}
                abril={abril}
                mayo={mayo}
                junio={junio}
                julio={julio}
                agosto={agosto}
                septiembre={septiembre}
                octubre={octubre}
                noviembre={noviembre}
                diciembre={diciembre}
              />
            </div>
          </div>
          <div className='flex flex-wrap mt-4'>
            <div className='w-full xl:w-8/12 mb-12 xl:mb-0 px-4'>
              <EstadisticaVisitantes />
            </div>
            <div className='w-full xl:w-4/12 px-4'>
              <EstadisticaTraficoSocial />
            </div>
          </div>
        </div>
      )}
      <Modal ref={modal}>
        <form onSubmit={handleSubmit}>
          <div id='redirection-notice'>
            <h3>Hola!</h3>
            <h4>Serás redireccionado hacia la página de reportes.</h4>
            <button type='button' onClick={(e) => closeModal(e)}>
              No, regresar
            </button>
            <button type='submit'>Si, redirigir</button>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
}
