import React, { useEffect, useState, useRef } from 'react';

import Loader from 'react-loader-spinner';

import CatalogueCard from './CatalogueCard';

import bootstrapstyle from './../../extras/e-shop/css/bootstrap.module.css';
import uistyle from './../../extras/e-shop/css/ui.module.css';
import '../../assets/styles/bootstrap.custom.css';

require('./../../extras/e-shop/css/all.min.css');
require('../../extras/e-shop/js/jquery.min.js');
require('../../extras/e-shop/js/bootstrap.bundle.min.js');

export default function Catalogue() {
  const [data, setData] = useState([]);
  const [dataBackUp, setDataBackUp] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'barcode',
    'id',
    'codigo',
    'nombre',
    'especificacion',
    'valor',
  ]);
  const [dataPhoto, setDataPhoto] = useState([]);
  const [dataTags, setDataTags] = useState([]);
  const _isMounted = useRef(true);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (_isMounted.current) {
      var datos = [];

      if (window.location.href.indexOf('/catalogue/vianney') !== -1) {
        datos.push({
          comercio_id: 2,
        });
      } else {
        datos.push({
          comercio_id: 1,
        });
      }

      fetch('/api/catalogos/fetch', {
        method: 'POST',
        credentials: 'same-origin',
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(datos),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            var sortedData = json.data.sort(function (a, b) {
              return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
            });
            setDataBackUp(sortedData);
            sortedData.reverse();
            setData(sortedData);
            setDataPhoto(json.dataPhoto);
            setDataTags(json.dataTags);
            setDataSearch(json.dataSearch);
            setLoading(false);
          } else {
            setAlert(json.message);
            setData([]);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    return () => {
      _isMounted.current = false;
    };
  }, []);

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some((column) =>
        column === 'valor'
          ? (row[column] * 0.8)
              .toFixed(2)
              .toString()
              .toLowerCase()
              .indexOf(q.toLowerCase()) > -1
          : row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  function handleUniqueSpecs(array) {
    let specs = [];
    array.forEach((obj) => {
      specs.push(obj.especificacion);
    });
    return specs.filter(onlyUnique);
  }

  function priceRange(min, max) {
    var newData = [];

    for (var i = 0, length = dataBackUp.length; i < length; i++) {
      var current = dataBackUp[i];
      if (current.valor >= min / 0.8 && current.valor <= max / 0.8) {
        newData.push(current);
      }
    }
    setData(newData);
  }

  const sortItemsByCheapest = () => {
    const itemsByCheapest = [...dataBackUp];
    itemsByCheapest.sort((a, b) => a.valor - b.valor);
    setData(itemsByCheapest);
  };

  const sortItemsByLatest = () => {
    const itemsByLatestEntry = [...dataBackUp];
    itemsByLatestEntry.sort((a, b) => b.id - a.id);
    setData(itemsByLatestEntry);
  };

  const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  const handlePriceRangeSubmit = (e) => {
    var min = parseFloat(e.target.min.value);
    var max = parseFloat(e.target.max.value);
    priceRange(min, max);
  };

  const handleDataBackUp = () => {
    setData(dataBackUp);
  };

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  const modalView = useRef(null);

  return (
    <>
      <div className={bootstrapstyle.html}>
        <div className={bootstrapstyle.app}>
          <header
            className={`${bootstrapstyle.header} ${uistyle['section-header']}`}>
            <section
              className={`${bootstrapstyle.section} ${uistyle['header-main']} ${bootstrapstyle['border-bottom']}`}>
              <div className={bootstrapstyle.container}>
                <div
                  className={`${bootstrapstyle.row} ${bootstrapstyle['align-items-center']}`}>
                  <div
                    className={`${bootstrapstyle['col-lg-2']} ${bootstrapstyle['col-4']}`}>
                    <a href='/' className={uistyle['brand-wrap']}>
                      Malem Group
                    </a>
                  </div>
                  <div
                    className={`${bootstrapstyle['col-lg-6']} ${bootstrapstyle['col-sm-12']}`}>
                    {/* <form action=" " className="search">
									<div className="input-group w-100">
										<input type="text" className="form-control" placeholder="Search" />
										<div className="input-group-append">
											<button className="btn btn-primary" type="submit">
												<i className="fa fa-search"></i>
											</button>
										</div>
									</div>
								</form>  */}
                  </div>
                  <div
                    className={`${bootstrapstyle['col-lg-4']} ${bootstrapstyle['col-sm-6']} ${bootstrapstyle['col-12']}`}>
                    <div className={bootstrapstyle['float-md-right']}>
                      {/* <div className="widget-header  mr-3">
											<a href=" " className="icon icon-sm rounded-circle border"><i className="fa fa-shopping-cart"></i></a>
											<span className="badge badge-pill badge-danger notify">0</span>
									</div> */}
                      <div
                        className={`${uistyle['widget-header']} ${uistyle.icontext}`}>
                        <a
                          href='/autenticacion/sesion'
                          className={`${uistyle.icon} ${uistyle['icon-sm']} ${bootstrapstyle['rounded-circle']} ${bootstrapstyle.border}`}>
                          <i className='fa fa-user'></i>
                        </a>
                        <div className={uistyle.text}>
                          <span className={bootstrapstyle['text-muted']}>
                            Bienvenido!
                          </span>
                          <div>
                            <a href='/autenticacion/sesion'>Iniciar sesión</a>
                            {/* <a href=" "> | Register</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </header>

          <section
            className={`${bootstrapstyle.section} ${uistyle['section-pagetop']} ${uistyle.bg}]`}>
            <div className={bootstrapstyle.container}>
              <h2 className={`${bootstrapstyle.h2} ${uistyle['title-page']}`}>
                Catálogo de productos
              </h2>
              <nav className={bootstrapstyle.nav}>
                <ol
                  className={`${bootstrapstyle.ol} ${bootstrapstyle.breadcrumb} ${bootstrapstyle['text-white']}`}>
                  <li className={`${bootstrapstyle['breadcrumb-item']}`}>
                    <a className={bootstrapstyle.a} href='/'>
                      Inicio
                    </a>
                  </li>
                  {window.location.href.indexOf('/catalogue/vianney') !== -1 ? (
                    <>
                      <li
                        className={`${bootstrapstyle['breadcrumb-item']} ${bootstrapstyle.active}`}
                        aria-current='page'>
                        Vianney
                      </li>
                      <li className={`${bootstrapstyle['breadcrumb-item']}`}>
                        <a className={bootstrapstyle.a} href='/catalogue/balam'>
                          B'alam
                        </a>
                      </li>
                    </>
                  ) : (
                    <>
                      <li className={`${bootstrapstyle['breadcrumb-item']}`}>
                        <a
                          className={bootstrapstyle.a}
                          href='/catalogue/vianney'>
                          Vianney
                        </a>
                      </li>
                      <li
                        className={`${bootstrapstyle['breadcrumb-item']} ${bootstrapstyle.active}`}
                        aria-current='page'>
                        B'alam
                      </li>
                    </>
                  )}
                </ol>
              </nav>
            </div>
          </section>

          <section
            className={`${bootstrapstyle.section} ${uistyle['padding-y']}`}>
            <div className={bootstrapstyle.container}>
              <div className={bootstrapstyle.row}>
                <aside
                  className={`${bootstrapstyle.aside} ${bootstrapstyle['col-md-3']}`}>
                  <div className={bootstrapstyle.card}>
                    <article
                      className={`${bootstrapstyle.article} ${uistyle['filter-group']}`}>
                      <header
                        className={`${bootstrapstyle.header} ${bootstrapstyle['card-header']} ${uistyle['card-header']}`}>
                        <a
                          className={bootstrapstyle.a}
                          href=' '
                          data-toggle='bootstrap_collapse'
                          data-target='#collapse_1'
                          aria-expanded='true'>
                          <i
                            className={`${uistyle['icon-control']} fa fa-chevron-down`}></i>
                          <h6
                            className={`${bootstrapstyle.h6} ${uistyle.title}`}>
                            Productos
                          </h6>
                        </a>
                      </header>
                      <div
                        className='filter-group bootstrap_collapse bootstrap_show'
                        id='collapse_1'>
                        <div className={bootstrapstyle['card-body']}>
                          <div className={bootstrapstyle['pb-3']}>
                            <div className={bootstrapstyle['input-group']}>
                              <input
                                type='text'
                                className={`${bootstrapstyle['form-control']} ${uistyle['form-control']}`}
                                placeholder='Buscar'
                                onChange={(e) => {
                                  setQ(e.target.value);
                                }}
                              />
                              <div
                                className={
                                  bootstrapstyle['input-group-append']
                                }>
                                <button
                                  className={`${bootstrapstyle.btn} ${bootstrapstyle['btn-light']}]`}
                                  type='button'>
                                  <i className='fa fa-search'></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          {columns &&
                            columns.map((column) => (
                              <div key={column}>
                                <label
                                  className={`${bootstrapstyle['custom-control']} ${bootstrapstyle['custom-checkbox']}`}>
                                  <input
                                    className={
                                      bootstrapstyle['custom-control-input']
                                    }
                                    type='checkbox'
                                    checked={searchColumns.includes(column)}
                                    onChange={(e) => {
                                      const checked =
                                        searchColumns.includes(column);
                                      setSearchColumns((prev) =>
                                        checked
                                          ? prev.filter((sc) => sc !== column)
                                          : [...prev, column]
                                      );
                                    }}
                                  />
                                  <div
                                    className={`${bootstrapstyle['custom-control-label']} capitalize`}>
                                    {column}
                                  </div>
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    </article>

                    <article
                      className={`${bootstrapstyle.article} ${uistyle['filter-group']}`}>
                      <header
                        className={`${bootstrapstyle.header} ${bootstrapstyle['card-header']} ${uistyle['card-header']}`}>
                        <a
                          className={bootstrapstyle.a}
                          href=' '
                          data-toggle='bootstrap_collapse'
                          data-target='#collapse_3'
                          aria-expanded='true'>
                          <i
                            className={`${uistyle['icon-control']} fa fa-chevron-down`}></i>
                          <h6
                            className={`${bootstrapstyle.h6} ${uistyle.title}`}>
                            Rango de precios
                          </h6>
                        </a>
                      </header>
                      <div
                        className='filter-group bootstrap_collapse bootstrap_show'
                        id='collapse_3'>
                        <div className={bootstrapstyle['card-body']}>
                          {/* <input type="range" className="custom-range" min="0" max="100" name="" /> */}
                          <form
                            className={bootstrapstyle['form-row']}
                            onSubmit={(e) => {
                              e.preventDefault();
                              handlePriceRangeSubmit(e);
                            }}>
                            <div
                              className={`${bootstrapstyle['form-group']} ${bootstrapstyle['col-md-6']}`}>
                              <label>Min</label>
                              <input
                                className={`${bootstrapstyle['form-control']} ${uistyle['form-control']}`}
                                placeholder='Q0'
                                type='number'
                                name='min'
                              />
                            </div>
                            <div
                              className={`${bootstrapstyle['form-group']} ${bootstrapstyle['text-right']} ${bootstrapstyle['col-md-6']}`}>
                              <label>Max</label>
                              <input
                                className={`${bootstrapstyle['form-control']} ${uistyle['form-control']}`}
                                placeholder='Q1,0000'
                                type='number'
                                name='max'
                              />
                            </div>
                            <button
                              type='submit'
                              className={`${bootstrapstyle.btn} ${bootstrapstyle['btn-block']} ${bootstrapstyle['btn-primary']}`}>
                              Aplicar
                            </button>
                            <button
                              onClick={(e) => {
                                e.preventDefault();
                                handleDataBackUp();
                              }}
                              className={`${bootstrapstyle.btn} ${bootstrapstyle['btn-block']} ${bootstrapstyle['btn-primary']}`}>
                              Deshacer
                            </button>
                          </form>
                        </div>
                      </div>
                    </article>

                    <article
                      className={`${bootstrapstyle.article} ${uistyle['filter-group']}`}>
                      <header
                        className={`${bootstrapstyle.header} ${bootstrapstyle['card-header']} ${uistyle['card-header']}`}>
                        <a
                          className={bootstrapstyle.a}
                          href=' '
                          data-toggle='bootstrap_collapse'
                          data-target='#collapse_4'
                          aria-expanded='true'>
                          <i
                            className={`${uistyle['icon-control']} fa fa-chevron-down`}></i>
                          <h6
                            className={`${bootstrapstyle.h6} ${uistyle.title}`}>
                            Especificaciones{' '}
                          </h6>
                        </a>
                      </header>
                      <div
                        className='filter-group bootstrap_collapse bootstrap_show'
                        id='collapse_4'>
                        <div
                          className={`${bootstrapstyle['card-body']} ${uistyle['card-body']}`}>
                          {handleUniqueSpecs(dataBackUp).map((row, index) => (
                            <label
                              key={index}
                              className={uistyle['checkbox-btn']}>
                              <input
                                type='checkbox'
                                value={row}
                                onChange={(e) =>
                                  q !== '' ? setQ('') : setQ(e.target.value)
                                }
                              />
                              <span
                                className={`${bootstrapstyle.btn} ${uistyle.btn} ${bootstrapstyle['btn-light']} ${uistyle['btn-light']}`}>
                                {row}
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    </article>
                  </div>
                </aside>

                <main
                  className={`${bootstrapstyle.main} ${bootstrapstyle['col-md-9']}`}>
                  <header
                    className={`${bootstrapstyle.header} ${bootstrapstyle['border-bottom']} ${bootstrapstyle['mb-4']} ${bootstrapstyle['pb-3']}`}>
                    <div className={bootstrapstyle['form-inline']}>
                      <span className={bootstrapstyle['mr-md-auto']}>
                        {search(data).length}{' '}
                        {search(data).length === 1
                          ? 'Artículo encontrado'
                          : 'Artículos encontrados'}{' '}
                      </span>
                      <select
                        className={`${bootstrapstyle['mr-2']} ${bootstrapstyle['form-control']} ${uistyle['form-control']}`}>
                        <option
                          onClick={(e) => {
                            sortItemsByLatest(e);
                          }}>
                          Últimos artículos
                        </option>
                        <option>Tendencias</option>
                        <option>Más popular</option>
                        <option
                          onClick={(e) => {
                            sortItemsByCheapest(e);
                          }}>
                          Lo más barato
                        </option>
                      </select>
                      <div className={bootstrapstyle['btn-group']}>
                        <a
                          href=' '
                          className={`${bootstrapstyle.btn} ${uistyle.btn} ${bootstrapstyle['btn-outline-secondary']} ${bootstrapstyle.active}`}
                          data-toggle='tooltip'
                          title='List view'>
                          <i className='fa fa-bars'></i>
                        </a>
                        <a
                          href=' '
                          className={`${bootstrapstyle.btn} ${uistyle.btn} ${bootstrapstyle['btn-outline-secondary']}`}
                          data-toggle='tooltip'
                          title='Grid view'>
                          <i className='fa fa-th'></i>
                        </a>
                      </div>
                    </div>
                  </header>
                  {loading ? (
                    <Loader
                      type='MutatingDots'
                      color='#9fd8df'
                      secondaryColor='#4b778d'
                      height={100}
                      width={100}
                      className='loader-middle'
                    />
                  ) : (
                    <CatalogueCard
                      data={search(data)}
                      dataPhoto={dataPhoto}
                      dataTags={dataTags}
                    />
                  )}
                </main>
              </div>
            </div>
          </section>

          <footer
            className={`${bootstrapstyle.footer} ${uistyle['section-footer']} ${bootstrapstyle['border-top']} ${uistyle['padding-y']}`}>
            <div className={bootstrapstyle.container}>
              <p className={bootstrapstyle['float-md-right']}>
                Malem Group &copy; Copyright{' '}
                {new Date().getFullYear() === 2021
                  ? 2021
                  : 2021 + ' - ' + new Date().getFullYear()}
                . Todos los derechos reservados
              </p>
              <p>
                <a className={bootstrapstyle.a} href='/terminos-y-condiciones'>
                  Términos y condiciones
                </a>
              </p>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}
