import React, { useState, useEffect, useRef } from "react";
import CardStockTags from "../../components/Cards/CardStockTags.js";
import Modal from "../../components/Modals/Modal.js";
// Loader
import Loader from "react-loader-spinner";
// Alertify
import alertify from 'alertifyjs';
// Select
import Select from 'react-select';

export default function ProductoTags() {

  function refreshOnSubmit() {
    refreshTable()
  }

  // Loading state
  const [loading, setLoading] = useState(true);
  // Data Map
  const [data, setData] = useState([]);  
  const [dataStock, setDataStock] = useState([])
  const [dataTag, setDataTag] = useState([])  
  const [dataSearch, setDataSearch] = useState([]);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["id", "codigo", "producto", "tag", "cantidad"]);
  const modal = useRef(null);
  // Data push
  const [producto, setProducto] = useState("");
  const [tag, setTag] = useState("");
  const [cantidadTag, setCantidadTag] = useState("");
  // Information about present view
  const documentTitle = ("Producto #Hashtag")
  const URIExtension = ("producto/tags")
  const URIPost = ("/api/post/")
  const URI = ("/api/data/")
  // Alerts
  const [alert, setAlert] = useState("");

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog('errorAlert',function factory() {
        return{
            build:function() {
              var errorHeader = '<span class="fas fa-info-circle fa-2x" '
              +    'style="vertical-align:middle;color:#77acf1;">'
              + '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            }
        };
      },true,'alert');
    }
    alertify.errorAlert(alert, function() {
      if (modal.current){ 
        // modal.current.close()
      }
      alertify.success('Muy bien!');
    })
    setAlert("");
  }

  useEffect(() => {
    const fetchData = () => {
    return fetch(URI+URIExtension, {method: "GET"})
      .then((response) => response.json())
      .then((json) => {
        // Data successfully fetched
        if (json.success) {
          var sortedData = json.data.sort(function(a,b) {return (a.producto_tag_id > b.producto_tag_id) ? 1 : ((b.producto_tag_id > a.producto_tag_id) ? -1 : 0);} );
          sortedData.reverse();
          setData(sortedData);
          setDataStock(json.dataStock);
          setDataTag(json.dataTag);
          setDataSearch(json.dataSearch);
          setLoading(false);
        }
        // Else error / No data available
        else {
          setAlert(json.message);
        }
      })
      .catch((error) => {
        console.log(error)
      })
    };
    fetchData();
  }, []);

  function submitData() {

    var datos = [];
  
    datos.push ({
      producto: producto.value,
      tag,
      cantidadTag
    })
  
    fetch(URIPost+URIExtension, {
        method: 'POST',
        credentials: 'same-origin',
        withCredentials: true,
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify(datos)
    })
      .then((response) => response.json())
      .then((json) => {
        // Data successfully inserted
        if (json.success) {
          setAlert(json.message);
          refreshTable();
        }
        // Else error
        else {
          setAlert(json.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      })
  }  
  
  function refreshTable () {
    fetch(URI+URIExtension, {method: "GET"})
      .then((response) => response.json())
      .then((json) => {
        // Refresh data successfully
        if (json.success) {
          var sortedData = json.data.sort(function(a,b) {return (a.producto_tag_id > b.producto_tag_id) ? 1 : ((b.producto_tag_id > a.producto_tag_id) ? -1 : 0);} );
          sortedData.reverse();
          setData(sortedData);
          setDataSearch(json.dataSearch);
        }
        // Else error / No data available
        else {
          setAlert(json.message);
          setData([]);
        }
      })
      .catch((error) => {
        // console.log(error);
      })
  }

  function search(rows) {
    return rows.filter((row) => 
      searchColumns.some(
        (column) => 
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submitData()
  }

  const customStyles = {
    menuList: (provided) => ({
      ...provided,
      maxHeight: '200px',
    }),
    option: (provided) => ({
      ...provided,
      display: 'inline-block',
      fontWeight: 'normal',
      lineHeight: '1',
      minWidth: 1,
    }),
    placeholder: (provided) => ({
      ...provided,
      paddingLeft: '10px'
    })
  };

  const handleKeyDown = (evt)=>{
    switch(evt.key){
      case "Home": evt.preventDefault();
        if(evt.shiftKey) evt.target.selectionStart = 0;
        else evt.target.setSelectionRange(0,0);
        break;
      case "End": evt.preventDefault();
        const len = evt.target.value.length;
        if(evt.shiftKey) evt.target.selectionEnd = len;
        else evt.target.setSelectionRange(len,len);
        break;
    }
 };

  return (    
  <>
    <div className="mb-auto h-full">
      <div className="flex justify-center flex-wrap mt-4">   
        <div className="w-full px-4">
          <div className="xs:block align-middle items-center justify-center mb-3">
            <div className="my-auto pt-2 float-left mx-auto">
              <input className="border rounded-xl py-2 px-3 mr-4 text-gray-700" type="text" value={q} onChange={(e) => setQ(e.target.value)} />            
            </div>
            {columns &&
              columns.map((column) => (           
                <div key={column} className="inline-flex">
                  <div className="rounded-t-xl overflow-hidden p-2">
                    <div className="p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md">
                      <label className="flex items-center space-x-3">
                        <input
                          className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
                          type="checkbox"
                          checked={searchColumns.includes(column)}
                          onChange={(e) => {
                            const checked = searchColumns.includes(column);
                            setSearchColumns((prev) => 
                              checked
                              ? prev.filter((sc) => sc !== column)
                              : [...prev, column]
                            );
                          }}
                        />
                        <span className="text-gray-900 font-normal space-x-3 font-sans capitalize text-sm">{column}</span>
                      </label>
                    </div>
                  </div>
                </div>
              ))
            }
            <div className="md:float-right pt-2 mx-auto">
              <button 
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => modal.current.open()}
              >
                Añadir nuevo #Hashtag a Producto
              </button>
            </div>     
          </div>          
          <Modal ref={modal}>
            <form onSubmit={handleSubmit}>
              <div
                className="text-gray-300 font-bold md:text-left mb-1 md:mb-0 pr-4"
              >
                Añadir nuevo {documentTitle}
              </div>
              <div
                className="my-4 flex items-center justify-center max-w-full"
              >
                <label 
                  htmlFor={"producto" + documentTitle}
                  className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                >
                  Producto
                </label>
                <Select
                  onKeyDown={handleKeyDown}
                  className="bg-gray-200 appearance-none rounded text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                  options={
                    dataStock.map((row) => (
                      {
                        label: `${row.nombre} (id: ${row.id}) (código: ${row.codigo})`,
                        value: `${row.id}`
                      }
                    ))
                  }
                  styles={customStyles}
                  onChange={e => setProducto(e)}
                  placeholder="Seleccionar"
                  noOptionsMessage={() => "Sin resultados"}
                  value={producto}
                />
              </div>
              <div
                className="my-4 flex items-center justify-center max-w-full"
              >
                <label 
                  htmlFor={"tag" + documentTitle}
                  className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                >
                  Tag
                </label>
                <select
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                  onChange={e => setTag(e.target.value)}
                  value={tag}
                >
                <option value="" defaultValue>Seleccionar</option>
                {dataTag.map((row, index) => (
                  <option key={index} value={row.id}>
                    {row.nombre}
                  </option>
                ))}
                </select>
              </div>
              <div
                className="my-4 md:block flex items-center justify-center max-w-full"
              >
                <label 
                  htmlFor={"nombre" + documentTitle}
                  className="text-gray-500 font-bold md:text-left mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                >
                  Cantidad del {documentTitle}
                </label>
                <input 
                  className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                  type="text"
                  id={"nombre" + documentTitle} 
                  name={"nombre" + documentTitle}
                  placeholder={"Cantidad del " + documentTitle}
                  onChange={e => setCantidadTag(e.target.value)}
                  value={cantidadTag}
                >              
                </input>
              </div>
              <div 
                className="flex"
              >
                <button 
                  type="button"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-1/2 w-1/2"
                  onClick={() => modal.current.close()}
                >
                  Cerrar ventana
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-1/2 w-1/2"
                >
                  Agregar nuevo
                </button>
              </div>              
            </form>
          </Modal>
          {
          loading 
          ? <Loader type="MutatingDots" color="#9fd8df" secondaryColor="#4b778d" height={100} width={100} className="loader-middle" />
          : <CardStockTags
              data={search(data)}
              info={documentTitle} 
              URIExtension={URIExtension}
              recibir={refreshOnSubmit} 
            />
          }
        </div>            
      </div>
    </div>
    </>
  );  
}