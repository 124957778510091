import React, { useState, useEffect, useRef, Fragment } from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { isMobile } from 'react-device-detect';

import CardEncargos from '../../components/Cards/CardEncargos.js';
import CardOrders from '../../components/Cards/CardOrders.js';
import Modal from 'components/Modals/Modal.js';
import Invoice from '../../components/Reports/Invoice';

export default function Encargos() {
  const [formData, setFormData] = useState({
    affiliate: '',
    commerce: '',
    extension: '',
  });

  const { affiliate, commerce, extension } = formData;
  const _isMounted = useRef(true);

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (_isMounted.current) {
      fetch('/api/orders/new', { method: 'GET' })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            var sortedData = json.orders.sort(function (a, b) {
              return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
            });
            sortedData.reverse();
            setOrders(sortedData);
            setAffiliates(json.affiliates);
            setCommerces(json.commerces);
            setExtensions(json.extensions);
            setDataSearch(json.columnSearch);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    return () => {
      _isMounted.current = false;
    };
  }, []);

  function obtainData() {
    return fetch('/api/inventario/commerce', {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          setData(json.data);
          setDataSearch(json.dataSearch);
          setSearchColumns(['barcode', 'id', 'valor', 'nombre']);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const refreshOnSubmit = () => {
    refreshTable();
  };

  const [createOrder, setCreateOrder] = useState(false);
  const [data, setData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [commerces, setCommerces] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [q, setQ] = useState('');
  const [loading, setLoading] = useState(true);
  const [viewOrderPDF, setViewOrderPDF] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [searchColumns, setSearchColumns] = useState([
    'id',
    'fecha',
    'comercio',
    'afiliado',
  ]);
  const modal = useRef(null);

  function refreshTable() {
    return fetch('/api/orders/new', { method: 'GET' })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          setOrders(json.orders);
          setAffiliates(json.affiliates);
          setCommerces(json.commerces);
          setExtensions(json.extensions);
          setDataSearch(json.columnSearch);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  const handleSubmit = (e) => {
    e.preventDefault();
    obtainData();
    setCreateOrder(true);
    if (modal.current) modal.current.close();
  };

  function handleViewOrder(value) {
    viewOrder(value);
  }

  const viewOrder = (id) => {
    var datos = [];

    datos.push({
      id: id,
    });

    fetch('/api/orders/fetch/id', {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos),
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          setViewOrderPDF(true);
          setInvoiceData(json.data);
          forceUpdate();
        } else {
          setAlert(json.message);
          setViewOrderPDF(false);
          setInvoiceData([]);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCardBack = () => {
    setViewOrderPDF(false);
  };

  return (
    <Fragment>
      <div className='mb-auto h-full'>
        <div className='flex justify-center flex-wrap mt-4'>
          <div className='w-full px-4'>
            <div className='block align-middle items-center justify-center mb-3 h-10'>
              <div className='my-auto pt-2 float-left mx-auto'>
                <input
                  className='border rounded-xl py-2 px-3 mr-4 text-gray-700'
                  type='text'
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
              {columns &&
                columns.map((column) => (
                  <div key={column} className='inline-flex'>
                    <div className='rounded-t-xl overflow-hidden p-2'>
                      <div className='p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md'>
                        <label className='flex items-center space-x-3'>
                          <input
                            className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none'
                            type='checkbox'
                            checked={searchColumns.includes(column)}
                            onChange={(e) => {
                              const checked = searchColumns.includes(column);
                              setSearchColumns((prev) =>
                                checked
                                  ? prev.filter((sc) => sc !== column)
                                  : [...prev, column]
                              );
                            }}
                          />
                          <span className='text-gray-900 font-normal space-x-3 font-sans capitalize text-sm'>
                            {column}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              {!createOrder && (
                <div className='xs:float-right pt-2 xxl:float-right mx-auto'>
                  <button
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                    onClick={() => modal.current.open()}>
                    Añadir nuevo Encargo
                  </button>
                </div>
              )}
            </div>
            {!createOrder ? (
              <div className='my-5 block'>
                <CardEncargos
                  orders={search(orders)}
                  recibir={refreshOnSubmit}
                  viewOrder={handleViewOrder}
                />
              </div>
            ) : (
              <div className='pt-20'>
                <CardOrders
                  data={search(data)}
                  buyPhase={data}
                  form={formData}
                  setCreateOrder={setCreateOrder}
                  setSearchColumns={setSearchColumns}
                  refreshOnSubmit={refreshOnSubmit}
                />
              </div>
            )}
            {viewOrderPDF ? (
              invoiceData &&
              Object.keys(invoiceData).length !== 0 &&
              invoiceData.constructor === Object ? (
                <Fragment>
                  <div className='buttons pb-8'>
                    <button
                      type='button'
                      onClick={(e) => {
                        e.preventDefault();
                        handleCardBack();
                      }}
                      className='text-left p-3 rounded-xl order-button-back'>
                      Salir
                    </button>
                    <button
                      type='button'
                      onClick={(e) => {
                        e.preventDefault();
                        handleCardBack();
                      }}
                      className='float-right p-3 rounded-xl order-button-back'>
                      Salir
                    </button>
                  </div>
                  <Fragment>
                    {isMobile ? (
                      <PDFDownloadLink
                        document={<Invoice invoice={invoiceData} />}>
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Loader
                              type='MutatingDots'
                              color='#9fd8df'
                              secondaryColor='#4b778d'
                              height={100}
                              width={100}
                              className='loader-middle'
                            />
                          ) : (
                            (openDownloadModal(url),
                            (
                              <button
                                className='bg-red-200 border-red-300 border rounded-md text-center w-64 h-12 m-auto block'
                                onClick={(e) => {
                                  e.preventDefault();
                                  openDownloadModal(url);
                                }}>
                                Generar nuevo PDF
                              </button>
                            ))
                          )
                        }
                      </PDFDownloadLink>
                    ) : (
                      <PDFViewer
                        height='600'
                        className='app md:max-w-1150-px md:min-h-screen-80 relative mx-auto max-w-full w-full'>
                        <Invoice invoice={invoiceData} />
                      </PDFViewer>
                    )}
                  </Fragment>
                </Fragment>
              ) : null
            ) : null}
          </div>
        </div>
      </div>
      <Modal ref={modal}>
        <form
          onSubmit={handleSubmit}
          className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 user-select'>
          <div className='text-gray-700 font-bold mb-1 md:mb-0 pr-4 text-align-center modal-title'>
            # Añadir nuevo Encargo
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='affiliate'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-check-circle'></i> Afiliado:
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              id='affiliate'
              name='affiliate'
              onChange={(e) => onChange(e)}
              value={affiliate}
              required>
              <option value=''>Seleccionar</option>
              {affiliates &&
                affiliates.map((affiliate, index) => (
                  <option key={index} value={JSON.stringify(affiliate)}>
                    {affiliate.nombre}
                  </option>
                ))}
            </select>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='commerce'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-check-circle'></i> Comercio:
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              id='commerce'
              name='commerce'
              onChange={(e) => onChange(e)}
              value={commerce}
              required>
              <option value=''>Seleccionar</option>
              {commerces &&
                commerces.map((comercio, index) => (
                  <option key={index} value={comercio.id}>
                    {comercio.nombre}
                  </option>
                ))}
            </select>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='extension'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-check-circle'></i> Extensión:
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              id='extension'
              name='extension'
              onChange={(e) => onChange(e)}
              value={extension}
              required>
              <option value=''>Seleccionar</option>
              {extensions &&
                extensions.map((extension, index) => (
                  <option key={index} value={extension.id}>
                    {extension.nombre}
                  </option>
                ))}
            </select>
          </div>
          <div className='float-right'>
            <button
              type='button'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 mr-4'
              onClick={() => modal.current.close()}>
              Cerrar ventana <i className='fas fa-times'></i>
            </button>
            <button
              type='submit'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 ml-4'>
              Añadir nuevo <i className='fas fa-check'></i>
            </button>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
}
