import React, { useState, useEffect, useRef } from "react";
// components
import CardUser from "../../components/Cards/CardUser.js";
import Modal from "../../components/Modals/Modal.js";
// Loader
import Loader from "react-loader-spinner";
// Alertify
import alertify from 'alertifyjs';

export default function Usuarios() {

  function refreshOnSubmit() {
    refreshTable()
  }

  // Loading state
  const [loading, setLoading] = useState(true);
  // Fetch & Data Store From DB
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataShop, setDataShop] = useState([]);
  const [dataExtension, setDataExtension] = useState([]);
  const [q, setQ] = useState("");
  const [searchColumns, setSearchColumns] = useState(["id", "nombre"]);
  // documentTitle
  const documentTitle = ("Usuario")  
  const URIExtension = ("users");
  const URIPost = ("/api/post/");
  const URI = ("/api/data/");
  // modal
  const modal = useRef(null);
  // datos push
  const [nameData, setNameData] = useState("");
  const [activeDataRaw, setActiveDataRaw] = useState("");
  const [emailData, setEmailData] = useState("");
  const [passwordData, setPasswordData] = useState("");
  const [departamentData, setDepartamentData] = useState("");
  const [addressData, setAddressData] = useState("");
  const [jobData, setJobData] = useState("");
  const [shopUserIdData, setShopUserIdData] = useState("");
  const [extensionUserIdData, setExtensionUserIdData] = useState("");  
  const [taskRawData, setTaskRawData] = useState("");
  const [phoneNumerData, setPhoneNumerData] = useState("");
  const [descriptionData, setDescriptionData] = useState("");
  // Alerts
  const [alert, setAlert] = useState("");

  if(alert) {
    if(!alertify.errorAlert) {
      alertify.dialog('errorAlert',function factory() {
        return{
                build:function(){
                    var errorHeader = '<span class="fas fa-info-circle fa-2x" '
                    +    'style="vertical-align:middle;color:#77acf1;">'
                    + '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
                    this.setHeader(errorHeader);
                }
            };
        },true,'alert');
    }
    alertify
        .errorAlert(alert);
    setAlert("");
  }

  useEffect(() => {
    const fetchData = () => {
    return fetch(URI+URIExtension, {method: "GET"})
      .then((response) => response.json())
      .then((json) => {
        if (json.success) {
          setData(json.data);
          setDataShop(json.dataShop);
          setDataExtension(json.dataExtension);
          setDataSearch(json.dataSearch);
          setLoading(false);
        }
        // Else error / No data available
        else {
          setAlert(json.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      })
    };
    fetchData();

  }, []);

  function submitData() {

    var datos = [];
  
    datos.push ({
      nameData,
      activeDataRaw,
      emailData,
      passwordData,
      departamentData,
      addressData,
      jobData,
      shopUserIdData,
      extensionUserIdData,
      taskRawData,
      phoneNumerData,
      descriptionData
    })
  
    if(!nameData || !activeDataRaw || !emailData || !passwordData || !departamentData || !addressData || !jobData || !shopUserIdData || !extensionUserIdData || !taskRawData || !phoneNumerData || !descriptionData) {
      setAlert("Por favor introduzca todos los datos del usuario");
    }
    else if (nameData && activeDataRaw && emailData && passwordData && departamentData && addressData && jobData && shopUserIdData && extensionUserIdData && taskRawData && phoneNumerData && descriptionData) {
      fetch(URIPost+URIExtension, {
          method: 'POST',
          credentials: 'same-origin',
          withCredentials: true,
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify(datos)
      })
        .then((response) => response.json())
        .then((json) => {
          // Data successfully inserted
          if (json.success) {
            setAlert(json.message);
            refreshTable();
          }
          // Else error
          else {
            setAlert(json.message);
          }     
        })
        .catch((error) => {
          // console.log(error);
        })
    }
  } 

  function refreshTable () {
    fetch(URI+URIExtension)
      .then((response) => response.json())
      .then((json) => {
        // Refresh data successfully
        if (json.success) {
          setData(json.data);
        }
        // Else error / No data available
        else {
          setAlert(json.message);
          setData([]);
        }
      })
      .catch((error) => {
        // console.log(error)l
      })
  }

  function search(rows) {
    return rows.filter((row) => 
      searchColumns.some(
        (column) => 
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submitData()
  }

  return (
    <>
      <div className="mb-auto h-full">        
        <div className="flex justify-center flex-wrap mt-4">   
          <div className="w-full px-4">   
            <div className="xs:block align-middle items-center justify-center mb-3">
              <div className="my-auto pt-2 float-left mx-auto">
                <input className="border rounded-xl py-2 px-3 mr-4 text-gray-700" type="text" value={q} onChange={(e) => setQ(e.target.value)} />            
              </div>
              {columns &&
                columns.map((column) => (           
                  <div key={column} className="inline-flex">
                    <div className="rounded-t-xl overflow-hidden p-2">
                      <div className="p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md">
                        <label className="flex items-center space-x-3">
                          <input
                            className="form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
                            type="checkbox"
                            checked={searchColumns.includes(column)}
                            onChange={(e) => {
                              const checked = searchColumns.includes(column);
                              setSearchColumns((prev) => 
                                checked
                                ? prev.filter((sc) => sc !== column)
                                : [...prev, column]
                              );
                            }}
                          />
                          <span className="text-gray-900 font-normal space-x-3 font-sans capitalize text-sm">{column}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))
              }   
              <div className="xs:float-right pt-2 xxl:float-right mx-auto">
                <button 
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={() => modal.current.open()}
                >
                  Añadir nuevo {documentTitle}
                </button>
              </div>     
            </div>                    
            <Modal ref={modal}>
              <form 
                onSubmit={handleSubmit}
                className="text-gray-300 font-bold mb-1 md:mb-0 pr-4"
              >
                <div
                  className="text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-align-center"
                >
                  Añadir nuevo {documentTitle}
                </div>
                <div
                className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"nombre" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Nombre del {documentTitle}
                  </label>
                  <input 
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"nombre" + documentTitle} 
                    name={"nombre" + documentTitle}
                    placeholder={"Nombre del " + documentTitle}
                    onChange={e => setNameData(e.target.value)}
                    value={nameData}
                  >              
                  </input>
                </div>                           
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"email" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Email del {documentTitle}
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"email" + documentTitle}
                    name={"email" + documentTitle}
                    placeholder={"Email del " + documentTitle}
                    onChange={e => setEmailData(e.target.value)}
                    value={emailData}
                  >
                  </input>
                </div> 
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"password" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Contraseña del {documentTitle}
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"password" + documentTitle}
                    name={"password" + documentTitle}
                    placeholder={"Contraseña del " + documentTitle}
                    onChange={e => setPasswordData(e.target.value)}
                    value={passwordData}
                  >
                  </input>
                </div> 
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"direccion" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Dirección del {documentTitle}
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"direccion" + documentTitle}
                    name={"direccion" + documentTitle}
                    placeholder={"Dirección del " + documentTitle}
                    onChange={e => setAddressData(e.target.value)}
                    value={addressData}
                  >
                  </input>
                </div>
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"departamento" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Departamento {documentTitle}
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"departamento" + documentTitle}
                    name={"departamento" + documentTitle}
                    placeholder={"Departamento del " + documentTitle}
                    onChange={e => setDepartamentData(e.target.value)}
                    value={departamentData}
                  >
                  </input>
                </div>  
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"ocupacion" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Ocupación
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"ocupacion" + documentTitle}
                    name={"ocupacion" + documentTitle}
                    placeholder={"Ocupación del " + documentTitle}
                    onChange={e => setJobData(e.target.value)}
                    value={jobData}
                  >
                  </input>
                </div> 
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"comercio" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Comercio
                  </label>
                  <select
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"comercio" + documentTitle}
                    name={"comercio" + documentTitle}
                    onChange={e => setShopUserIdData(e.target.value)}
                    value={shopUserIdData}
                  >
                    <option value="">Seleccionar</option>
                    {dataShop.map((row, index) => (                                           
                      <option key={index} value={row.id}>
                        {row.nombre}
                      </option>                    
                    ))}
                  </select>
                </div>
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"extension" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Extensión
                  </label>
                  <select
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"extension" + documentTitle}
                    name={"extension" + documentTitle}
                    onChange={e => setExtensionUserIdData(e.target.value)}
                    value={extensionUserIdData}
                  >
                    <option value="">Seleccionar</option>
                    {dataExtension.map((row, index) => (                                           
                      <option key={index} value={row.id}>
                        {row.nombre}
                      </option>                    
                    ))}
                  </select>
                </div>
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"puesto" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Puesto
                  </label>
                  <select
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"puesto" + documentTitle}
                    name={"puesto" + documentTitle}
                    onChange={e => setTaskRawData(e.target.value)}
                    value={taskRawData}
                  >
                    <option value="">Seleccionar</option>
                    <option value="1">Propietario</option>
                    <option value="2">Administrador</option>
                    <option value="3">Proveedor</option>
                    <option value="4">Empleado</option>
                  </select>
                </div> 
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"telefono" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Teléfono
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"                  
                    type="text"
                    id={"telefono" + documentTitle}
                    name={"telefono" + documentTitle}
                    placeholder={"Número de télefono del " + documentTitle}
                    onChange={e => setPhoneNumerData(e.target.value)}
                    value={phoneNumerData}
                  >
                  </input>
                </div>
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"descripcion" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Descripción
                  </label>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"descripcion" + documentTitle}
                    name={"descripcion" + documentTitle}
                    placeholder={"Descripción del " + documentTitle}
                    onChange={e => setDescriptionData(e.target.value)}
                    value={descriptionData}
                  >
                  </input>
                </div>
                <div
                  className="my-4 flex items-center justify-center max-w-full"
                >
                  <label 
                    htmlFor={"activo" + documentTitle}
                    className="text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block"
                  >
                    Estado del {documentTitle}
                  </label>
                  <select
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block"
                    type="text"
                    id={"activo" + documentTitle}
                    name={"activo" + documentTitle}
                    onChange={e => setActiveDataRaw(e.target.value)}
                    value={activeDataRaw}
                  >
                    <option value="">Seleccionar</option>
                    <option value="1">Activo</option>
                    <option value="2">No activo</option>
                  </select>
                </div>
                <div 
                  className="float-right"
                >
                  <button 
                    type="button"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 mr-4"
                    onClick={() => modal.current.close()}
                  >
                    Cerrar ventana
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 ml-4"
                  >
                    Agregar nuevo
                  </button>
                </div>              
              </form>
            </Modal>
            {loading ?
              <Loader type="MutatingDots" color="#9fd8df" secondaryColor="#4b778d" height={100} width={100} className="loader-middle" />
            :
              <CardUser data={search(data)} dataShop={dataShop} dataExtension={dataExtension} info={documentTitle} URIExtension={URIExtension} recibir={refreshOnSubmit} />
            }            
          </div>            
        </div>
      </div>
    </>
  );
}
