import React, { useState, useEffect, useRef } from 'react';
// Components
import ImageUploader from 'react-images-upload';
import CardStock from '../../components/Cards/CardStock.js';
import Modal from '../../components/Modals/Modal.js';
// Loader
import Loader from 'react-loader-spinner';
// Alertify
import alertify from 'alertifyjs';

export default function Productos({ ...props }) {
  function refreshOnSubmit() {
    refreshTable();
  }

  // Loading state
  const [loading, setLoading] = useState(true);
  // Data Map
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [dataExtension, setDataExtension] = useState([]);
  const [dataGroup, setDataGroup] = useState([]);
  const [dataElement, setDataElement] = useState([]);
  const [dataSpec, setDataSpecification] = useState([]);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'barcode',
    'id',
    'codigo',
    'nombre',
    'activo',
    'valor',
    'elemento',
    'extra',
  ]);
  const modal = useRef(null);
  // Data push
  const [barCode, setBarCode] = useState('');
  const [codeProduct, setCodeProduct] = useState('');
  const [nameDataRow, setNameDataRow] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [activeDataRow, setActiveDataRow] = useState(1);
  const [comercioProducto, setComercioProducto] = useState('');
  const [extensionProducto, setExtensionProducto] = useState(1);
  const [grupoProducto, setGrupoProducto] = useState(3);
  const [elementoProducto, setElementoProducto] = useState('');
  const [especificacionProducto, setEspecificacionProducto] = useState('');
  const [extraProducto, setExtraProducto] = useState(2);
  const [productoFoto, setProductoFoto] = useState();
  const [productoCantidad, setProductoCantidad] = useState('');
  const [productoValor, setProductoValor] = useState('');
  // Information about present view
  const documentTitle = 'Producto';
  const rowExtension = 'producto';
  const URIExtensiontPhoto = 'photo';
  const URIExtension = 'stock';
  const URIPost = '/api/post/';
  const URI = '/api/data/';
  // Subir fotos
  const [pictures, setPictures] = useState([]);
  // Alerts
  const [alert, setAlert] = useState('');
  // Munted Component Ref
  const _isMounted = useRef(true); // Initial value __isMounted = true

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert, function () {
      if (modal.current) {
        // modal.current.close()
      }
      alertify.success('Muy bien!');
    });
    setAlert('');
  }

  const onDrop = (picture) => {
    setPictures([...pictures, picture]);
    setProductoFoto(picture);
  };

  useEffect(() => {
    if (_isMounted.current) {
      fetch(URI + URIExtension, { method: 'GET' })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            var sortedData = json.data.sort(function (a, b) {
              return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
            });
            sortedData.reverse();
            setData(sortedData);
            setDataStore(json.dataStore);
            setDataExtension(json.dataExtension);
            setDataGroup(json.dataGroup);
            setDataElement(sortByName(json.dataElement));
            setDataSpecification(sortByName(json.dataSpecification));
            setDataSearch(json.dataSearch);
            setLoading(false);
          } else {
            setAlert(json.message);
            setData([]);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
    return () => {
      _isMounted.current = false;
    };
  }, []);

  function submitData() {
    const fd = new FormData();

    if (typeof productoFoto !== 'undefined') {
      productoFoto.forEach((file) => {
        fd.append('image', file);
      });
    }

    if (
      typeof barCode === 'undefined' ||
      !codeProduct ||
      typeof productoFoto === 'undefined' ||
      !nameDataRow ||
      !activeDataRow ||
      !comercioProducto ||
      !extensionProducto ||
      !grupoProducto ||
      !elementoProducto ||
      !especificacionProducto ||
      !extraProducto ||
      !productoCantidad ||
      !productoValor
    ) {
      setAlert(
        'Por favor introduzca el nombre del producto e ingrese todas sus opciones'
      );
    } else if (
      typeof codeProduct !== 'undefined' &&
      typeof productoFoto !== 'undefined' &&
      typeof nameDataRow !== 'undefined' &&
      typeof activeDataRow !== 'undefined' &&
      typeof comercioProducto !== 'undefined' &&
      typeof extensionProducto !== 'undefined' &&
      typeof grupoProducto !== 'undefined' &&
      typeof elementoProducto !== 'undefined' &&
      typeof especificacionProducto !== 'undefined' &&
      typeof extraProducto !== 'undefined' &&
      typeof productoCantidad !== 'undefined' &&
      typeof productoValor !== 'undefined'
    ) {
      fetch(URIPost + URIExtensiontPhoto, {
        method: 'POST',
        body: fd,
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.success) {
            postData(json.photoPath);
          } else {
            setAlert(json.message);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }

    const postData = (photoPath) => {
      var datos = [];

      datos.push({
        barCode,
        codeProduct,
        nameDataRow,
        descripcion,
        activeDataRow,
        comercioProducto,
        extensionProducto,
        grupoProducto,
        elementoProducto,
        especificacionProducto,
        extraProducto,
        productoCantidad,
        productoValor,
        photoPath,
      });

      fetch(URIPost + URIExtension, {
        method: 'POST',
        credentials: 'same-origin',
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(datos),
      })
        .then((response) => response.json())
        .then((json) => {
          // Data successfully inserted
          if (json.success) {
            setAlert(json.message);
            refreshTable();
            clearForm();
          }
          // Else error
          else {
            setAlert(json.message);
            // setData([]);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    };
  }

  function refreshTable() {
    fetch(URI + URIExtension, {
      credentials: 'same-origin',
      withCredentials: true,
    })
      .then((response) => response.json())
      .then((json) => {
        // Data successfully fetched
        if (json.success) {
          var sortedData = json.data.sort(function (a, b) {
            return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
          });
          sortedData.reverse();
          setData(sortedData);
          setLoading(false);
        }
        // Else error / no data to fetch
        else {
          setAlert(json.message);
          setData([]);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  function sortByName(data) {
    return data.sort(function (a, b) {
      return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
    });
  }

  function clearForm() {
    setBarCode('');
    setCodeProduct('');
    setNameDataRow('');
    setDescripcion('');
    setActiveDataRow(1);
    setComercioProducto('');
    setExtensionProducto(1);
    setGrupoProducto(3);
    setElementoProducto('');
    setEspecificacionProducto('');
    setExtraProducto(2);
    setProductoCantidad('');
    setProductoValor('');
  }

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    submitData();
  };

  return (
    <>
      <div className='mb-auto h-full'>
        <div className='flex justify-center flex-wrap mt-4'>
          <div className='w-full px-4'>
            <div className='xs:block align-middle items-center justify-center mb-3'>
              <div className='my-auto pt-2 float-left mx-auto'>
                <input
                  className='border rounded-xl py-2 px-3 mr-4 text-gray-700'
                  type='text'
                  value={q}
                  onChange={(e) => {
                    setQ(e.target.value);
                    window.history.replaceState(null, '', '?page=1');
                  }}
                />
              </div>
              {columns &&
                columns.map((column) => (
                  <div key={column} className='inline-flex'>
                    <div className='rounded-t-xl overflow-hidden p-2'>
                      <div className='p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md'>
                        <label className='flex items-center space-x-3 user-select'>
                          <input
                            className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none'
                            type='checkbox'
                            checked={searchColumns.includes(column)}
                            onChange={(e) => {
                              const checked = searchColumns.includes(column);
                              setSearchColumns((prev) =>
                                checked
                                  ? prev.filter((sc) => sc !== column)
                                  : [...prev, column]
                              );
                            }}
                            disabled={column === 'barcode' ? true : false}
                          />
                          <span className='text-gray-900 font-normal space-x-3 font-sans capitalize text-sm'>
                            {column}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              <div className='xs:float-right pt-2 xxl:float-right mx-auto'>
                <button
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
                  onClick={() => modal.current.open()}>
                  Añadir nuevo {documentTitle}
                </button>
              </div>
            </div>
            <Modal ref={modal}>
              <form
                onSubmit={handleSubmit}
                className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 max-w-580-px'>
                <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-align-center'>
                  Añadir nuevo {documentTitle}
                </div>
                {/* Campo: código de barras del producto */}
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'barcode' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Código de barras
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id={'barcode' + documentTitle}
                    name={'barcode' + documentTitle}
                    placeholder='Código de barras'
                    onChange={(e) => setBarCode(e.target.value)}
                    value={barCode}></input>
                </div>
                {/* Termina campo */}
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'codigo' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Código del {documentTitle}
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id={'codigo' + documentTitle}
                    name={'codigo' + documentTitle}
                    placeholder={'Código de ' + documentTitle}
                    onChange={(e) => setCodeProduct(e.target.value)}
                    value={codeProduct}></input>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'nombre' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Nombre del {documentTitle}
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id={'nombre' + documentTitle}
                    name={'nombre' + documentTitle}
                    placeholder={'Nombre de ' + documentTitle}
                    onChange={(e) => setNameDataRow(e.target.value)}
                    value={nameDataRow}></input>
                </div>
                <div className='my-4 flex items-baseline justify-center max-w-full'>
                  <label
                    htmlFor={'foto' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Foto del {documentTitle} a ingresar
                  </label>
                  <ImageUploader
                    {...props}
                    key='image-uploader'
                    className='max-w-1/2 w-1/2'
                    labelClass='w-full max-w-full text-center'
                    buttonClassName='w-11/12'
                    name={'foto' + documentTitle}
                    withIcon={true}
                    singleImage={false}
                    withPreview={true}
                    //label="&nbsp; Tamaño máximo de archivo: 5 MB Aceptado: jpg | jpeg | png"
                    label='Tamaño máximo de archivo: 5 MB. Aceptado: jpg | jpeg | png'
                    buttonText='Selecciona una imagen'
                    onChange={onDrop}
                    imgExtension={['.jpg', '.jpeg', '.png']}
                    maxFileSize={5242880}
                  />
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'descripcion' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Descripción del {documentTitle}
                  </label>
                  <textarea
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id={'descripcion' + documentTitle}
                    name={'descripcion' + documentTitle}
                    placeholder={'Descripción de ' + documentTitle}
                    onChange={(e) => setDescripcion(e.target.value)}
                    value={descripcion}></textarea>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'cantidad' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Cantidad actual de {documentTitle}
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id={'cantidad' + documentTitle}
                    name={'cantidad' + documentTitle}
                    onChange={(e) => setProductoCantidad(e.target.value)}
                    onKeyPress={(e) => {
                      if (!/[0-9.]/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    value={productoCantidad}></input>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'valor' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Valor de cada {documentTitle}
                  </label>
                  <input
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    type='text'
                    id={'valor' + documentTitle}
                    name={'valor' + documentTitle}
                    onChange={(e) => setProductoValor(e.target.value)}
                    onKeyPress={(event) => {
                      if (!/[0-9.]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    value={productoValor}></input>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'activo' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Estado del {documentTitle}
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    onChange={(e) => setActiveDataRow(e.target.value)}
                    value={activeDataRow}>
                    <option value='1'>Activo</option>
                    <option value='2' defaultValue>
                      No activo
                    </option>
                  </select>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'comercio' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Comercio
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    onChange={(e) => setComercioProducto(e.target.value)}
                    value={comercioProducto}>
                    <option value='' defaultValue>
                      Seleccionar
                    </option>
                    {dataStore.map((row, index) => (
                      <option key={index} value={row.id}>
                        {row.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'extension' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Extensión
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    onChange={(e) => setExtensionProducto(e.target.value)}
                    value={extensionProducto}>
                    {dataExtension.map((row, index) => (
                      <option key={index} value={row.id}>
                        {row.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'grupo' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block float-right'>
                    Grupo
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    id={'grupo' + documentTitle}
                    name={'grupo' + documentTitle}
                    onChange={(e) => setGrupoProducto(e.target.value)}
                    value={grupoProducto}>
                    {dataGroup.map((row, index) => (
                      <option key={index} value={row.id}>
                        {row.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'elemento' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Elemento
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    onChange={(e) => setElementoProducto(e.target.value)}
                    value={elementoProducto}>
                    <option value='' defaultValue>
                      Seleccionar
                    </option>
                    {dataElement.map((row, index) => (
                      <option key={index} value={row.id}>
                        {row.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'especificacion' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    Especificación
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    onChange={(e) => setEspecificacionProducto(e.target.value)}
                    value={especificacionProducto}>
                    <option value='' defaultValue>
                      Seleccionar
                    </option>
                    {dataSpec.map((row, index) => (
                      <option key={index} value={row.id}>
                        {row.nombre}
                      </option>
                    ))}
                  </select>
                </div>
                <div className='my-4 flex items-center justify-center max-w-full'>
                  <label
                    htmlFor={'extra' + documentTitle}
                    className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'>
                    El {documentTitle} es un extra
                  </label>
                  <select
                    className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
                    onChange={(e) => setExtraProducto(e.target.value)}
                    value={extraProducto}>
                    <option value='1'>Si</option>
                    <option value='2'>No</option>
                  </select>
                </div>
                <div className='flex'>
                  <button
                    type='button'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-1/2 w-1/2'
                    onClick={() => modal.current.close()}>
                    Cerrar ventana
                  </button>
                  <button
                    type='submit'
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-1/2 w-1/2'>
                    Agregar nuevo
                  </button>
                </div>
              </form>
            </Modal>
            {loading ? (
              <Loader
                type='MutatingDots'
                color='#9fd8df'
                secondaryColor='#4b778d'
                height={100}
                width={100}
                className='loader-middle'
              />
            ) : (
              <CardStock
                data={search(data)}
                dataSearch={dataSearch}
                dataStore={dataStore}
                dataExtension={dataExtension}
                dataGroup={dataGroup}
                dataElement={dataElement}
                dataSpec={dataSpec}
                info={documentTitle}
                URIExtension={URIExtension}
                rowExtension={rowExtension}
                recibir={refreshOnSubmit}
              />
            )}
          </div>
        </div>
      </div>
    </>
  ); // return
}
