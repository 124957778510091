import React from 'react';
import CardStats from 'components/Cards/CardStats.js';

export default function HeaderStats() {
  return (
    <>
      {/* Header */}
      <div className='relative bg-blue-600 md:pt-32 pb-32 pt-12'>
        <div className='px-4 md:px-10 mx-auto w-full'>
          <div>
            {/* Card stats */}
            <div className='flex flex-wrap'>
              <div className='w-full lg:w-6/12 xl:w-3/12 px-4'>
                <CardStats
                  statSubtitle='Tráfico'
                  statTitle='350,897'
                  statArrow='up'
                  statPercent='3.48'
                  statPercentColor='text-green-500'
                  statDescripiron='Desde el mes pasado'
                  statIconName='far fa-chart-bar'
                  statIconColor='bg-red-500'
                />
              </div>
              <div className='w-full lg:w-6/12 xl:w-3/12 px-4'>
                <CardStats
                  statSubtitle='Proveedores totales'
                  statTitle='2,356'
                  statArrow='down'
                  statPercent='3.48'
                  statPercentColor='text-red-500'
                  statDescripiron='Desde la semana pasada'
                  statIconName='fas fa-chart-pie'
                  statIconColor='bg-orange-500'
                />
              </div>
              <div className='w-full lg:w-6/12 xl:w-3/12 px-4'>
                <CardStats
                  statSubtitle='Ventas totales'
                  statTitle='924'
                  statArrow='down'
                  statPercent='1.10'
                  statPercentColor='text-orange-500'
                  statDescripiron='Desde ayer'
                  statIconName='fas fa-users'
                  statIconColor='bg-pink-500'
                />
              </div>
              <div className='w-full lg:w-6/12 xl:w-3/12 px-4'>
                <CardStats
                  statSubtitle='Rendimiento'
                  statTitle='49,65%'
                  statArrow='up'
                  statPercent='12'
                  statPercentColor='text-green-500'
                  statDescripiron='Desde el mes pasado'
                  statIconName='fas fa-percent'
                  statIconColor='bg-blue-500'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
