import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',

    width: '72.1mm',
    paddingRight: 20,

    textAlign: 'justify',
  },
  wrapper: {
    marginBottom: 8,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  product: {
    textTransform: 'capitalize',
    flex: '0 0 90%',
  },
  qty: {
    textAlign: 'left',
  },
});

function calcPercent(val, percentage) {
  let desc = (val * (percentage / 100)).toFixed(0);
  let value = val - desc;
  if (percentage > 0 && percentage < 100) return value.toFixed(2);
  return value;
}

function capitalize(word) {
  const lower = word.toLowerCase();
  return word.charAt(0).toUpperCase() + lower.slice(1);
}

const ReceiptItems = ({ items, descuento }) => {
  // El descuento viene numerico pero es un porcentaje

  const firstSpan = (item) => {
    if (descuento > 0 || item.cantidad > 1 || item.adiPrice > 0) {
      return `Q${item.valor * item.cantidad}`;
    }
  };

  const secondSpan = (adi) => {
    if (descuento > 0) {
      return `- ${descuento}%`;
    } else if (adi) {
      return `- Desc. ADI`;
    }
  };

  const thirdSpan = (item) => {
    if (descuento > 0) {
      return `= Q${calcPercent(item.cantidad * item.valor, descuento)}`;
    } else if (item.adiPrice > 0) {
      return `= Q${item.cantidad * item.adiPrice}`;
    } else {
      return `Q${item.cantidad * item.valor}`;
    }
  };

  const rows = items.map((item) => (
    <View style={styles.container} key={item.id.toString()}>
      <View style={styles.wrapper}>
        <View style={styles.row}>
          <Text style={styles.product}>
            {capitalize(
              `${item.elemento} ${item.nombre} ${item.especificacion}`
            )}
          </Text>
          <Text style={styles.qty}>{item.cantidad}</Text>
        </View>
        <View style={styles.row}>
          <Text>Valor</Text>
          <Text>
            {firstSpan(item)} {secondSpan(item.adiPrice)} {thirdSpan(item)}
          </Text>
        </View>
      </View>
    </View>
  ));
  return (
    <Fragment>
      {rows}
      <View style={[{ marginTop: -8 }]} />
    </Fragment>
  );
};

export default ReceiptItems;
