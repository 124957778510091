import React, { useContext, useState, useEffect } from 'react';
import { UserContext } from 'UserContext';
import PropTypes from 'prop-types';

import OrderDropdown from './../Dropdowns/OrderDropdown.js';
import PageNumbers from 'components/Table/PageNumbers.jsx';
import { Link } from 'react-router-dom';

export default function CardInvoice({ data, info, URIExtension, ...props }) {
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  const [slice, setSlice] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const color = 'light';

  useEffect(() => {
    let mounted = true;
    var dataPages = [];
    var numberOfPages = Math.ceil(data.length / 10);

    for (var i = 1; i <= numberOfPages; i++) {
      dataPages.push(i);
    }

    if (mounted) {
      setPageNumbers(dataPages);
      defaultPage(dataPages);
    }

    return () => {
      mounted = false;
    };
  }, [data]);

  function refreshOnSubmit() {
    props.recibir();
  }

  function defaultPage(value) {
    var url = window.location.search;
    var currentPage = Math.abs(parseInt(url.replace('?page=', '')));
    var index = currentPage - 1;
    var lastPage = pageNumbers[pageNumbers.length - 1];

    if (typeof value === 'number' && value < 0 && currentPage > 1) {
      changePage((index += value), (currentPage += value));
    } else if (
      typeof value === 'number' &&
      value > 0 &&
      currentPage < lastPage
    ) {
      changePage((index += value), (currentPage += value));
    } else if (typeof value === 'object') {
      if (
        typeof currentPage !== 'undefined' &&
        currentPage <= value[value.length - 1] &&
        currentPage != 0
      ) {
        changePage(index, currentPage);
      } else {
        changePage(0, 1);
      }
    }
  }

  function changePage(index, row) {
    var startIndex = index * 10;
    var endIndex = row * 10;
    setSlice(data.slice([startIndex], [endIndex]));
    window.history.replaceState(null, '', `?page=${endIndex / 10}`);
  }

  const arrowPageChange = (value) => {
    defaultPage(value);
  };

  const handleViewFacturas = (rowDataId) => {
    props.viewFacturas(rowDataId);
  };

  const handleViewRecibos = (rowDataId) => {
    props.viewRecibos(rowDataId);
  };

  return (
    <>
      {!('1,2'.indexOf(userProfile.puesto) !== -1) ? (
        <div className='buttons pb-8'>
          <Link to='/client/pedidos/nuevo'>
            <button className='text-left p-3 rounded-xl order-button-back'>
              Nuevo pedido
            </button>
          </Link>
        </div>
      ) : null}
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 mt-6 shadow-lg rounded ' +
          (color === 'light' ? 'bg-white' : 'bg-blue-900 text-white')
        }>
        <PageNumbers
          info={info}
          color={color}
          pages={pageNumbers}
          changePage={changePage}
          arrowPageChange={arrowPageChange}
        />
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  ID
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Fecha
                </th>
                {'1,2'.indexOf(userProfile.puesto) !== -1 ? (
                  <>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                        (color === 'light'
                          ? 'bg-gray-100 text-gray-600 border-gray-200'
                          : 'bg-blue-800 text-blue-300 border-blue-700')
                      }>
                      Comercio
                    </th>
                  </>
                ) : null}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Autor
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Cliente
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Total
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Estado
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200 '
                      : 'bg-blue-800 text-blue-300 border-blue-700 ')
                  }>
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {slice.map((row, index) => (
                <tr key={index}>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.id}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.fecha}
                  </td>
                  {'1,2'.indexOf(userProfile.puesto) !== -1 ? (
                    <>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.comercio}
                      </td>
                    </>
                  ) : null}
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.autor}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    {row.cliente}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                    Q{row.total}
                  </td>
                  {row.proceso === 'En proceso' ? (
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      <div className='flex items-center w-full'>
                        <span className='mr-2'>50%</span>
                        <div className='relative w-full'>
                          <div className='overflow-hidden h-2 text-xs flex rounded bg-teal-200'>
                            <div
                              style={{ width: '50%' }}
                              className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500'></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      <div className='flex items-center'>
                        <span className='mr-2'>100%</span>
                        <div className='relative w-full'>
                          <div className='overflow-hidden h-2 text-xs flex rounded bg-green-200'>
                            <div
                              style={{ width: '100%' }}
                              className='shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-green-500'></div>
                          </div>
                        </div>
                      </div>
                    </td>
                  )}
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right'>
                    <OrderDropdown
                      rowDataId={row.id}
                      rowDataProcess={row.procesoRaw}
                      info={info}
                      URIExtension={URIExtension}
                      recibir={refreshOnSubmit}
                      viewFacturas={handleViewFacturas}
                      viewRecibos={handleViewRecibos}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardInvoice.defaultProps = {
  color: 'light',
};

CardInvoice.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
};
