import React, { useContext } from 'react';
import { UserContext } from 'UserContext';
import PropTypes from 'prop-types';

export default function CardInventory({
  data,
  dataPhoto,
  info,
  optionsDropdown,
}) {
  const color = 'light';
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  let citrus;

  if (dataPhoto) {
    citrus = dataPhoto.slice(1, 2);
  }

  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (color === 'light' ? 'bg-white' : 'bg-blue-900 text-white')
        }>
        <div className='rounded-t mb-0 px-4 py-3 border-0'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
              <h3
                className={
                  'font-semibold text-lg ' +
                  (color === 'light' ? 'text-gray-800' : 'text-white')
                }>
                {info}
              </h3>
            </div>
          </div>
        </div>
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  ID
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Código
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Nombre
                </th>
                {'1,2'.indexOf(userProfile.puesto) !== -1 ? (
                  <>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                        (color === 'light'
                          ? 'bg-gray-100 text-gray-600 border-gray-200'
                          : 'bg-blue-800 text-blue-300 border-blue-700')
                      }>
                      Comercio
                    </th>
                    <th
                      className={
                        'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                        (color === 'light'
                          ? 'bg-gray-100 text-gray-600 border-gray-200'
                          : 'bg-blue-800 text-blue-300 border-blue-700')
                      }>
                      Extensión
                    </th>
                  </>
                ) : null}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Grupo
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Elemento
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Especificación
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Cantidad
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Valor
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Extra
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Estado
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200 '
                      : 'bg-blue-800 text-blue-300 border-blue-700 ') +
                    (optionsDropdown === false ? 'hidden' : 'block')
                  }>
                  Opciones
                </th>
              </tr>
            </thead>
            <tbody>
              {typeof data != 'undefined' &&
                data != null &&
                data.length != null &&
                data.length > 0 &&
                data.map((row, index) =>
                  row.comercioId === userProfile.comercioId ? (
                    <tr key={index}>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.id}
                      </td>
                      <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.codigo}
                      </td>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center'>
                        <div className='bg-white rounded-full border p-0.5'>
                          <div className='block relative h-12 w-12 text-center whitespace-nowrap'>
                            <div className='inline-block h-full align-middle'></div>
                            {row.foto.indexOf(',') > -1 ? (
                              dataPhoto.length > 1 ? (
                                citrus.map((row, index) => (
                                  <img
                                    src={
                                      process.env.NODE_ENV === 'development'
                                        ? 'http://localhost:3000/api/resources/' +
                                          row
                                        : 'https://malem.group/api/resources/' +
                                          row
                                    }
                                    className='inline-block max-h-12 max-w-12 align-middle'
                                    alt={index}
                                    key={index}
                                  />
                                ))
                              ) : null
                            ) : (
                              <>
                                <img
                                  src={
                                    process.env.NODE_ENV === 'development'
                                      ? 'http://localhost:3000/api/resources/' +
                                        row.foto
                                      : 'https://malem.group/api/resources/' +
                                        row.foto
                                  }
                                  className='inline-block max-h-12 max-w-12 align-middle'
                                  alt={row.nombre}
                                />
                              </>
                            )}
                          </div>
                        </div>
                        <span
                          style={{
                            width: '150px',
                            maxWidth: '150px',
                            whiteSpace: 'pre-wrap',
                            wordWrap: 'break-word',
                          }}
                          className={
                            'ml-3 ' +
                            (color === 'light'
                              ? 'bg-gray-000 text-gray-700 border-gray-200'
                              : 'text-white')
                          }>
                          {row.nombre}
                        </span>
                      </td>
                      {'1,2'.indexOf(userProfile.puesto) !== -1 ? (
                        <>
                          <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                            {row.comercio}
                          </td>
                          <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                            {row.extension}
                          </td>
                        </>
                      ) : null}
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.grupo}
                      </td>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.elemento}
                      </td>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.especificacion}
                      </td>
                      <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.cantidad}
                      </td>
                      <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        Q{row.valor}
                      </td>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.extra === 'Si' ? (
                          <>
                            <i className='fas fa-circle text-green-500 mr-2'></i>
                            {row.extra}
                          </>
                        ) : (
                          <>
                            <i className='fas fa-circle text-red-500 mr-2'></i>
                            {row.extra}
                          </>
                        )}
                      </td>
                      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                        {row.activo === 'Activo' ? (
                          <>
                            <i className='fas fa-circle text-green-500 mr-2'></i>
                            {row.activo}
                          </>
                        ) : (
                          <>
                            <i className='fas fa-circle text-red-500 mr-2'></i>
                            {row.activo}
                          </>
                        )}
                      </td>
                      <td
                        className={
                          'border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right ' +
                          (optionsDropdown === false ? 'hidden' : 'block')
                        }></td>
                    </tr>
                  ) : null
                )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardInventory.defaultProps = {
  color: 'light',
};

CardInventory.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']),
};
