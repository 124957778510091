import React, { useState, useReducer, Fragment, useContext } from 'react';
import { UserContext } from 'UserContext';

import alertify from 'alertifyjs';
import { Link } from 'react-router-dom';

export default function CardItem({ data, buyPhase, affiliates }) {
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  let currentCommerce = userProfile.comercio;

  const color = 'light';
  const [itemsBag, setItemsBag] = useState('');
  const [cardSubmitted, setCardSubmitted] = useState(false);
  const [orderCompleted, setOrderCompleted] = useState(false);
  const [isBusy, setBusy] = useState(true);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [alert, setAlert] = useState('');
  const [addButtonSelected, SetAddButtonSelected] = useState(false);

  const [afiliado, setAfiliado] = useState(null);
  const [client, setClient] = useState('Consumidor Final');
  const [nit, setNit] = useState('CF');
  const [total, setTotal] = useState(0);
  const [subtotal, setSubTotal] = useState(0);
  const [discount, setDiscount] = useState(0);

  const [adiDiscount, setAdiDiscount] = useState(null);
  const [checked, setChecked] = useState(false);

  function myBag(id, valor, disponibilidad) {
    if (Object.keys(itemsBag).length === 0) {
      // if my items bag is empty put items
      var myItemsBag = {
        ['item_' + id]: [
          { id: id, cantidad: 1, valor: valor, disponibilidad: disponibilidad },
        ],
      };
      setItemsBag(myItemsBag);
    } else {
      // else my items bag has value
      if (itemsBag.hasOwnProperty(['item_' + id])) {
        // if my items bag has value delete it
        delete itemsBag[['item_' + id]];
        setItemsBag({ ...itemsBag });
      } else {
        // else add a new one
        setItemsBag({
          ...itemsBag,
          ['item_' + id]: [
            {
              id: id,
              cantidad: 1,
              valor: valor,
              disponibilidad: disponibilidad,
            },
          ],
        });
      }
    }
  }

  function submitItems() {
    const submitCard = () => {
      if (Object.keys(itemsBag).length > 0) {
        setCardSubmitted(true);
        order_update_pattern();
      } else {
        setAlert(
          'Por favor seleccione al menos un producto para generar la orden'
        );
      }
    };
    submitCard();
  }

  function submitOrder() {
    let adiValue;

    if (checked) {
      adiValue = adiDiscount;
    }

    var data = [];

    data.push({
      itemsBag,
      set: 'A',
      afiliado: afiliado,
      cliente: client,
      nit: nit,
      subtotal: subtotal,
      descuento: discount,
      total: total,
      adi: adiValue,
    });

    const createNewOrder = () => {
      return fetch('/api/post/items', {
        method: 'POST',
        credentials: 'same-origin',
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.orderCompleted) {
            setOrderCompleted(json.orderCompleted);
          } else {
            setAlert(json.message);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    createNewOrder();
  }

  const order_update_pattern = () => {
    // Actualizando el valor total del pedido
    var areas2 = [];
    var adiTotal = [];
    for (var i in itemsBag) {
      if (itemsBag[i][0].adi) {
        areas2.push(itemsBag[i][0].adi * itemsBag[i][0].cantidad);
        adiTotal.push(itemsBag[i][0].valor * itemsBag[i][0].cantidad);
      } else {
        areas2.push(itemsBag[i][0].valor * itemsBag[i][0].cantidad);
        adiTotal.push(null);
      }
    }
    // Sumando los valores de los arrays en una suma total
    const sum = areas2.map((v) => v).reduce((sum, current) => sum + current, 0);

    const fixedSum = (Math.round(sum * 100) / 100).toFixed(2);

    const adiSum = adiTotal
      .map((v) => v)
      .reduce((adiSum, current) => adiSum + current, 0);
    const fixedAdiSum = (Math.round(adiSum * 100) / 100).toFixed(2);

    setSubTotal(fixedSum);
    if (fixedAdiSum > 0) setAdiDiscount(fixedAdiSum - fixedSum);

    if (discount) {
      setTotal(getDiscount(discount, fixedSum));
    } else {
      setTotal(fixedSum);
    }
    forceUpdate();
  };

  function getDiscount(disc, val) {
    if (disc > 0 && disc <= 100) {
      var result = ((disc / 100) * val).toFixed(0);
      var newTotal = val - result;
      return newTotal.toFixed(2);
    }
    return val;
  }

  function subtractCounter(id) {
    if (itemsBag['item_' + id][0].cantidad > 1) {
      // Actualizando la cantidad de los items
      itemsBag['item_' + id].forEach((e) => (e.cantidad -= 1));
      setItemsBag(itemsBag);
      order_update_pattern();
    } else {
      // setAlert('Debes de elegir por lo menos 1 producto!');
      delete itemsBag['item_' + id];
      setItemsBag(itemsBag);
      order_update_pattern();
    }
  }

  function sumCounter(id, cantidad) {
    if (itemsBag['item_' + id][0].cantidad < cantidad) {
      // Actualizando la cantidad de los items
      itemsBag['item_' + id].forEach((e) => (e.cantidad += 1));
      setItemsBag(itemsBag);
      order_update_pattern();
    } else {
      setAlert(
        'El producto en el pedido no puede ser mayor que la disponibilidad!'
      );
    }
  }

  const setLoader = () => {
    setTimeout(() => {
      setBusy(false);
    }, 3000);
  };

  const pendingOrdersPage = (e) => {
    e.preventDefault();
    var url = '/client/pedidos/pendientes';
    window.location.replace(url);
  };

  const reloadPage = (e) => {
    e.preventDefault();
    window.location.reload();
  };

  const handleSubmitItems = (e) => {
    e.preventDefault();
    submitItems();
  };

  const handleSubmitOrder = (e) => {
    e.preventDefault();
    submitOrder();
  };

  const handleCardBack = () => {
    setCardSubmitted(false);
    getADIPrice(false);
  };

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert);
    setAlert('');
  }

  function fixPhotoArray(row) {
    return row.split(',')[0];
  }

  function updateTotalValue(e) {
    if (e > 0 && e <= 100) {
      var result = ((e / 100) * subtotal).toFixed(0);
      var newTotal = subtotal - result;
      setTotal(newTotal.toFixed(2));
    }
  }

  function updateMyBagADI(data) {
    if (!data) {
      for (const key in itemsBag) {
        delete itemsBag[key][0].adi;
      }
    } else {
      for (const key in data) {
        const id = data[key].id;
        const value = data[key].value.toFixed(2);
        if (itemsBag.hasOwnProperty(['item_' + id])) {
          let item = itemsBag[['item_' + id]];
          item[0].adi = value;
        }
      }
    }
    order_update_pattern();
  }

  function fetchADI() {
    fetch('/api/pedidos/fetch/ADIPrice', {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(itemsBag),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.ok) {
          updateMyBagADI(res.data);
        } else {
          setAlert(res.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function prepareCheckbox(val) {
    setChecked(val);

    if (val)
      return {
        ok: true,
      };
    return {
      ok: false,
    };
  }

  const setNewDiscount = (e) => {
    setDiscount(e.target.value);
    setTotal(subtotal);
    updateTotalValue(e.target.value);
  };

  const getADIPrice = async (val) => {
    await prepareCheckbox(val)
      .then((res) => {
        if (res.ok) {
          fetchADI();
        } else {
          updateMyBagADI(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Fragment>
      <form
        onSubmit={handleSubmitItems}
        className={
          'card-step-1 ' + (cardSubmitted ? 'hide-card' : 'show-card')
        }>
        <div className='buttons pb-8'>
          <Link to='/client/pedidos/pendientes'>
            <button className='text-left p-3 rounded-xl order-button-back'>
              Pendientes
            </button>
          </Link>
          <button
            type='submit'
            className='float-right p-3 rounded-xl order-button'>
            Generar pedido
          </button>
        </div>
        <div className='flex my-5'>
          <div className='md:min-w-1150-px flex flex-wrap justify-evenly item-flex-container'>
            {data.map((row) => (
              <div key={row.id} className='md:w-5/12 w-full mb-6 px-4'>
                <input
                  key={Math.random()}
                  type='checkbox'
                  id={row.id}
                  name={row.id}
                  onChange={() => myBag(row.id, row.valor, row.cantidad)}
                  defaultChecked={itemsBag.hasOwnProperty(['item_' + row.id])}
                  className='flex relative z-10 float-right clear-both form-checkbox item-checkbox disable-blur'
                />
                <div
                  className={
                    'flex relative flex-col min-w-0 break-words w-full shadow-lg rounded-3xl bg-white border-transparent border z-0 item-card-' +
                    (itemsBag.hasOwnProperty(['item_' + row.id])
                      ? 'selected'
                      : 'no-selected')
                  }>
                  <label className='cursor-pointer item-card' htmlFor={row.id}>
                    <div className='p-4'>
                      <div className='block h-350-px text-center m-auto whitespace-nowrap rounded-3xl border'>
                        <div className='inline-block h-full align-middle'></div>
                        {row.foto.indexOf(',') > -1 ? (
                          <img
                            src={
                              process.env.NODE_ENV === 'development'
                                ? 'http://localhost:3000/api/resources/' +
                                  fixPhotoArray(row.foto)
                                : 'https://malem.group/api/resources/' +
                                  fixPhotoArray(row.foto)
                            }
                            className='product-image max-h-80 inline-block align-middle'
                            alt={row.id}
                          />
                        ) : (
                          <img
                            src={
                              process.env.NODE_ENV === 'development'
                                ? 'http://localhost:3000/api/resources/' +
                                  row.foto
                                : 'https://malem.group/api/resources/' +
                                  row.foto
                            }
                            className='product-image max-h-80 inline-block align-middle'
                            alt={row.id}
                          />
                        )}
                      </div>
                      <div className='codex-row'>
                        <ul>
                          <li>id: {row.id}</li>
                          <li>Código: {row.codigo}</li>
                        </ul>
                      </div>
                      <div className='relative w-full max-w-full'>
                        <h1 className='product-name uppercase text-2xl font-semibold'>
                          <span className='product-name-child'>
                            {row.nombre}
                          </span>
                        </h1>
                      </div>
                    </div>
                    <div>
                      <h6 className='text-center'>
                        <span className='price-tag'>Q{row.valor}</span>
                      </h6>
                    </div>
                    <div className='rounded-t mb-0 px-4 py-3 bg-transparent'>
                      <div className='relative w-full max-w-full'>
                        <div className='product-description flex justify-between'>
                          <div className='text-left'>
                            <h5 className='item-tag px-2 m-1 text-center'>
                              <span className='block relative'>
                                {row.grupo}
                              </span>
                            </h5>
                            <h5 className='item-tag px-2 m-1 text-center'>
                              <span className='block relative'>
                                {row.elemento}
                              </span>
                            </h5>
                            <h5 className='item-tag px-2 m-1 text-center'>
                              <span className='block relative'>
                                {row.especificacion}
                              </span>
                            </h5>
                          </div>
                          <div className='text-right justify-end'>
                            <h5 className='item-tag px-2 m-1 text-center'>
                              <span className='block relative'>
                                {row.cantidad === 1
                                  ? row.cantidad + ' Unidad'
                                  : row.cantidad + ' Unidades'}
                              </span>
                            </h5>
                            <h5 className='item-tag px-2 m-1 text-center'>
                              <span className='block relative'>
                                Extra:{' '}
                                {row.extra === 'Si' ? (
                                  <i className='fas fa-circle text-green-500 mr-1' />
                                ) : (
                                  <i className='fas fa-circle text-red-500 mr-1' />
                                )}
                                {row.extra}
                              </span>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className='buttons pb-8'>
          <Link to='/client/pedidos/pendientes'>
            <button className='text-left p-3 rounded-xl order-button-back'>
              Pendientes
            </button>
          </Link>
          <button
            type='submit'
            className='float-right p-3 rounded-xl order-button'>
            Generar pedido
          </button>
        </div>
      </form>
      <form
        onSubmit={handleSubmitOrder}
        className={
          'card-step-2 ' +
          (cardSubmitted === true && orderCompleted === false
            ? 'show-card'
            : 'hide-card')
        }>
        <div className='buttons pb-8'>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleCardBack();
            }}
            className='text-left p-3 rounded-xl order-button-back'>
            Regresar
          </button>
          <button
            type='submit'
            className='float-right p-3 rounded-xl order-button'>
            Generar pedido
          </button>
        </div>
        <div className='block w-full overflow-x-auto shadow-sm mt-8 mb-16'>
          <table className='items-center w-full bg-white border border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  ID
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Código
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Nombre
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200 '
                      : 'bg-blue-800 text-blue-300 border-blue-700 ')
                  }>
                  Cantidad
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Disponibilidad
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Grupo
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Elemento
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Especificación
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Valor
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-gray-100 text-gray-600 border-gray-200'
                      : 'bg-blue-800 text-blue-300 border-blue-700')
                  }>
                  Extra
                </th>
              </tr>
            </thead>
            <tbody>
              {buyPhase.map((row, index) =>
                itemsBag.hasOwnProperty(['item_' + row.id]) ? (
                  <tr key={index}>
                    <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      {row.id}
                    </td>
                    <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      {row.codigo}
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-left flex items-center'>
                      <div className='bg-white rounded-full border p-0.5'>
                        <div className='block relative h-12 w-12 text-center whitespace-nowrap'>
                          <div className='inline-block h-full align-middle'></div>
                          {row.foto.indexOf(',') > -1 ? (
                            <img
                              src={
                                process.env.NODE_ENV === 'development'
                                  ? 'http://localhost:3000/api/resources/' +
                                    fixPhotoArray(row.foto)
                                  : 'https://malem.group/api/resources/' +
                                    fixPhotoArray(row.foto)
                              }
                              className='rounded-3xl inline-block max-h-12 max-w-12 align-middle'
                              alt={row.id}
                            />
                          ) : (
                            <img
                              src={
                                process.env.NODE_ENV === 'development'
                                  ? 'http://localhost:3000/api/resources/' +
                                    row.foto
                                  : 'https://malem.group/api/resources/' +
                                    row.foto
                              }
                              className='rounded-3xl inline-block max-h-12 max-w-12 align-middle'
                              alt={row.id}
                            />
                          )}
                        </div>
                      </div>
                      <span
                        style={{
                          maxWidth: '150px',
                          whiteSpace: 'pre-wrap',
                          wordWrap: 'break-word',
                        }}
                        className={
                          'ml-3 ' +
                          (color === 'light'
                            ? 'bg-gray-000 text-gray-700 border-gray-200'
                            : 'text-white')
                        }>
                        {row.nombre}
                      </span>
                    </td>
                    <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          subtractCounter(row.id);
                        }}
                        type='button'
                        className='fas fa-minus quantity-button subtract'
                      />
                      <span
                        className={
                          'contador-item user-select' +
                          (addButtonSelected ? ' add' : '')
                        }>
                        {itemsBag['item_' + row.id][0].cantidad}
                      </span>
                      <button
                        onMouseOver={(e) => {
                          SetAddButtonSelected(true);
                        }}
                        onMouseLeave={(e) => {
                          SetAddButtonSelected(false);
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          sumCounter(row.id, row.cantidad);
                        }}
                        type='button'
                        className='fas fa-plus quantity-button add'
                      />
                    </td>
                    <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      {row.cantidad === 1
                        ? row.cantidad + ' Unidad'
                        : row.cantidad + ' Unidades'}
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      {row.grupo}
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      {row.elemento}
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      {row.especificacion}
                    </td>
                    <td className='border-t-0 px-6 text-center align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      {itemsBag['item_' + row.id][0].adi != null
                        ? 'Q' + itemsBag['item_' + row.id][0].adi
                        : 'Q' + row.valor}
                    </td>
                    <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200'>
                      {row.extra === 'Si' ? (
                        <>
                          <i className='fas fa-circle text-green-500 mr-2'></i>
                          {row.extra}
                        </>
                      ) : (
                        <>
                          <i className='fas fa-circle text-red-500 mr-2'></i>
                          {row.extra}
                        </>
                      )}
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
            <thead className='border-t'>
              <tr>
                <th colSpan='3' className='text-left p-4 pr-8'>
                  <label htmlFor='cliente_nombre' className='client_label'>
                    <i className='fas fa-id-card'></i>
                    Cliente
                  </label>
                  <input
                    id='cliente_nombre'
                    className='client_input'
                    defaultValue={client}
                    onChange={(e) => setClient(e.target.value)}
                  />
                </th>
                {currentCommerce !== `B'alam` && (
                  <Fragment>
                    <th colSpan='2' className='text-left p-4'>
                      <label htmlFor='afiliado_select'>
                        <i className='fas fa-users' />
                        Afiliado
                      </label>
                      <select
                        id='afiliado_select'
                        name='afiliado_select'
                        className='ml-2 w-28 text-center border rounded-md align-middle'
                        defaultValue='null'
                        onChange={(e) => setAfiliado(e.target.value)}>
                        <option value='null' disabled>
                          Seleccionar
                        </option>
                        {affiliates.map((row, index) => (
                          <option key={index} value={row.codigo}>
                            {row.nombre}
                          </option>
                        ))}
                      </select>
                    </th>

                    <th colSpan='2' className='text-left p-4 pr-8'>
                      <div className='flex flex-row items-center justify-evenly'>
                        <label
                          htmlFor='precio_adi'
                          className='client_label cursor-pointer'>
                          Precio ADI
                        </label>
                        <input
                          type='checkbox'
                          id='precio_adi'
                          className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none cursor-pointer'
                          checked={checked}
                          onChange={() => getADIPrice(!checked)}
                        />
                      </div>
                    </th>
                  </Fragment>
                )}
                <th
                  colSpan={currentCommerce !== `B'alam` ? '1' : '5'}
                  className='text-right'>
                  <label htmlFor='cliente_descuento' className='client_label'>
                    <i className='fas fa-percentage'></i>
                    Descuento
                  </label>
                </th>
                <th colSpan='2' className='text-center'>
                  <i className='fas fa-money-check-alt'></i>
                  Total
                </th>
              </tr>
            </thead>
            <tbody className='border-t'>
              <tr>
                <th
                  colSpan='3'
                  className='text-left p-4'
                  style={{ paddingLeft: '4.2rem' }}>
                  <label htmlFor='cliente_nit' className='client_label'>
                    NIT
                  </label>
                  <input
                    id='cliente_nit'
                    className='client_input'
                    defaultValue={nit}
                    onChange={(e) => setNit(e.target.value)}
                    required
                  />
                </th>
                <th colSpan='5' className='text-right'>
                  <input
                    type='number'
                    id='cliente_descuento'
                    className='client_input'
                    style={{ maxWidth: '6rem' }}
                    defaultValue={0}
                    onChange={(e) => setNewDiscount(e)}
                    required
                  />
                </th>
                <th colSpan='2' className='text-center'>
                  <i className='fas fa-comments-dollar'></i>
                  <span className='border-b-5 border-gray-600 border-double'>
                    Q{total}
                  </span>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='buttons pb-8'>
          <button
            onClick={(e) => {
              e.preventDefault();
              handleCardBack();
            }}
            className='text-left inline p-3 rounded-xl order-button-back'>
            Regresar
          </button>
          <button
            type='submit'
            className='float-right inline p-3 rounded-xl order-button'>
            Generar pedido
          </button>
        </div>
      </form>
      <div className={'order-completed-' + (orderCompleted ? 'show' : 'hide')}>
        {orderCompleted ? (
          <div className='mt-32 ml-8 max-w-md'>
            <div
              className={
                'order-completed-loader circle-loader ' +
                (isBusy ? '' : 'load-complete')
              }>
              <div
                className='checkmark draw'
                style={{ display: isBusy ? 'none' : 'block' }}></div>
            </div>
            {isBusy ? (
              setLoader()
            ) : (
              <>
                <span className='successfully-span'>
                  Pedido realizado con éxito!
                </span>
                <button
                  type='button'
                  onClick={(e) => reloadPage(e)}
                  className='successfully-button'>
                  Realizar otro pedido
                </button>
                <button
                  type='button'
                  onClick={(e) => pendingOrdersPage(e)}
                  className='successfully-button-pending-orders'>
                  Ver pedidos pendientes
                </button>
              </>
            )}
          </div>
        ) : (
          ''
        )}
      </div>
    </Fragment>
  );
}
