import React from 'react';
import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';
import InvoiceTitle from './Utils/Invoices/InvoiceTitle';
import InvoiceHeader from './Utils/Invoices/InvoiceHeader';
import InvoiceNo from './Utils/Invoices/InvoiceNo';
import InvoiceFooter from './Utils/Invoices/InvoiceFooter';
import InvoiceItemsTable from './Utils/Invoices/InvoiceItemsTable';
import InvoiceDate from './Utils/Invoices/InvoiceDate';
import InvoiceThankYouMsg from './Utils/Invoices/InvoiceThankYouMsg';
import Hypermarket from 'fonts/Hypermarket W00 Regular.ttf';

Font.register({
  family: 'Hypermarket',
  format: 'truetype',
  src: Hypermarket,
});

Font.register({
  family: 'Dancing Script',
  src: 'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTeB9ptDqpw.ttf',
});

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 0,
    marginTop: 0,
    paddingLeft: 0,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: 'column',
    width: '72.1mm',
  },
  font: {
    fontSize: 11,
    color: 'black',
    letterSpacing: 0,
    textAlign: 'center',
    fontFamily: 'Hypermarket',
    textTransform: 'uppercase',
  },
});

const Invoice = ({ invoice }) => (
  <Document>
    {/* 80(72.1) x 297 mm */}
    <Page size={['72.1mm', '297mm']} style={[styles.page, styles.font]}>
      <InvoiceTitle title={invoice.comercio} />
      <InvoiceHeader
        title={invoice.comercio}
        extension={invoice.extension}
        nit={invoice.nit}
      />
      <InvoiceNo invoice={invoice} />
      <InvoiceItemsTable invoice={invoice} />
      <InvoiceDate invoice={invoice} />
      <InvoiceFooter />
      <InvoiceThankYouMsg comercio={invoice.comercio} />
    </Page>
  </Document>
);

export default Invoice;
