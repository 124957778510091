import React from 'react';
import {Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
   
    bottomBorder:{
        borderBottom: '1.5',
        borderStyle: 'dashed',
        marginTop: 6,
        marginRight: 10,
        marginLeft: 10,
    },
  });

  const SeparatorBottom = () => (
    <Text style={styles.bottomBorder}/>
  );
  
  export default SeparatorBottom