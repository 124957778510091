import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import balamLogo from 'assets/img/Balam/Jaguar Transparente.png';
import SeparatorBottom from '../SeparatorBottom';
import vianneyLogo from 'assets/img/Vianney/Vianney.png';

const styles = StyleSheet.create({
  balamContainer: {
    textAlign: 'center',
    width: '72.1mm',
    marginBottom: 24,
  },
  image: {
    marginVertical: 10,
    marginHorizontal: 48,
    textAlign: 'center',
  },
  comercio: {
    fontFamily: 'Dancing Script',
    fontSize: 30,
    textTransform: 'initial',
  },
  vianneyContainer: {
    textAlign: 'center',
    width: '72.1mm',
    marginBottom: 18,
    marginTop: 18,
  },
  vianneyLogo: {
    marginVertical: 3.5,
    marginHorizontal: 65,
    marginBottom: -3,
  },
  vianneyText: {
    fontFamily: 'Roboto',
    fontSize: 30,
    textTransform: 'initial',
    textAlign: 'center',
    lineHeight: 1,
    margin: '0',
    width: '72.1mm',
  },
});

const InvoiceTitle = ({ title }) => (
  <>
    {title === "B'alam" ? (
      <View style={styles.balamContainer}>
        <Image style={styles.image} src={balamLogo} />
        <Text style={styles.comercio}>{title}</Text>
        <SeparatorBottom />
        <SeparatorBottom />
      </View>
    ) : (
      <View style={styles.vianneyContainer}>
        <SeparatorBottom />
        <Text style={styles.vianneyText}>{title}</Text>
        <SeparatorBottom />
        <Image style={styles.vianneyLogo} src={vianneyLogo} />
        <SeparatorBottom />
      </View>
    )}
  </>
);

export default InvoiceTitle;
