import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  invoiceDateContainer: {
    marginTop: 0,
    marginBottom: 8,
    textAlign: 'center',
    width: '72.1mm',
  },
});

var date = new Date().toLocaleString();

const InvoiceNo = ({ invoice }) => (
  <Fragment>
    <View style={styles.invoiceDateContainer}>
      <Text>Fecha: {date}</Text>
      <Text>Encargado: {invoice.autor}</Text>
    </View>
  </Fragment>
);

export default InvoiceNo;
