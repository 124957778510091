import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import ReceiptEmojis from './ReceiptEmojis';
import ReceiptN3k3 from './ReceiptN3k3';

const styles = StyleSheet.create({
  container: {
    width: '72.1mm',
    textAlign: 'center',
  },
});

const ReceiptFooter = ({ receipt }) => (
  <View style={styles.container}>
    <ReceiptEmojis comercio={receipt.comercio} />
    <ReceiptN3k3 />
  </View>
);

export default ReceiptFooter;
