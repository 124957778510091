import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useReducer,
  Fragment,
} from 'react';
import Loader from 'react-loader-spinner';
import alertify from 'alertifyjs';
import { Link } from 'react-router-dom';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { isMobile } from 'react-device-detect';

import CardInvoice from '../../components/Cards/CardInvoice.js';
import Invoice from '../../components/Reports/Invoice';
import Receipt from '../../components/Reports/Receipt';

export default function Facturas() {
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'id',
    'fecha',
    'autor',
    'cliente',
    'total',
  ]);
  const documentTitle = 'Pedidos';
  const URIExtension = 'pedidos';
  const URI = '/api/data/';
  const [alert, setAlert] = useState('');
  const [loading, setLoading] = useState(true);
  const [viewFacturasPDF, setViewFacturasPDF] = useState(false);
  const [viewRecibosPDF, setViewRecibosPDF] = useState(false);
  const [invoiceData, setInvoiceData] = useState([]);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    let _isMounted = true;
    if (_isMounted) {
      fetch('/api/facturas/fetch', { method: 'GET' })
        .then((response) => response.json())
        .then((json) => {
          // Data successfully fetched
          if (json.success) {
            var sortedData = json.data.sort(function (a, b) {
              return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
            });
            sortedData.reverse();
            setData(sortedData);
            setDataSearch(json.dataSearch);
            setLoading(false);
          }
          // Else error / No data available
          else {
            setAlert(json.message);
            setData([]);
          }
        })
        .catch((error) => {
          // console.log(error)
        });
    }
    return () => {
      _isMounted = false;
    };
  }, []);

  function refreshTable() {
    fetch('/api/facturas/fetch', { method: 'GET' })
      .then((response) => response.json())
      .then((json) => {
        if (json.message) {
          setAlert(json.message);
          setData([]);
          setDataSearch([]);
          setLoading(true);
        } else {
          var sortedData = json.data.sort(function (a, b) {
            return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
          });
          sortedData.reverse();
          setData(sortedData);
          setDataSearch(json.dataSearch);
        }
      })
      .catch((error) => {
        // console.log(error)
        // setMensajeData(error)
      });
  }

  const completeOrder = () => {
    var datos = [];
    let id = invoiceData.id;

    datos.push({
      idData: id,
      invoiceProcess: 2,
    });

    if (id) {
      return fetch('/api/update/pedidos', {
        method: 'PUT',
        credentials: 'same-origin',
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(datos),
      })
        .then((response) => response.json())
        .then((result) => {
          refreshOnSubmit();
          setAlert(result.message);
          if (result.success === true) {
            setViewOrderPDF(false);
            setInvoiceData([]);
          }
        })
        .catch((error) => {});
    } else {
      setAlert(
        'Por favor no deje casillas en blanco antes de guardar los cambios realizados'
      );
    }
  };

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  async function viewOrder(id) {
    var datos = [];

    datos.push({
      id: id,
    });

    return await fetch('/api/pedidos/fetch/id', {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos),
    })
      .then((res) => res.json())
      .catch((err) => {
        console.error(err);
      });
  }

  const refreshOnSubmit = () => {
    refreshTable();
  };

  const handleViewFacturas = (value) => {
    viewOrder(value).then((res) => {
      setViewFacturasPDF(true);
      setInvoiceData(res.data);
      forceUpdate();
      if (!res.success) {
        setAlert(res.message);
        setViewFacturasPDF(false);
        setInvoiceData([]);
      }
    });
  };

  const handleViewRecibos = (value) => {
    viewOrder(value).then((res) => {
      setViewRecibosPDF(true);
      setInvoiceData(res.data);
      forceUpdate();
      if (!res.success) {
        setAlert(res.message);
        setViewRecibosPDF(false);
        setInvoiceData([]);
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    completeOrder();
  };

  const handleCardBack = () => {
    setViewFacturasPDF(false);
    setViewRecibosPDF(false);
    setInvoiceData([]);
    forceUpdate();
  };

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert);
    setAlert('');
  }

  return (
    <>
      <div className='mb-auto h-full'>
        <div className='flex justify-center flex-wrap mt-4'>
          <div className='w-full px-4'>
            {Object.keys(invoiceData).length > 0 ? (
              <div className='block'>
                <div className='buttons pb-8'>
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        handleCardBack();
                      }}
                      className='text-left p-3 rounded-xl order-button-back'>
                      Regresar
                    </button>
                    <button
                      type='submit'
                      className='float-right p-3 rounded-xl order-button'>
                      Completar pedido
                    </button>
                  </form>
                </div>
                {viewFacturasPDF && (
                  <Fragment>
                    {isMobile ? (
                      <PDFDownloadLink
                        document={<Invoice invoice={invoiceData} />}>
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Loader
                              type='MutatingDots'
                              color='#9fd8df'
                              secondaryColor='#4b778d'
                              height={100}
                              width={100}
                              className='loader-middle'
                            />
                          ) : (
                            (openDownloadModal(url),
                            (
                              <button
                                className='bg-red-200 border-red-300 border rounded-md text-center w-64 h-12 m-auto block'
                                onClick={(e) => {
                                  e.preventDefault();
                                  openDownloadModal(url);
                                }}>
                                Generar nuevo PDF
                              </button>
                            ))
                          )
                        }
                      </PDFDownloadLink>
                    ) : (
                      <PDFViewer
                        height='600'
                        className='app md:max-w-1150-px md:min-h-screen-80 relative mx-auto max-w-full w-full'>
                        <Invoice invoice={invoiceData} />
                      </PDFViewer>
                    )}
                  </Fragment>
                )}
                {viewRecibosPDF && (
                  <Fragment>
                    {isMobile ? (
                      <PDFDownloadLink
                        document={<Receipt receipt={invoiceData} />}>
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            <Loader
                              type='MutatingDots'
                              color='#9fd8df'
                              secondaryColor='#4b778d'
                              height={100}
                              width={100}
                              className='loader-middle'
                            />
                          ) : (
                            (openDownloadModal(url),
                            (
                              <button
                                className='bg-red-200 border-red-300 border rounded-md text-center w-64 h-12 m-auto block'
                                onClick={(e) => {
                                  e.preventDefault();
                                  openDownloadModal(url);
                                }}>
                                Generar nuevo PDF
                              </button>
                            ))
                          )
                        }
                      </PDFDownloadLink>
                    ) : (
                      <PDFViewer
                        height='600'
                        className='app md:max-w-1150-px md:min-h-screen-80 relative mx-auto max-w-full w-full'>
                        <Receipt receipt={invoiceData} />
                      </PDFViewer>
                    )}
                  </Fragment>
                )}
              </div>
            ) : loading ? (
              <>
                <div className='buttons pb-8'>
                  <Link to='/client/pedidos/nuevo'>
                    <button className='text-left p-3 rounded-xl order-button-back'>
                      Nuevo pedido
                    </button>
                  </Link>
                </div>
                <Loader
                  type='MutatingDots'
                  color='#9fd8df'
                  secondaryColor='#4b778d'
                  height={100}
                  width={100}
                  className='loader-middle'
                />
              </>
            ) : (
              <>
                <div className='xs:block align-middle items-center justify-center mb-3'>
                  <div className='my-auto pt-2 float-left mx-auto'>
                    <input
                      className='border rounded-xl py-2 px-3 mr-4 text-gray-700'
                      type='text'
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                  </div>
                  {columns &&
                    columns.map((column) => (
                      <div key={column} className='inline-flex'>
                        <div className='rounded-t-xl overflow-hidden p-2'>
                          <div className='p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md'>
                            <label className='flex items-center space-x-3'>
                              <input
                                className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none'
                                type='checkbox'
                                checked={searchColumns.includes(column)}
                                onChange={(e) => {
                                  const checked =
                                    searchColumns.includes(column);
                                  setSearchColumns((prev) =>
                                    checked
                                      ? prev.filter((sc) => sc !== column)
                                      : [...prev, column]
                                  );
                                }}
                              />
                              <span className='text-gray-900 font-normal space-x-3 font-sans capitalize text-sm user-select'>
                                {column}
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <CardInvoice
                  data={search(data)}
                  info={documentTitle}
                  URIExtension={URIExtension}
                  recibir={refreshOnSubmit}
                  viewFacturas={handleViewFacturas}
                  viewRecibos={handleViewRecibos}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  ); // return
}
