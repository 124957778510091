import React from 'react';
import bootstrapstyle from '../../assets/styles/bootstrap.module.css';

export default function CataloguePageNumbers({ pages, ...props }) {
  const arrowPageChange = (value) => {
    props.arrowPageChange(value);
  };

  const changePage = (index, row) => {
    props.changePage(index, row);
  };

  return (
    <nav
      className={`${bootstrapstyle.nav} ${bootstrapstyle['mt-4']} overflow-y-hidden overflow-x-auto`}
      aria-label='Page navigation sample'>
      <ul
        className={`cursor-pointer user-select ${bootstrapstyle.pagination} w-3xl`}>
        <li
          className={
            `${bootstrapstyle['page-item']} ` +
            (window.location.search === `?page=1`
              ? 'cursor-default ' + bootstrapstyle.disabled
              : '')
          }>
          <a
            className={`${bootstrapstyle['page-link']}`}
            onClick={() => {
              arrowPageChange(-1);
            }}>
            Anterior
          </a>
        </li>
        {pages.map((row, index) => (
          <li
            key={index}
            className={
              bootstrapstyle['page-item'] +
              (window.location.search === `?page=${row}`
                ? ' ' + bootstrapstyle.active
                : '')
            }>
            <a
              className={`${bootstrapstyle['page-link']}`}
              onClick={() => {
                changePage(index, row);
              }}>
              {row}
            </a>
          </li>
        ))}
        <li
          className={
            `${bootstrapstyle['page-item']} ` +
            (window.location.search === `?page=${pages.length}` ||
            pages.length == 0
              ? 'cursor-default ' + bootstrapstyle.disabled
              : '')
          }>
          <a
            className={`${bootstrapstyle['page-link']}`}
            onClick={() => {
              arrowPageChange(+1);
            }}>
            Siguiente
          </a>
        </li>
      </ul>
    </nav>
  );
}
