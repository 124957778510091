import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import InvoiceTableHeader from './InvoiceTableHeader';
import InvoiceTableRow from './InvoiceTableRow';
import InvoiceTableTotal from './InvoiceTableTotal';
import InvoiceTableRowDisc from './InvoiceTableRow';
import InvoiceTableTotalDisc from './InvoiceTableTotalDisc';
import SeparatorTop from '../SeparatorTop';
import SeparatorBottom from '../SeparatorBottom';

const styles = StyleSheet.create({
  tableContainer: {
    width: '72.1mm',
    marginTop: 0,
    marginBottom: 4,
    textAlign: 'center',
  },
});

const InvoiceItemsTable = ({ invoice }) => (
  console.log(invoice.descuento),
  (
    <View style={styles.tableContainer}>
      <SeparatorTop />
      <InvoiceTableHeader invoice={invoice} />
      <SeparatorBottom />
      {invoice.descuento == 0 || invoice.descuento > 0 ? (
        <>
          <InvoiceTableRow
            items={invoice.items}
            descuento={invoice.descuento}
          />
          <InvoiceTableTotal
            subtotal={invoice.subtotal}
            descuento={invoice.descuento}
            total={invoice.total}
            adi={invoice.adi}
          />
        </>
      ) : (
        <>
          <InvoiceTableRowDisc
            items={invoice.items}
            descuento={invoice.descuento}
          />
          <InvoiceTableTotalDisc items={invoice.items} />
        </>
      )}
    </View>
  )
);

export default InvoiceItemsTable;
