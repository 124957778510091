import React, { useState, useEffect, useRef } from "react";
import { createPopper } from "@popperjs/core";

const NotificationDropdown = () => {

  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] = useState(
      initialIsVisible        
    );
    const ref = useRef(null);
  
    const handleHideDropdown = (event) => {
      if (event.key === "Escape") {
        closeDropdownPopover();      
      }
    };
  
    const handleClickOutside = (event) => {
      if (ref.current && !btnDropdownRef.current.contains(event.target)) {          
        closeDropdownPopover();
      }
    };
  
    useEffect(() => {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("click", handleClickOutside, true);
      };
    });
  
    return { ref, isComponentVisible, setIsComponentVisible };
  }
  
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {    
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "left-start",
    });
    setDropdownPopoverShow(true);    
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible();

  return (
    <>
      <a
        className="text-gray-600 block py-1 px-3"
        href=" "
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          dropdownPopoverShow ? closeDropdownPopover() : setIsComponentVisible(true);
        }}      
      >
        <i className="fas fa-bell"></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "bloque " : "escondido ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1 min-w-48"
        }
      >
        {isComponentVisible && (
          <div ref={ref}>
            <a
              href=" "
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
              }
              onClick={(e) => e.preventDefault()}
            >
              Notificaciones
            </a>
            <div className="h-0 my-2 border border-solid border-gray-200" />
            <a
              href=" "
              className={
                "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
              }
              onClick={(e) => e.preventDefault()}
            >
              Borrar notificaciones
            </a>
          </div> 
        )}
      </div>
    </>
  );
};

export default NotificationDropdown;
