import React, { Fragment } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import AdminNavbar from '../components/Navbars/AdminNavbar.js';
import AdminSidebar from './../components/Sidebar/AdminSidebar.js';
import HeaderStats from '../components/Headers/HeaderStats.js';
import FooterAdmin from '../components/Footers/FooterAdmin.js';

import Dashboard from '../views/admin/Dashboard.js';
import Settings from '../views/admin/Settings.js';
import Comercios from '../views/admin/Comercios.js';
import Extensiones from '../views/admin/Extensiones.js';
import Productos from '../views/admin/Productos.js';
import Grupos from '../views/admin/Grupos.js';
import Elementos from '../views/admin/Elementos.js';
import Especificaciones from '../views/admin/Especificaciones.js';
import Tags from '../views/admin/Tags.js';
import ProductoTags from '../views/admin/ProductoTags.js';
import Registros from '../views/admin/Registros.js';
import Afiliados from '../views/admin/Afiliados.js';
import Usuarios from '../views/admin/Usuarios.js';
import Facturas from '../views/admin/Facturas';
import Encargos from '../views/admin/Encargos';
import Reportes from '../views/admin/Reportes';

const TITLE = 'Malem Group | Administración';

export default function Admin(userData) {
  return (
    <Fragment>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <AdminSidebar />
      <div className='block relative md:ml-64 bg-gray-200 h-screen-90'>
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className='px-4 md:px-10 mx-auto w-full'>
          <Switch>
            <Route
              path='/admin/dashboard'
              exact
              component={Dashboard}
              userData={userData}
            />
            <Route
              path='/admin/opciones'
              exact
              component={Settings}
              userData={userData}
            />
            <Route
              path='/admin/comercios'
              exact
              component={Comercios}
              userData={userData}
            />
            <Route
              path='/admin/extensiones'
              exact
              component={Extensiones}
              userData={userData}
            />
            <Route
              path='/admin/productos'
              exact
              component={Productos}
              userData={userData}
            />
            <Route
              path='/admin/grupos'
              exact
              component={Grupos}
              userData={userData}
            />
            <Route
              path='/admin/elementos'
              exact
              component={Elementos}
              userData={userData}
            />
            <Route
              path='/admin/especificaciones'
              exact
              component={Especificaciones}
              userData={userData}
            />
            <Route path='/admin/tags' exact component={Tags} />
            <Route path='/admin/producto/tags' exact component={ProductoTags} />
            <Route
              path='/admin/facturas/consultar'
              exact
              component={Facturas}
              userData={userData}
            />
            <Route
              path='/admin/encargos/auditar'
              exact
              component={Encargos}
              userData={userData}
            />
            <Route
              path='/admin/usuarios/gestionar'
              exact
              component={Usuarios}
              userData={userData}
            />
            <Route
              path='/admin/usuarios/registros'
              exact
              component={Registros}
            />
            <Route
              path='/admin/usuarios/afiliados'
              exact
              component={Afiliados}
            />
            <Route path='/admin/reportes' exact component={Reportes} />
            <Redirect from='/admin' to='/admin/dashboard' />
          </Switch>
        </div>
        <div className='block relative md:ml-64 bg-gray-200'>
          <FooterAdmin />
        </div>
      </div>
    </Fragment>
  );
}
