import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import ReceiptDetails from './ReceiptDetails';
import ReceiptItems from './ReceiptItems';
import ReceiptTotal from './ReceiptTotal.jsx';
import ReceiptDate from './ReceiptDate';

import Separator from './../Separator';

const styles = StyleSheet.create({
  container: {
    width: '72.1mm',
    marginTop: 10,
    textAlign: 'center',
    paddingLeft: '5%',
    paddingRight: '5%',
  },
});

const InvoiceItemsTable = ({ receipt }) => {
  return (
    <View style={styles.container}>
      <ReceiptDetails receipt={receipt} />
      <Separator />
      <ReceiptItems items={receipt.items} descuento={receipt.descuento} />
      <Separator />
      <ReceiptTotal
        subtotal={receipt.subtotal}
        descuento={receipt.descuento}
        total={receipt.total}
        items={receipt.items}
        adi={receipt.adi}
      />
      <Separator />
      <ReceiptDate receipt={receipt} />
      <Separator />
    </View>
  );
};

export default InvoiceItemsTable;
