import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    width: '72.1mm',
    textAlign: 'left',
  },
});

const ReceiptDetails = ({ receipt }) => (
  <View style={styles.container}>
    <Text>Nombre: {receipt.cliente}</Text>
    <Text>Cajero: {receipt.autor}</Text>
  </View>
);

export default ReceiptDetails;
