import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from 'UserContext';
import Modal from 'components/Modals/Modal.js';
// Alertify
import alertify from 'alertifyjs';

const OrderDropdown = ({
  rowDataId,
  rowDataProcess,
  info,
  URIExtension,
  ...props
}) => {
  // User Profile
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;
  const [alert, setAlert] = useState('');

  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
      useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
      if (event.key === 'Escape') {
        closeDropdownPopover();
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !btnDropdownRef.current.contains(event.target)) {
        closeDropdownPopover();
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
      };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
  }

  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    // createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
    //   placement: "auto"
    // });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  // useState
  const [idData, setIdData] = useState(null);
  const [invoiceProcess, setInvoiceProcess] = useState(null);
  // URI
  const URI = '/api/update/';
  // URI For Remove (Set State to 2)
  const URIDelete = '/api/delete/';

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setIdData(rowDataId);
      setInvoiceProcess(rowDataProcess);
    }

    return () => {
      mounted = false;
    };
  }, [rowDataId, rowDataProcess]);

  function submitDataEdit() {
    const postData = () => {
      var datos = [];
      datos.push({
        idData,
        invoiceProcess,
      });

      if (idData && invoiceProcess) {
        fetch(URI + URIExtension, {
          method: 'PUT',
          credentials: 'same-origin',
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(datos),
        })
          .then((response) => response.json())
          .then((result) => {
            props.recibir();
            setAlert(result.message);
          })
          .catch((error) => {});
      } else {
        setAlert(
          'Por favor no deje casillas en blanco antes de guardar los cambios realizados'
        );
      }
    };
    postData();
  }

  function submitDataRemove() {
    fetch(URIDelete + URIExtension, {
      method: 'PUT',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ idData }),
    })
      .then((response) => response.json())
      .then((result) => {
        setAlert(result.message);
        props.recibir();
      })
      .catch((error) => {
        setAlert(error);
      });
  }

  const handleFacturas = (e) => {
    e.preventDefault();
    props.viewFacturas(idData);
  };
  const handleRecibos = (e) => {
    e.preventDefault();
    props.viewRecibos(idData);
  };
  const handleSubmitEdit = (e) => {
    e.preventDefault();
    submitDataEdit();
  };
  const handleSubmitRemove = (e) => {
    e.preventDefault();
    submitDataRemove();
  };

  const modalEdit = useRef(null);
  const modalRemove = useRef(null);

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert, function () {
      if (modalEdit.current) {
        modalEdit.current.close();
      }
      if (modalRemove.current) {
        modalRemove.current.close();
      }
      alertify.success('Muy bien!');
    });
    setAlert('');
  }

  return (
    <>
      <a
        className='text-gray-600 py-1 px-3'
        href=' '
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          dropdownPopoverShow
            ? closeDropdownPopover()
            : setIsComponentVisible(true);
        }}
      >
        <i className='fas fa-ellipsis-v'></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded-md shadow-lg w-36 ' +
          (dropdownPopoverShow ? 'message-globe' : 'hide-message')
        }
      >
        {isComponentVisible && (
          <div ref={ref}>
            <a
              href=' '
              className={
                'view-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                handleFacturas(e);
              }}
            >
              <i className='fas fa-file-invoice-dollar mr-2'></i>
              Facturas
            </a>
            <a
              href=' '
              className={
                'view-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                handleRecibos(e);
              }}
            >
              <i className='fas fa-receipt mr-2'></i>
              Recibos
            </a>
            <a
              href=' '
              className={
                'edit-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                e.preventDefault();
                modalEdit.current.open();
              }}
            >
              <i className='far fa-edit mr-2'></i>
              Editar
            </a>
            {userProfile.puesto === 1 && (
              <a
                href=' '
                className={
                  'delete-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
                }
                onClick={(e) => {
                  e.preventDefault();
                  modalRemove.current.open();
                }}
              >
                <i className='fas fa-eraser mr-2'></i>
                Eliminar
              </a>
            )}
          </div>
        )}
      </div>
      <Modal ref={modalEdit}>
        <form
          onSubmit={handleSubmitEdit}
          className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 max-w-580-px'
        >
          <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-align-center'>
            Editar {info}
          </div>
          <div className='my-4 flex items-center justify-center max-w-full md:w-96'>
            <label
              htmlFor={'extra' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Estado del pedido
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              onChange={(e) => setInvoiceProcess(e.target.value)}
              value={invoiceProcess}
            >
              <option value=''>Seleccionar</option>
              <option value='1'>En proceso</option>
              <option value='2'>Completado</option>
            </select>
          </div>
          <div className='flex'>
            <button
              type='button'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-1/2 w-1/2'
              onClick={() => modalEdit.current.close()}
            >
              Cerrar ventana
            </button>
            <button
              type='submit'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-1/2 w-1/2'
            >
              Guardar cambios
            </button>
          </div>
        </form>
      </Modal>
      <Modal ref={modalRemove}>
        <form
          onSubmit={(e) => {
            handleSubmitRemove(e);
          }}
        >
          <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-left'>
            Eliminar {info}
          </div>
          <div className='my-4'>
            <label
              htmlFor={'nombre' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 inline-block'
            >
              En verdad desea eliminar esta factura?
            </label>
            <div className='my-4'>
              <div className='flex'>
                <button
                  type='button'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-full inline-block'
                  onClick={() => modalRemove.current.close()}
                >
                  No, cerrar ventana
                </button>
                <button
                  type='submit'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-full inline-block'
                >
                  Si, deseo eliminarla
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default OrderDropdown;
