import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import SeparatorTop from '../SeparatorTop';
import SeparatorBottom from '../SeparatorBottom';

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 12,
    width: '72.1mm',
  },
  topBorder: {
    borderTop: '.5',
    borderStyle: 'dashed',
    marginBottom: 6,
    marginRight: 10,
    marginLeft: 10,
  },
  bottomBorder: {
    borderBottom: '.5',
    borderStyle: 'dashed',
    marginTop: 6,
    marginRight: 10,
    marginLeft: 10,
  },
  emojiSpan: {
    fontSize: '35px',
  },
});

const InvoiceThankYouMsg = ({ comercio }) => (
  <View style={styles.titleContainer}>
    <SeparatorTop style={styles.topBorder} />
    {comercio === "B'alam" ? (
      <>
        <Text>Muchas gracias por su compra</Text>
        <Text>¡Vuelva pronto!</Text>
        <Text>
          <Text style={styles.emojiSpan}>{`\u{1F334}`}</Text>Le esperamos{' '}
          <Text style={styles.emojiSpan}>{`⛱️`}</Text>
        </Text>
      </>
    ) : (
      <>
        <Text>Muchas gracias por su compra</Text>
        <Text>¡Vuelva pronto!</Text>
        <Text>
          <Text style={styles.emojiSpan}>{`🛋️`}</Text>Le esperamos{' '}
          <Text style={styles.emojiSpan}>{`🏡`}</Text>
        </Text>
      </>
    )}
    <SeparatorBottom style={styles.bottomBorder} />
    <SeparatorBottom style={styles.bottomBorder} />
  </View>
);

export default InvoiceThankYouMsg;
