import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    width: '72.1mm',
    textAlign: 'left',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  consumerLabel: {
    marginBottom: -5,
  },
});

const InvoiceTableHeader = ({ invoice }) => (
  <View style={styles.container}>
    <Text style={styles.consumerLabel}>Nit: {invoice.nit}</Text>
    <Text style={styles.consumerLabel}>Nombre: {invoice.cliente}</Text>
  </View>
);

export default InvoiceTableHeader;
