import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useContext,
} from 'react';
import Modal from 'components/Modals/Modal.js';
import { UserContext } from 'UserContext';

import alertify from 'alertifyjs';

const AffiliatesDropdown = ({ affiliates, ...props }) => {
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;

  const [formData, setFormData] = useState({
    id: affiliates.id,
    codigo: affiliates.codigo,
    nombre: affiliates.nombre,
    telefono: affiliates.telefono,
    email: affiliates.email,
    estado: affiliates.estado,
    descripcion: affiliates.descripcion,
  });

  const { id, codigo, nombre, telefono, email, descripcion, estado } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    let _isMounted = true;

    if (_isMounted) {
      setFormData({
        id: affiliates.id,
        codigo: affiliates.codigo,
        nombre: affiliates.nombre,
        telefono: affiliates.telefono,
        email: affiliates.email,
        estado: affiliates.estado,
        descripcion: affiliates.descripcion,
      });
    }

    return () => {
      _isMounted = false;
    };
  }, [affiliates]);

  const [alert, setAlert] = useState('');
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
      useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
      if (event.key === 'Escape') {
        closeDropdownPopover();
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !btnDropdownRef.current.contains(event.target)) {
        closeDropdownPopover();
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
      };
    });
    return { ref, isComponentVisible, setIsComponentVisible };
  }

  async function submitDataEdit() {
    fetch('/api/affiliates', {
      method: 'PUT',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((json) => {
        setAlert(json.message);
        props.recibir();
      })
      .catch((err) => {
        console.error(err);
        props.recibir();
      });
  }

  function submitDataRemove() {
    fetch('/api/affiliates', {
      method: 'DELETE',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((json) => {
        setAlert(json.message);
        props.recibir();
      })
      .catch((err) => {
        console.log(err);
        props.recibir();
      });
  }

  const shoppingHistory = (id) => {
    props.shoppingHistory(id);
  };

  const handleSubmitEdit = (e) => {
    e.preventDefault();
    submitDataEdit();
  };
  const handleSubmitRemove = (e) => {
    e.preventDefault();
    submitDataRemove();
  };

  const modalEdit = useRef(null);
  const modalRemove = useRef(null);

  if (alert) {
    if (!alertify.successAlert) {
      alertify.dialog(
        'successAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.successAlert(alert, function () {
      if (modalEdit.current !== null) {
        modalEdit.current.close();
      }
      if (modalEdit.current !== null) {
        modalRemove.current.close();
      }
      alertify.success('Muy bien!');
    });
    setAlert('');
  }

  return (
    <Fragment>
      <a
        className='text-gray-600 py-1 px-3'
        href=' '
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          dropdownPopoverShow
            ? closeDropdownPopover()
            : setIsComponentVisible(true);
        }}>
        <i className='fas fa-ellipsis-v' />
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded-md shadow-lg w-36 ' +
          (dropdownPopoverShow ? 'message-globe' : 'hide-message')
        }>
        {isComponentVisible && (
          <div ref={ref}>
            <a
              href=' '
              className={
                'edit-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                e.preventDefault();
                shoppingHistory(codigo);
              }}>
              <i className='fas fa-history mr-2' />
              Historial
            </a>
            <a
              href=' '
              className={
                'edit-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                e.preventDefault();
                modalEdit.current.open();
              }}>
              <i className='far fa-edit mr-2' />
              Editar
            </a>
            {'1,2'.indexOf(userProfile.puesto) !== -1 && (
              <a
                href=' '
                className={
                  'delete-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
                }
                onClick={(e) => {
                  e.preventDefault();
                  modalRemove.current.open();
                }}>
                <i className='fas fa-eraser mr-2' />
                Eliminar
              </a>
            )}
          </div>
        )}
      </div>
      <Modal ref={modalEdit}>
        <form
          onSubmit={handleSubmitEdit}
          className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 user-select'>
          <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-align-center modal-title'>
            # Editar Afiliado
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='codigo'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square' /> Código del Afiliado
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='number'
              id='codigo'
              name='codigo'
              placeholder='Código del nuevo Afiliado'
              onChange={(e) => onChange(e)}
              value={codigo}
              required
            />
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='nombre'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square' /> Nombre del Afiliado
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id='nombre'
              name='nombre'
              placeholder='Nombre del nuevo Afiliado'
              onChange={(e) => onChange(e)}
              value={nombre}
              required
            />
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='telefono'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square' /> Teléfono
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='tel'
              id='telefono'
              name='telefono'
              placeholder='Número de télefono del nuevo Afiliado'
              onChange={(e) => onChange(e)}
              value={telefono}
              required
            />
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='email'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square' /> Email
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='email'
              id='email'
              name='email'
              placeholder='Email del Afiliado'
              onChange={(e) => onChange(e)}
              value={email}
              required
            />
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='descripcion'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square' /> Descripción
            </label>
            <textarea
              style={{ minHeight: '80px' }}
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id='descripcion'
              name='descripcion'
              placeholder='Descripción del nuevo Afiliado'
              onChange={(e) => onChange(e)}
              value={descripcion}
              required
            />
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor='estado'
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block modal-label'>
              <i className='fas fa-pen-square' /> Estado del Afiliado
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id='estado'
              name='estado'
              onChange={(e) => onChange(e)}
              value={estado}>
              <option value=''>Seleccionar</option>
              <option value='1'>Activo</option>
              <option value='2'>No activo</option>
            </select>
          </div>
          <div className='float-right'>
            <button
              type='button'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 mr-4'
              onClick={() => modalEdit.current.close()}>
              Cerrar ventana <i className='fas fa-times' />
            </button>
            <button
              type='submit'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-3 ml-4'>
              Guardar cambios <i className='fas fa-check-circle' />
            </button>
          </div>
        </form>
      </Modal>
      <Modal ref={modalRemove}>
        <form onSubmit={handleSubmitRemove}>
          <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-center modal-title'>
            # Remover Afiliado
          </div>
          <div className='mt-4'>
            <div className='border-yellow p-1 cursor-pointer'>
              <label className='text-gray-500 font-bold mb-1 md:mb-0 inline-block modal-label danger'>
                Cuidado esta acción es permanente!{' '}
                <i className='fas fa-exclamation-triangle' />
              </label>
            </div>
            <div className='my-4'>
              <div className='block align-middle text-center'>
                <button
                  type='button'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 inline-block'
                  onClick={() => modalRemove.current.close()}>
                  Cancelar <i className='fas fa-times-circle' />
                </button>
                <button
                  type='submit'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 inline-block'>
                  Remover Afiliado <i className='fas fa-user-slash' />
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </Fragment>
  );
};

export default AffiliatesDropdown;
