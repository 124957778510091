import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// components
import TableDropdown from "../../components/Dropdowns/TableDropdown.js";
import PageNumbers from "components/Table/PageNumbers.jsx";

export default function CardTable({ data, info, URIExtension, ...props}) {

  const [slice, setSlice] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const color = "light";

  useEffect(() => {

    let _isMounted = true;

    if (_isMounted) {
      var dataPages = []
      var numberOfPages = Math.ceil(data.length / 10)
    
      for (var i = 1; i <= numberOfPages; i++) {
        dataPages.push(i)
      }

      setPageNumbers(dataPages)
      defaultPage(dataPages)
    }
		return () => {
			_isMounted = false;
		}
  }, [data])

  function refreshOnSubmit() {
    props.recibir()
  }

  function defaultPage(value) {
    var url = window.location.search
    var currentPage = Math.abs(parseInt(url.replace("?page=", "")))
    var index = currentPage - 1
    var lastPage = pageNumbers[pageNumbers.length - 1]

    if (typeof value === "number" && value < 0 && currentPage > 1) {
      changePage(index += value, currentPage += value)
    }
    else if (typeof value === "number" && value > 0 && currentPage < lastPage) {
      changePage(index += value, currentPage += value)
    }
    else if (typeof value === "object") {
      if (typeof currentPage !== "undefined" && currentPage <= value[value.length - 1] && currentPage != 0) {
        changePage(index, currentPage)
      }
      else {
        changePage(0, 1)
      }
    }
  }

  function changePage (index, row) {
    var startIndex = index * 10;
    var endIndex = row * 10;
    var sortDataById = sortById(data)
    setSlice(sortDataById.slice([startIndex], [endIndex]));
    window.history.replaceState(null, "", `?page=${endIndex / 10}`)
  }

  function sortById(data) {
    return data.sort(function(a,b) {return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0);}).reverse()
  }

  const arrowPageChange = (value) => {
    defaultPage(value);
  }

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-blue-900 text-white")
        }
      >
        <PageNumbers info={info} color={color} pages={pageNumbers} changePage={changePage} arrowPageChange={arrowPageChange}/>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >ID</th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Nombre</th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Estado</th>                
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-right " +
                    (color === "light"
                      ? "bg-gray-100 text-gray-600 border-gray-200"
                      : "bg-blue-800 text-blue-300 border-blue-700")
                  }
                >Opciones</th>           
              </tr>
            </thead>
            <tbody>
              {slice.map((row, index) => (
                <tr 
                  key={index}
                  className={
                    (( index & 1 ) ? "bg-gray-100" : "hover:bg-gray-100")
                  }
                >
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">
                    {row.id}
                  </td>                    
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">{row.nombre}</td>                    
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 bg-gray-000 text-gray-700 border-gray-200">                    
                    {(row.activo===1) ? (<><i className="fas fa-circle text-green-500 mr-2"></i>Activo</>) : (<><i className="fas fa-circle text-red-500 mr-2"></i>No activo</>)}
                  </td>  
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap p-4 text-right">
                    <TableDropdown 
                      rowDataId={row.id} 
                      rowDataName={row.nombre} 
                      rowDataActive={row.activo} 
                      info={info} 
                      URIExtension={URIExtension} 
                      recibir={refreshOnSubmit} 
                    />
                  </td>                  
                </tr>
              ))}              
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
