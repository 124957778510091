import {useState} from "react";

export default function AlertRefresh ({ reload }) {

  const [reloader, setReload] = useState(reload);

  if (reloader) {
    setTimeout(() => {
      window.history.go(0)
      return (
        setReload(false)
      )
    }, 3000);
  } 
  return (null)
}