import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    marginTop: 12,
    paddingRight: 5,
    width: '72.1mm',
  },
  label: {
    fontFamily: 'Ivory Chill',
    fontSize: 36,
  },
  emojiRow: {
    marginTop: 10,
  },
  emojiSpan: {
    fontSize: '36px',
  },
});

const InvoiceThankYouMsg = ({ comercio }) => (
  <View style={styles.container}>
    {comercio === "B'alam" ? (
      <Fragment>
        <Text style={styles.label}>¡Regresa pronto!</Text>
        <Text style={[styles.label, { marginTop: -10 }]}>
          Te esperamos {`🍻`}
        </Text>
        <Text style={styles.emojiRow}>
          <Text style={styles.emojiSpan}>{`\u{1F334}`}</Text>{' '}
          <Text style={styles.emojiSpan}>{`😎`}</Text>{' '}
          <Text style={styles.emojiSpan}>{`🤙`}</Text>
        </Text>
      </Fragment>
    ) : (
      <Fragment>
        <Text style={{ fontSize: 12 }}>Muchas gracias por su compra</Text>
        <Text style={{ fontSize: 12 }}>¡Vuelva pronto!</Text>
        <Text style={styles.emojiRow}>
          <Text style={styles.emojiSpan}>{`🛋️`}</Text>Le esperamos{' '}
          <Text style={styles.emojiSpan}>{`🏡`}</Text>
        </Text>
      </Fragment>
    )}
  </View>
);

export default InvoiceThankYouMsg;
