import React from 'react';
import { Page, Document, StyleSheet, Font } from '@react-pdf/renderer';

import ReceiptHeader from './Utils/Receipts/ReceiptHeader';
import ReceiptBody from './Utils/Receipts/ReceiptBody.jsx';
import ReceiptFooter from './Utils/Receipts/ReceiptFooter';

import Hypermarket from 'fonts/Hypermarket W00 Regular.ttf';
import AlteHaasGroteskRegular from 'fonts/altehaasgroteskregular.ttf';
import AlteHaasGroteskBold from 'fonts/altehaasgroteskbold.ttf';
import IvoryChill from 'fonts/Ivory Chill.otf';

Font.register({
  family: 'Ivory Chill',
  format: 'opentype',
  src: IvoryChill,
});

Font.register({
  family: 'Hypermarket',
  format: 'truetype',
  src: Hypermarket,
});

Font.register({
  family: 'Alte Haas Grotesk',
  format: 'truetype',
  fonts: [
    {
      src: AlteHaasGroteskRegular,
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: AlteHaasGroteskBold,
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
  ],
});

Font.register({
  family: 'Dancing Script',
  src: 'https://fonts.gstatic.com/s/dancingscript/v16/If2cXTr6YS-zF4S-kcSWSVi_sxjsohD9F50Ruu7BMSoHTeB9ptDqpw.ttf',
});

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
});

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'http://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsiH0C4nY1M2xLER.ttf',
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: 'http://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: 'http://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjr0C4nY1M2xLER.ttf',
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: 'http://fonts.gstatic.com/s/opensans/v26/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgshZ1y4nY1M2xLER.ttf',
      fontStyle: 'normal',
      fontWeight: 800,
    },
  ],
});

Font.registerEmojiSource({
  format: 'png',
  url: 'https://twemoji.maxcdn.com/2/72x72/',
});

const styles = StyleSheet.create({
  page: {
    paddingTop: 0,
    marginTop: 0,
    paddingLeft: 0,
    paddingRight: 0,
    lineHeight: 1.3,
    flexDirection: 'column',
    width: '72.1mm',
  },
  font: {
    fontSize: 11,
    color: 'black',
    letterSpacing: 0,
    textAlign: 'center',
    fontFamily: 'Alte Haas Grotesk',
  },
});

const Receipt = ({ receipt }) => {
  const itemAverageHeight = 12.5;
  const standardDocumentHeight = 297;
  const totalDocumentHeight =
    receipt.items.length * itemAverageHeight + standardDocumentHeight;
  // Cada producto ocupa entre 12.5mm y 17.5mm en la tabla

  return (
    <Document>
      {/* 80(72.1) x 297 mm */}
      <Page
        size={['72.1mm', totalDocumentHeight + 'mm']}
        style={[styles.page, styles.font]}
      >
        <ReceiptHeader title={receipt.comercio} extension={receipt.extension} />
        <ReceiptBody receipt={receipt} />
        <ReceiptFooter receipt={receipt} />
      </Page>
    </Document>
  );
};

export default Receipt;
