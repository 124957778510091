import React from 'react';
import {Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({   
  topBorder:{
      borderTop: '1.5',
      borderStyle: 'dashed',
      marginBottom: 6,
      marginRight: 10,
      marginLeft: 10,
  }
});

  const SeparatorTop = () => (
    <Text style={styles.topBorder}/>
  );
  
  export default SeparatorTop