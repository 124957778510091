import React from 'react';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Background1 from 'assets/img/wallpapers/1.webp';
import Background2 from 'assets/img/wallpapers/2.webp';

import ReCAPTCHA from 'react-google-recaptcha';

const pictureArray = [Background1, Background2];
const randomIndex = Math.floor(Math.random() * pictureArray.length);
const selectedPicture = pictureArray[randomIndex];

const styles = (theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    // backgroundImage: `url(${process.env.PUBLIC_URL + '/apple-icon.png'})`,
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage: `url(${selectedPicture})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

function Copyright() {
  return (
    <Typography variant='body2' color='textSecondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href=' '>
        Malem Group
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

class Sesion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mensajeData: [],
      checked: false,
      IS_LOGGED_IN: false,
    };

    this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
  }

  onChangeCheckBox(e) {
    this.setState({ checked: true });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    var datos = [];

    datos.push({
      email: e.target.email.value,
      password: e.target.password.value,
    });

    fetch('/api/auth/validateToken', {
      method: 'POST',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos), // We convert the React state to JSON and send it as the POST body
    })
      .then((response) => response.json())
      .then((mensajeData) => {
        this.setState({ mensajeData });
        // console.log(mensajeData.message)
      });
  };

  render() {
    const { mensajeData } = this.state;
    const { classes } = this.props;

    if (mensajeData.message) {
      if (mensajeData.IS_LOGGED_IN === true) {
        setTimeout(() => {
          const elemRemSesiCorr = document.getElementById(
            'removerSesionCorrecta'
          );
          if (
            elemRemSesiCorr &&
            elemRemSesiCorr.classList.contains('escondido')
          ) {
            elemRemSesiCorr.classList.remove('escondido');
          }
          if (
            elemRemSesiCorr &&
            !elemRemSesiCorr.classList.contains('sesionCorrecta')
          ) {
            elemRemSesiCorr.classList.add('sesionCorrecta');
            elemRemSesiCorr.classList.add('sesion-correcta-animation');
          }
          setTimeout(() => {
            elemRemSesiCorr.classList.remove('sesionCorrecta');
            elemRemSesiCorr.classList.remove('sesion-correcta-animation');
            elemRemSesiCorr.classList.add('escondido');
            window.location.reload();
          }, 3500);
        }, 100);
      } else {
        setTimeout(() => {
          const elemRemSesInco = document.getElementById(
            'removerSesionIncorrecta'
          );
          if (
            elemRemSesInco &&
            elemRemSesInco.classList.contains('escondido')
          ) {
            elemRemSesInco.classList.remove('escondido');
          }
          if (
            elemRemSesInco &&
            !elemRemSesInco.classList.contains('sesionIncorrecta')
          ) {
            elemRemSesInco.classList.add('sesionIncorrecta');
            elemRemSesInco.classList.add('sesion-incorrecta-animation');
          }
          setTimeout(() => {
            elemRemSesInco.classList.remove('sesionIncorrecta');
            elemRemSesInco.classList.remove('sesion-incorrecta-animation');
            elemRemSesInco.classList.add('escondido');
          }, 3500);
        }, 100);
      }
    }

    return (
      <Grid container component='main' className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Iniciar sesión
            </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                type='email'
                id='email'
                label='E-mail'
                name='email'
                autoComplete='email'
                autoFocus
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Contraseña'
                type='password'
                id='password'
                autoComplete='current-password'
              />
              <FormControlLabel
                control={<Checkbox value='remember' color='primary' />}
                label='Recordarme'
              />
              <script
                src='https://www.google.com/recaptcha/api.js'
                async
                defer></script>
              <ReCAPTCHA
                sitekey={
                  process.env.NODE_ENV === 'development'
                    ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'
                    : '6LdwuowaAAAAAFEuGzehBBBSLUN_J7_9QYmScCLG'
                }
                checked={this.state.checked}
                onChange={this.onChangeCheckBox}
              />
              <Button
                disabled={!this.state.checked}
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}>
                INICIAR SESIÓN
              </Button>

              {mensajeData &&
              mensajeData.message === 'Los datos ingresados son incorrectos' ? (
                <div
                  ref={(ref) => (this.myTextInput = ref)}
                  className={
                    'sesionIncorrecta sesion-incorrecta-animation ' +
                    'bg-white text-base z-50 float-left py-2 list-none text-left rounded drop-shadow mt-1 min-w-48'
                  }
                  id='removerSesionIncorrecta'>
                  <li>{mensajeData.message}</li>
                </div>
              ) : mensajeData &&
                mensajeData.message === 'Inicio de sesión correcto' ? (
                <div
                  ref={this.textInput}
                  className={
                    'sesionCorrecta sesion-correcta-animation ' +
                    'bg-white text-base z-50 float-left py-2 list-none text-left rounded drop-shadow mt-1 min-w-48'
                  }
                  id='removerSesionCorrecta'>
                  <li>{mensajeData.message}</li>
                </div>
              ) : (
                ''
              )}
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(Sesion);
