import React, { useEffect, useState, useRef, Fragment } from 'react';
// Components
import Modal from 'components/Modals/Modal.js';
import CataloguePageNumbers from './CataloguePageNumbers';
// Loader
import Loader from 'react-loader-spinner';
// CSS
import bootstrapstyle from './../../extras/e-shop/css/bootstrap.module.css';
import uistyle from './../../extras/e-shop/css/ui.module.css';
// Image Zoom
import InnerImageZoom from 'react-inner-image-zoom';
import './../../assets/styles/zoom.css';

export default function CatalogueCard({ data, dataTags }) {
  const [slicedData, setSlicedData] = useState([]);
  const [pageNumbers, setPageNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState();

  useEffect(() => {
    let _isMounted = true;

    if (_isMounted) {
      var dataPages = [];
      var numberOfPages = Math.ceil(data.length / 5);

      for (var i = 1; i <= numberOfPages; i++) {
        dataPages.push(i);
      }

      setPageNumbers(dataPages);
      defaultPage(dataPages);
      setLoading(false);
    }
    return () => {
      _isMounted = false;
    };
  }, [data]);

  function defaultPage(value) {
    var url = window.location.search;
    var currentPage = Math.abs(parseInt(url.replace('?page=', '')));
    var index = currentPage - 1;
    var lastPage = pageNumbers[pageNumbers.length - 1];

    if (typeof value === 'number' && value < 0 && currentPage > 1) {
      changePage((index += value), (currentPage += value));
    } else if (
      typeof value === 'number' &&
      value > 0 &&
      currentPage < lastPage
    ) {
      changePage((index += value), (currentPage += value));
    } else if (typeof value === 'object') {
      if (
        typeof currentPage !== 'undefined' &&
        currentPage <= value[value.length - 1] &&
        currentPage != 0
      ) {
        changePage(index, currentPage);
      } else {
        changePage(0, 1);
      }
    }
  }

  function changePage(index, row) {
    var startIndex = index * 5;
    var endIndex = row * 5;
    setSlicedData(data.slice([startIndex], [endIndex]));
    window.history.replaceState(null, '', `?page=${endIndex / 5}`);
  }

  function openModal() {
    modalView.current.open();
  }

  function fixPhotoArray(row) {
    return row.split(',')[0];
  }

  function photoArray(row) {
    return row.split(',');
  }

  const arrowPageChange = (value) => {
    defaultPage(value);
  };

  const modalView = useRef(null);

  return (
    <>
      {slicedData.map((row, index) => (
        <article
          key={index}
          className={`${bootstrapstyle.article} ${uistyle.article} ${bootstrapstyle.card} ${uistyle.card} ${uistyle['card-product-list']}`}>
          <div
            className={`${bootstrapstyle.row} ${uistyle.row} ${bootstrapstyle['no-gutters']}`}>
            <aside
              className={`${bootstrapstyle.aside} ${bootstrapstyle['col-md-3']}`}>
              <div className={`${bootstrapstyle.a} ${uistyle['img-wrap']}`}>
                <span
                  className={`${bootstrapstyle.badge} ${uistyle.badge} ${bootstrapstyle['badge-danger']}`}>
                  {' '}
                  NUEVO{' '}
                </span>
                {row.foto.indexOf(',') > -1 ? (
                  <img
                    className='cursor-zoom'
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentImage(row.foto);
                      openModal();
                    }}
                    src={
                      process.env.NODE_ENV === 'development'
                        ? 'http://localhost:3000/api/resources/' +
                          fixPhotoArray(row.foto)
                        : 'https://malem.group/api/resources/' +
                          fixPhotoArray(row.foto)
                    }
                    alt={row.id}
                  />
                ) : (
                  <img
                    className='cursor-zoom'
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentImage(row.foto);
                      openModal();
                    }}
                    src={
                      process.env.NODE_ENV === 'development'
                        ? 'http://localhost:3000/api/resources/' + row.foto
                        : 'https://malem.group/api/resources/' + row.foto
                    }
                    alt={row.id}
                  />
                )}
              </div>
            </aside>

            <div className={bootstrapstyle['col-md-6']}>
              <div className={uistyle['info-main']}>
                {window.location.href.indexOf('/catalogue/vianney') !== -1 ? (
                  <a
                    href=' '
                    className={`${bootstrapstyle.h5} ${uistyle.title}`}>
                    {row.nombre} ({row.codigo})
                  </a>
                ) : (
                  <>
                    <h6 className={bootstrapstyle.h6}>ID: {row.id}</h6>
                    <a
                      href=' '
                      className={`${bootstrapstyle.h5} ${uistyle.title}`}>
                      {row.nombre}
                    </a>
                  </>
                )}
                <div className={`rating-wrap ${bootstrapstyle['mb-3']}`}>
                  <ul className={uistyle['rating-stars']}>
                    <li className={`w-80 ${uistyle['stars-active']}`}>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                    </li>
                    <li>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                      <i className='fa fa-star'></i>
                    </li>
                  </ul>
                  <div className={uistyle['label-rating']}>5/5</div>
                </div>
                <p> {row.descripcion} </p>
                <br />
                <div
                  className={`${bootstrapstyle['float-left']} ${uistyle.relative}`}>
                  {dataTags.map((innerRow, index) =>
                    row.id === innerRow.producto_id ? (
                      <li key={index} style={{ marginLeft: '15px' }}>
                        {innerRow.tag_nombre} ({innerRow.tag_cantidad})
                      </li>
                    ) : null
                  )}
                </div>
                <div
                  className={`mb-6 ${bootstrapstyle['float-right']} ${uistyle.relative}`}>
                  <li> {row.grupo} </li>
                  <li> {row.elemento} </li>
                  <li> {row.especificacion} </li>
                </div>
              </div>
            </div>
            <aside
              className={`${bootstrapstyle.aside} ${bootstrapstyle['col-sm-3']}`}>
              <div className={uistyle['info-aside']}>
                <div className={uistyle['price-wrap']}>
                  {window.location.href.indexOf('/catalogue/vianney') !== -1 ? (
                    <>
                      <span className={`${uistyle.price} ${bootstrapstyle.h5}`}>
                        Q{(row.valor * 0.95).toFixed(2)}
                      </span>
                      <del className={uistyle['price-old']}>Q{row.valor}</del>
                      <p className={`text-red-600 ${bootstrapstyle.h6}`}>
                        Aprovecha ahora el 5% de descuento!
                      </p>
                    </>
                  ) : (
                    <>
                      <span className={`${uistyle.price} ${bootstrapstyle.h5}`}>
                        Q{row.valor}
                      </span>
                    </>
                  )}
                </div>
                <p className={bootstrapstyle['text-success']}>
                  Disponible ({row.cantidad})
                </p>
                <br />
                <p>
                  <a
                    href=' '
                    className={`${bootstrapstyle.btn} ${bootstrapstyle['btn-primary']} ${bootstrapstyle['btn-block']}`}>
                    {' '}
                    Detalles{' '}
                  </a>
                  {/* <a href=" " className="btn btn-light btn-block"><i className="fa fa-heart"></i> 
										<span className="text">Add to wishlist</span>
									</a> */}
                </p>
              </div>
            </aside>
          </div>
        </article>
      ))}
      {loading ? (
        <Loader
          type='MutatingDots'
          color='#9fd8df'
          secondaryColor='#4b778d'
          height={100}
          width={100}
          className='loader-middle'
        />
      ) : (
        <Fragment>
          <CataloguePageNumbers
            pages={pageNumbers}
            changePage={changePage}
            arrowPageChange={arrowPageChange}
          />
        </Fragment>
      )}
      <Modal ref={modalView}>
        {currentImage ? (
          currentImage.indexOf(',') > -1 ? (
            <section
              className='carousel md:max-w-xl max-w-xs'
              aria-label='Gallery'
              style={{ width: '36rem' }}>
              <ol className='carousel__viewport'>
                {photoArray(currentImage).map((row, index) => (
                  <li
                    key={index}
                    id={`carousel__slide${index}`}
                    tabIndex={index}
                    className='carousel__slide'
                    style={{
                      backgroundImage: `url("/api/resources/${row}")`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: 'auto 100%',
                    }}>
                    <div className='carousel__snapper'>
                      <a
                        href={`#carousel__slide${
                          index === 0
                            ? photoArray(currentImage).length - 1
                            : index - 1
                        }`}
                        className='carousel__prev'>
                        Go to last slide
                      </a>
                      <a
                        href={`#carousel__slide${
                          index === photoArray(currentImage).length - 1
                            ? 0
                            : index + 1
                        }`}
                        className='carousel__next'>
                        Go to next slide
                      </a>
                    </div>
                  </li>
                ))}
              </ol>

              <aside className='carousel__navigation'>
                <ol className='carousel__navigation-list'>
                  {photoArray(currentImage).map((row, index) => (
                    <li key={index} className='carousel__navigation-item'>
                      <a
                        href={`#carousel__slide${index}`}
                        className='carousel__navigation-button'>
                        Go to slide {index + 1}
                      </a>
                    </li>
                  ))}
                </ol>
              </aside>
            </section>
          ) : (
            <InnerImageZoom
              src={
                process.env.NODE_ENV === 'development'
                  ? 'http://localhost:3000/api/resources/' + currentImage
                  : 'https://malem.group/api/resources/' + currentImage
              }
              zoomSrc={
                process.env.NODE_ENV === 'development'
                  ? 'http://localhost:3000/api/resources/' + currentImage
                  : 'https://malem.group/api/resources/' + currentImage
              }
              zoomScale={2}
              hasSpacer={true}
              zoomType='hover'
              zoomPreload={true}
            />
          )
        ) : null}
      </Modal>
    </>
  );
}
