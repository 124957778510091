import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

// components

// import Navbar from "components/Navbars/AuthNavbar.js";
// import FooterSmall from "components/Footers/FooterSmall.js";

// views

import Sesion from 'views/auth/Sesion.js';

export default function Autenticacion() {
  return (
    <>
      <section>
        <Switch>
          <Route path='/autenticacion/sesion' exact component={Sesion} />
          <Redirect from='/autenticacion' to='/autenticacion/sesion' />
        </Switch>
      </section>
    </>
  );
}

// export default function Auth() {
//   return (
//     <>
//       <Navbar transparent />
//       <main>
//         <section className="relative w-full h-full py-40 min-h-screen">
//           <div
//             className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
//             style={{
//               backgroundImage:
//                 "url(" + require("assets/img/register_bg_2.png") + ")",
//             }}
//           ></div>
//           <Switch>
//             <Route path="/auth/sesion" exact component={Sesion} />
//             <Route path="/auth/login" exact component={Login} />
//             <Route path="/auth/register" exact component={Register} />
//             <Redirect from="/auth" to="/auth/login" />
//           </Switch>
//           <FooterSmall absolute />
//         </section>
//       </main>
//     </>
//   );
// }
