import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from 'UserContext';
import { Link } from 'react-router-dom';
import { createPopper } from '@popperjs/core';
import Modal from 'components/Modals/Modal.js';
// Alertify
import alertify from 'alertifyjs';
// Cookies
import { Cookies } from 'react-cookie';
import icon from 'assets/img/PngItem_1503945.png';

const UserDropdown = () => {
  // User Profile
  const contextStore = useContext(UserContext);
  const userProfile = contextStore.userProfile;
  // Modal
  const modalLogout = useRef(null);
  // Dropdown states
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'left-start',
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  // Alerts
  const [alert, setAlert] = useState('');

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert);
    setAlert('');
  }

  // handleSubmitLogout
  function submitLogout() {
    fetch('/api/logout/user/token', {
      method: 'GET',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => response.json())
      .then((result) => {
        setAlert(result.message);
        if (result.LOG_OUT) {
          const cookies = new Cookies();

          cookies.remove('MALEM__PROFILE', { path: '/' });

          setTimeout(() => {
            window.location.href = window.location.origin;
          }, 3000);
        }
      })
      .catch((err) => {
        console.error(err);
        setAlert(err);
      });
  }

  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
      useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
      if (event.key === 'Escape') {
        closeDropdownPopover();
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !btnDropdownRef.current.contains(event.target)) {
        closeDropdownPopover();
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
      };
    });

    return { ref, isComponentVisible, setIsComponentVisible };
  }

  // handle before submit
  const handleSubmitLogout = (evt) => {
    evt.preventDefault();
    submitLogout();
  };

  return (
    <>
      <button
        className='text-gray-600 block'
        ref={btnDropdownRef}
        onClick={(e) => {
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          dropdownPopoverShow
            ? closeDropdownPopover()
            : setIsComponentVisible(true);
        }}>
        <div className='items-center flex'>
          <span className='w-12 h-12 text-sm text-white bg-gray-300 inline-flex items-center justify-center rounded-full'>
            <img
              alt='...'
              className='w-full rounded-full align-middle border-none shadow-lg'
              src={icon}
            />
          </span>
        </div>
      </button>

      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? 'bloque ' : 'escondido ') +
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48'
        }>
        {isComponentVisible && (
          <div ref={ref}>
            <Link
              to={
                userProfile.puesto === 1
                  ? '/admin/dashboard'
                  : '/managment/dashboard'
              }
              className={
                'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }>
              Panel de Administración
            </Link>
            <div className='h-0 my-2 border border-solid border-gray-200' />
            <Link
              to={
                userProfile.puesto === 1
                  ? '/admin/opciones'
                  : '/managment/opciones'
              }
              className={
                'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }>
              Opciones
            </Link>
            <Link
              to={
                userProfile.puesto === 1
                  ? '/admin/productos'
                  : '/managment/productos'
              }
              className={
                'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }>
              Producto
            </Link>
            <div className='h-0 my-2 border border-solid border-gray-200' />
            <Link
              to=''
              className={
                'text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                e.preventDefault();
                modalLogout.current.open();
              }}>
              Cerrar sesión
            </Link>
          </div>
        )}
      </div>
      <Modal ref={modalLogout}>
        <form onSubmit={handleSubmitLogout}>
          <div className='text-gray-300 font-bold md:text-left mb-1 md:mb-0 pr-4'>
            Cerrar sesión
          </div>
          <div className='my-4'>
            <label
              htmlFor={'nombre'}
              className='text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4'>
              En verdad desea cerrar sesión?
            </label>
            <div className='my-4'>
              <div className='flex'>
                <button
                  type='button'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3.5 rounded mr-2 whitespace-pre-line'
                  onClick={() => modalLogout.current.close()}>
                  No, cerrar ventana
                </button>
                <button
                  type='submit'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 mt-3.5 rounded ml-2 whitespace-pre-line'>
                  Si, deseo cerrar sesión
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UserDropdown;
