import React, { useState, useEffect, useRef } from 'react';
import Modal from 'components/Modals/Modal.js';
// Alertify
import alertify from 'alertifyjs';

const ManageDropdown = ({
  dataId,
  dataName,
  dataActiveRaw,
  dataEmail,
  dataPassword,
  dataDepartament,
  dataAddress,
  dataJob,
  dataTask,
  dataTaskRaw,
  dataPhoneNumber,
  dataDescription,
  dataUserShopId,
  dataUserExtensionId,
  dataShop,
  dataExtension,
  info,
  URIExtension,
  ...props
}) => {
  // Alerts
  const [alert, setAlert] = useState('');
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  // Edit Form Data
  const [idData] = useState(dataId);
  const [nameData, setNameData] = useState(dataName);
  const [activeDataRaw, setActiveDataRaw] = useState(dataActiveRaw);
  const [emailData, setEmailData] = useState(dataEmail);
  // old password
  const [passwordData, setPasswordData] = useState(dataPassword);
  // sumbit new password if changed
  const [passwordDataChange, setPasswordDataChange] = useState(dataPassword);
  const [departamentData, setDepartamentData] = useState(dataDepartament);
  const [addressData, setAddressData] = useState(dataAddress);
  const [jobData, setJobData] = useState(dataJob);
  const [shopUserIdData, setShopUserIdData] = useState(dataUserShopId);
  const [extensionUserIdData, setExtensionUserIdData] =
    useState(dataUserExtensionId);
  const [taskRawData, setTaskRawData] = useState(dataTaskRaw);
  const [phoneNumerData, setPhoneNumerData] = useState(dataPhoneNumber);
  const [descriptionData, setDescriptionData] = useState(dataDescription);
  // URI
  const URI = '/api/update/';
  // URI For Remove (Set State to 2)
  const URIDelete = '/api/delete/';
  // Dropdown
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const openDropdownPopover = () => {
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible();

  function useComponentVisible(initialIsVisible) {
    const [isComponentVisible, setIsComponentVisible] =
      useState(initialIsVisible);
    const ref = useRef(null);

    const handleHideDropdown = (event) => {
      if (event.key === 'Escape') {
        closeDropdownPopover();
      }
    };

    const handleClickOutside = (event) => {
      if (ref.current && !btnDropdownRef.current.contains(event.target)) {
        closeDropdownPopover();
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleHideDropdown, true);
      document.addEventListener('click', handleClickOutside, true);
      return () => {
        document.removeEventListener('keydown', handleHideDropdown, true);
        document.removeEventListener('click', handleClickOutside, true);
      };
    });
    return { ref, isComponentVisible, setIsComponentVisible };
  }

  useEffect(() => {
    setPasswordData(dataPassword);
  }, [dataPassword]);

  useEffect(() => {
    setPasswordDataChange(dataPassword);
  }, [dataPassword]);

  function submitDataEdit() {
    const postData = () => {
      var datos = [];

      datos.push({
        idData,
        nameData,
        activeDataRaw,
        emailData,
        passwordData,
        passwordDataChange,
        departamentData,
        addressData,
        jobData,
        shopUserIdData,
        extensionUserIdData,
        taskRawData,
        phoneNumerData,
        descriptionData,
      });

      // if a field is empty
      if (
        !idData ||
        !nameData ||
        !activeDataRaw ||
        !emailData ||
        !passwordData ||
        !passwordDataChange ||
        !departamentData ||
        !addressData ||
        !jobData ||
        !shopUserIdData ||
        !extensionUserIdData ||
        !taskRawData ||
        !phoneNumerData ||
        !descriptionData
      ) {
        setAlert(
          'Por favor no deje casillas en blanco antes de guardar los cambios realizados'
        );
      } // /if
      else if (
        idData &&
        nameData &&
        activeDataRaw &&
        emailData &&
        passwordData &&
        passwordDataChange &&
        departamentData &&
        addressData &&
        jobData &&
        shopUserIdData &&
        extensionUserIdData &&
        taskRawData &&
        phoneNumerData &&
        descriptionData
      ) {
        // else there are no empty fields
        return fetch(URI + URIExtension, {
          method: 'PUT',
          credentials: 'same-origin',
          withCredentials: true,
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(datos),
        })
          .then((response) => response.json())
          .then((json) => {
            setAlert(json.message);
            props.recibir();
          })
          .catch((err) => {
            console.error(err);
          });
      } // /else
    };
    postData();
  }

  function submitDataRemove() {
    var datos = [];

    datos.push({
      idData: idData,
    });

    fetch(URIDelete + URIExtension, {
      method: 'PUT',
      credentials: 'same-origin',
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(datos),
    })
      .then((response) => response.json())
      .then((json) => {
        setAlert(json.message);
        props.recibir();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const handleSubmitEdit = (evt) => {
    evt.preventDefault();
    submitDataEdit();
  };
  const handleSubmitRemove = (evt) => {
    evt.preventDefault();
    submitDataRemove();
  };

  const modalEdit = useRef(null);
  const modalRemove = useRef(null);

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert);
    setAlert('');
  }

  return (
    <>
      <a
        className='text-gray-600 py-1 px-3'
        href=' '
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
          dropdownPopoverShow
            ? closeDropdownPopover()
            : setIsComponentVisible(true);
        }}
      >
        <i className='fas fa-ellipsis-v'></i>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded-md shadow-lg w-36 ' +
          (dropdownPopoverShow ? 'message-globe' : 'hide-message')
        }
      >
        {isComponentVisible && (
          <div ref={ref}>
            <a
              href=' '
              className={
                'edit-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                e.preventDefault();
                modalEdit.current.open();
              }}
            >
              <i className='far fa-edit mr-2'></i>
              Editar
            </a>
            <a
              href=' '
              className={
                'delete-option text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800'
              }
              onClick={(e) => {
                e.preventDefault();
                modalRemove.current.open();
              }}
            >
              <i className='fas fa-eraser mr-2'></i>
              Eliminar
            </a>
          </div>
        )}
      </div>
      <Modal ref={modalEdit}>
        <form
          onSubmit={handleSubmitEdit}
          className='text-gray-300 font-bold mb-1 md:mb-0 pr-4'
        >
          <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-align-center'>
            Editar {info}
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'nombre' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Nombre del {info}
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'nombre' + info}
              name={'nombre' + info}
              placeholder={'Nombre del ' + info}
              onChange={(e) => setNameData(e.target.value)}
              defaultValue={nameData}
            ></input>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'email' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Email del {info}
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'email' + info}
              name={'email' + info}
              placeholder={'Email del ' + info}
              onChange={(e) => setEmailData(e.target.value)}
              defaultValue={emailData}
            ></input>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'password' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Contraseña del {info}
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'password' + info}
              name={'password' + info}
              placeholder={'Contraseña del ' + info}
              key={dataPassword}
              onChange={(e) => setPasswordDataChange(e.target.value)}
              //defaultValue={passwordDataChange}
              value={passwordDataChange}
            ></input>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'direccion' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Dirección del {info}
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'direccion' + info}
              name={'direccion' + info}
              placeholder={'Dirección del ' + info}
              onChange={(e) => setAddressData(e.target.value)}
              defaultValue={addressData}
            ></input>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'departamento' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Departamento {info}
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'departamento' + info}
              name={'departamento' + info}
              placeholder={'Departamento del ' + info}
              onChange={(e) => setDepartamentData(e.target.value)}
              defaultValue={departamentData}
            ></input>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'ocupacion' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Ocupación
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'ocupacion' + info}
              name={'ocupacion' + info}
              placeholder={'Ocupación del ' + info}
              onChange={(e) => setJobData(e.target.value)}
              defaultValue={jobData}
            ></input>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'comercio' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Comercio
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'comercio' + info}
              name={'comercio' + info}
              onChange={(e) => setShopUserIdData(e.target.value)}
              defaultValue={shopUserIdData}
            >
              <option value=''>Seleccionar</option>
              {dataShop.map((row, index) => (
                <option key={index} value={row.id}>
                  {row.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'comercio' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Comercio
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'comercio' + info}
              name={'comercio' + info}
              onChange={(e) => setExtensionUserIdData(e.target.value)}
              defaultValue={extensionUserIdData}
            >
              <option value=''>Seleccionar</option>
              {dataExtension.map((row, index) => (
                <option key={index} value={row.id}>
                  {row.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'puesto' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Puesto
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'puesto' + info}
              name={'puesto' + info}
              onChange={(e) => setTaskRawData(e.target.value)}
              defaultValue={taskRawData}
            >
              <option value=''>Seleccionar</option>
              <option value='1'>Propietario</option>
              <option value='2'>Administrador</option>
              <option value='3'>Proveedor</option>
              <option value='4'>Empleado</option>
            </select>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'telefono' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Teléfono
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'telefono' + info}
              name={'telefono' + info}
              placeholder={'Número de télefono del ' + info}
              onChange={(e) => setPhoneNumerData(e.target.value)}
              defaultValue={phoneNumerData}
            ></input>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'descripcion' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Descripción
            </label>
            <input
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'descripcion' + info}
              name={'descripcion' + info}
              placeholder={'Descripción del ' + info}
              onChange={(e) => setDescriptionData(e.target.value)}
              defaultValue={descriptionData}
            ></input>
          </div>
          <div className='my-4 flex items-center justify-center max-w-full'>
            <label
              htmlFor={'activo' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 max-w-1/2 w-1/2 inline-block'
            >
              Estado del {info}
            </label>
            <select
              className='bg-gray-200 appearance-none border-2 border-gray-200 rounded py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500 max-w-1/2 w-1/2 inline-block'
              type='text'
              id={'activo' + info}
              name={'activo' + info}
              onChange={(e) => setActiveDataRaw(e.target.value)}
              defaultValue={activeDataRaw}
            >
              <option value=''>Seleccionar</option>
              <option value='1'>Activo</option>
              <option value='2'>No activo</option>
            </select>
          </div>
          <div className='flex'>
            <button
              type='button'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-1/2 w-1/2'
              onClick={() => modalEdit.current.close()}
            >
              Cerrar ventana
            </button>
            <button
              type='submit'
              className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-1/2 w-1/2'
            >
              Guardar cambios
            </button>
          </div>
        </form>
      </Modal>
      <Modal ref={modalRemove}>
        <form onSubmit={handleSubmitRemove}>
          <div className='text-gray-300 font-bold mb-1 md:mb-0 pr-4 text-left'>
            Eliminar {info}
          </div>
          <div className='my-4'>
            <label
              htmlFor={'nombre' + info}
              className='text-gray-500 font-bold mb-1 md:mb-0 pr-4 inline-block'
            >
              En verdad desea eliminar este {info}?
            </label>
            <div className='my-4'>
              <div className='flex'>
                <button
                  type='button'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded mr-2 max-w-full inline-block'
                  onClick={() => modalRemove.current.close()}
                >
                  No, cerrar ventana
                </button>
                <button
                  type='submit'
                  className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 my-3 rounded ml-2 max-w-full inline-block'
                >
                  Si, deseo eliminarlo
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ManageDropdown;
