import React from 'react';
import { View } from '@react-pdf/renderer';
import ReceiptLogo from './ReceiptLogo';
import ReceiptDescription from './ReceiptDescription';

const ReceiptHeader = ({ title, extension }) => (
  <View>
    <ReceiptLogo title={title} />
    <ReceiptDescription title={title} extension={extension} />
  </View>
);

export default ReceiptHeader;
