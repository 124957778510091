import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  titleContainer: {
    marginTop: 0,
    marginBottom: 8,
    width: '72.1mm',
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  reportTitle: {
    marginBottom: -5,
  },
  extensionSpan: {
    lineHeight: 0,
  },
});

const InvoiceTitle = ({ title, extension }) => (
  <View style={styles.titleContainer}>
    <Text style={styles.reportTitle}></Text>
    <Text style={styles.reportTitle}>Malem Group S.A.</Text>
    {/* <Text style={styles.reportTitle}>Nit: {nit}</Text> */}
    <Text style={styles.extensionSpan}>{extension}</Text>
    <Text style={styles.reportTitle}>Huehuetenango, Huehuetenango</Text>
  </View>
);

export default InvoiceTitle;
