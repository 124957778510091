import React, { useState, useEffect, useRef } from 'react';
import CardItem from '../../components/Cards/CardItem.js';
// Loader
import Loader from 'react-loader-spinner';
// Alertify
import alertify from 'alertifyjs';

export default function Pedidos() {
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState('');

  const [affiliates, setAffiliates] = useState([]);
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [q, setQ] = useState('');
  const [searchColumns, setSearchColumns] = useState([
    'barcode',
    'codigo',
    'nombre',
    'grupo',
    'elemento',
    'especificacion',
    'extra',
    'cantidad',
    'valor',
  ]);
  // Munted Component Ref
  const _isMounted = useRef(true); // Initial value __isMounted = true

  useEffect(() => {
    if (_isMounted.current) {
      fetch('/api/inventario/fetch', { method: 'GET' })
        .then((response) => response.json())
        .then((json) => {
          // Data successfully fetched
          if (json.success) {
            var sortedData = json.data.sort(function (a, b) {
              return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
            });
            sortedData.reverse();
            setData(sortedData);
            setDataSearch(json.dataSearch);
            setLoading(false);
          } else {
            setAlert(json.message);
            setData([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      fetchAfiliados();
    }
    return () => {
      _isMounted.current = false;
    };
  }, []);

  function search(rows) {
    return rows.filter((row) =>
      searchColumns.some(
        (column) =>
          row[column].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      )
    );
  }

  if (alert) {
    if (!alertify.errorAlert) {
      alertify.dialog(
        'errorAlert',
        function factory() {
          return {
            build: function () {
              var errorHeader =
                '<span class="fas fa-info-circle fa-2x" ' +
                'style="vertical-align:middle;color:#77acf1;">' +
                '</span><span style="vertical-align:middle;font-size:1.15em;margin: 0 .5em;">Info</span>';
              this.setHeader(errorHeader);
            },
          };
        },
        true,
        'alert'
      );
    }
    alertify.errorAlert(alert);

    setAlert('');
  }

  const fetchAfiliados = async () => {
    await fetch('/api/affiliates', { method: 'GET' })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAffiliates(res.affiliates);
          setLoading(false);
        } else {
          setAlert(res.message);
          setData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = dataSearch[0] && Object.keys(dataSearch[0]);

  return (
    <>
      <div className='mb-auto h-full'>
        <div className='flex justify-center flex-wrap mt-4'>
          <div className='w-full'>
            <div className='px-2.5'>
              <div className='my-auto pt-2 pl-2 float-left mx-auto'>
                <input
                  className='border rounded-xl py-2 px-3 mr-4 text-gray-700'
                  type='text'
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
              </div>
              {columns &&
                columns.map((column) => (
                  <div key={column} className='inline-flex'>
                    <div className='rounded-t-xl overflow-hidden p-2'>
                      <div className='p-2 max-w-xs mx-auto bg-white rounded-xl shadow-md'>
                        <label className='flex items-center space-x-3 cursor-pointer'>
                          <input
                            className='form-tick appearance-none h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none'
                            type='checkbox'
                            checked={searchColumns.includes(column)}
                            onChange={(e) => {
                              const checked = searchColumns.includes(column);
                              setSearchColumns((prev) =>
                                checked
                                  ? prev.filter((sc) => sc !== column)
                                  : [...prev, column]
                              );
                            }}
                          />
                          <span className='text-gray-900 font-normal space-x-3 font-sans capitalize text-sm user-select'>
                            {column}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {loading ? (
              <Loader
                type='MutatingDots'
                color='#9fd8df'
                secondaryColor='#4b778d'
                height={100}
                width={100}
                timeout={3000}
                className='loader-middle'
              />
            ) : (
              <CardItem
                data={search(data)}
                buyPhase={data}
                affiliates={affiliates}
              />
            )}
          </div>
        </div>
      </div>
    </>
  ); // return
}
